import { getRequest } from '../../../services/axiosClient';
import { getBaseUrl } from '../../../util/getBaseUrl';

const API_BASE_URL = getBaseUrl() + '/api/datasrcs/1/schemas/';
export const constructUrl = configItem =>  API_BASE_URL + configItem.foreign_schema_name + '/tables/' + configItem.foreign_table_name + `/records?${configItem.foreign_column_name}=${configItem.form_default_value}`;


export const addurlParamValues = (formData, params, columns, conf) => {
    const updatedFormData = { ...formData };
    params.forEach(([key, newValue]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (updatedFormData.hasOwnProperty(key) || (columns.includes(key))) {
            updatedFormData[key] = conf[key]?.async_lookup_flag ? [newValue] : newValue;
        }
    });
    return updatedFormData;
};

export const addDefaultValues = async (config, columns) => {

    const row = {};
    const asyncUrlList = {};
    columns?.forEach(item => {
        if (!item.includes('_dv')) {
            row[item] = config[item].form_default_value;
            if (config[item].async_lookup_flag && config[item].form_default_value) {
                asyncUrlList[item] = constructUrl(config[item]);
            }
        }
    });

    const fetchTableData = async table => {
        try {
            return await getRequest(asyncUrlList[table]);
        } catch (error) {
            console.error('Failed to fetch table data for:', table, error);
            return null;
        }
    };

    const results = await Promise.all(
        Object.keys(asyncUrlList).map(async table => {
            const response = await fetchTableData(table);
            return [table, response?.data?.data[0][config[table].ref_display_value || '']];
        })
    );

    results.forEach(([table, data]) => {
        if (data) {
            row[table] = [config[table].form_default_value, data];
        }
    });

    return row;
};
