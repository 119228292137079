import React,{useEffect, useState} from 'react';
import './homepage.css';
import PropTypes from 'prop-types';
import globeImg from '../../assets/globe-compressed.webp';
import pencupImg from '../../assets/pencup-compressed.webp';
import monitorImg from '../../assets/monitor.png';
import lampImg from '../../assets/lamp-compressed.webp';
import dataImg from '../../assets/dataImg.webp';
import windowImg from '../../assets/window1.webp';
import confImg from '../../assets/confluence.png';
import productsImg from '../../assets/products.png';
import { Link } from 'react-router-dom';
import CustomPopup from '../../components/common/customPopup/CustomPopup';
import { MdClose } from 'react-icons/md';
import diskImg from '../../assets/disk.webp';
import DashboardGroup from '../dashboards/dashboardGroup/DashboardGroup';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
const imagesTable = {
    dataExp:{
        src:globeImg,
        alt:'Data Exploration',
        width:120,
        url:'/explore'
    },
    webApp:{
        src:monitorImg,
        alt:'Web Applications',
        width:350,
    },
    penCup:{
        src:pencupImg,
        alt:'Pen Cup',
        className:'homepage-table-pencup'
    },
    lamp:{
        src:lampImg,
        alt:'Lamp',
        className:'homepage-table-lamp'
    },};
const imagesWall = {
    dataMaintenance: {
        src:dataImg,
        alt:'Data Maintenance',
        width:'250px',
        url:'/data_maintenance'
    },
    window: {
        src:windowImg,
        alt:'Window',
        width:'670px',
    },
    confluence: {
        src:confImg,
        alt:'Trainings & Confluence',
        width:'322px',
    },
    events: {
        src:'',
        alt:'Events & Announcements',
        width:'310px',
    },
    products:{
        src:productsImg,
        alt:'Product Groups',
        width:'500px',
        url:'/dashboards'
    }
};
const webApps = [
    {   id:1,
        app: 'RMR',
        name:'Resource Management & Recoverability',
        link:'https://rmr.jnj.com'
    },
    {   id:2,
        app: 'WSR',
        name:'Weekly Status Report',
        link:'https://wsr.jnj.com'
    },
    {   id:3,
        app: 'ITP',
        name:'IT Procurement',
        link:'https://itprocurement.jnj.com'
    },
    {   id:4,
        app: 'JJT Strategy',
        name:'JJT Executive Management Dashboard',
        link:'https://jjtstrategy.jnj.com'
    }
];

const HomePage = props =>  {
    const [webPopup, setWebPopup] = useState(false);
    const [productPopup, setProductPopup] = useState(false);
    /* we set the title
    of the page. */
    useEffect(() => {
        props.setTitle('TS Insights & Solutions Homepage');
    }, []);


    return (
        <div className='homepage-wrap'>
            <div className='homepage-top-wrap'>
                <div className='homepage-img-title homepage-hover' onClick={()=> setProductPopup(true)}>
                    <span>{imagesWall.products.alt}</span>
                    <img src={imagesWall.products.src} alt={imagesWall.products.alt} width={imagesWall.products.width} />
                </div>
            </div>
            {
                productPopup && <div className='homepage-product-popup'>
                    <span className='homepage-product-close homepage-hover' onClick={()=>setProductPopup(false)} data-testid='close-icon'>
                        <MdClose fontSize={22}/>
                    </span>
                    <DashboardGroup/>
                </div>
            }
            <div className="homepage-middle-wrap">
                <Link className='homepage-img-title homepage-hover' to={{pathname: imagesWall.dataMaintenance.url}}>
                    <span>{imagesWall.dataMaintenance.alt}</span>
                    <img src={imagesWall.dataMaintenance.src} alt={imagesWall.dataMaintenance.alt} width={imagesWall.dataMaintenance.width} style={{boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius:'5px'}}/>
                </Link>
                
                <img src={imagesWall.window.src} alt={imagesWall.window.alt} width={imagesWall.window.width} className='homepage-window-image'/> 
                
                <span className='homepage-side-wrap'>
                    <a className='homepage-confluence homepage-hover' href='https://confluence.jnj.com/pages/viewpage.action?pageId=169588039' target={'_blank'} rel="noreferrer">
                        <span>
                            {imagesWall.confluence.alt} 
                        </span>
                        <img src={imagesWall.confluence.src} alt={imagesWall.confluence.alt} width={imagesWall.confluence.width} />
                    </a>
                    <span className='homepage-events homepage-hover' >
                        
                        <Calendar/>
                    </span>
                </span>
            </div>
            {
                webPopup && <CustomPopup overflow={'hidden'} padding={'0px'}>
                    <div className='homepage-webapp-wrap'>
                        <div className='homepage-webapp-header'> 
                            <span>Web Applications</span>
                            <span className='homepage--webapp-close homepage-hover' onClick={()=>setWebPopup(false)} data-testid='close-icon'>
                                <MdClose fontSize={22}/>
                            </span>
                        </div>
                        <div className='homepage-webapp-img-container'>
                            {webApps.map(item => {
                                return (
                                    <a key={item.id} className='homepage-webapp-img-wrap' href={item.link} target={'_blank'} rel="noreferrer">
                                        <span className='homepage-webapp-img-title'>{item.app}</span>
                                        <img src={diskImg} alt={item.name} width={140} />
                                    </a>
                                );
                            })}
                        </div>
                        <div className='homepage-webapp-footer'><span style={{marginLeft:'1rem'}}>{webApps.length} Applications</span></div>
                    </div>
                </CustomPopup>
            }
            <div className='homepage-table-wrap'>
                <Link className='homepage-table-dataExp homepage-hover' to={{pathname: imagesTable.dataExp.url}}>
                    <span>{imagesTable.dataExp.alt}</span>
                    <img src={imagesTable.dataExp.src} alt={imagesTable.dataExp.alt}  width={imagesTable.dataExp.width} />
                </Link>
                <span className='homepage-table-webApp' onClick={()=>setWebPopup(true)}>
                    <span>Web Applications</span>
                    <img src={imagesTable.webApp.src} alt={imagesTable.webApp.alt} width={imagesTable.webApp.width} />
                    <div className='homepage-icon-wrap'>
                        <div className="homepage-icon-container">
                            <img src={diskImg} alt={'Web Apps'} width={40} className='homepage-icon-img' />
                            <img src={diskImg} alt={'Web Apps'} width={40} className='homepage-icon-img'/> 
                            <img src={diskImg} alt={'Web Apps'} width={40} className='homepage-icon-img' /> 
                            <img src={diskImg} alt={'Web Apps'} width={40} className='homepage-icon-img'/> 
                        </div>
                    </div>
                    
                </span>
                
                <span>
                    <img src={imagesTable.penCup.src} alt={imagesTable.penCup.alt} className={imagesTable.penCup.className}/>
                </span>
                <span>
                    <img src={imagesTable.lamp.src} alt={imagesTable.lamp.alt} className={imagesTable.lamp.className}/>
                </span>
                <div className='homepage-table'>
                </div>
                
            </div>         </div>    
    );
};

HomePage.propTypes={
    setTitle: PropTypes.any
};

  
export default HomePage;
  