import { RowDvToId, RemoveAuditDataFromRow } from './RowEditingCommands';

const handleEditClickWorker = (event, row, AccessControlList, state, dispatch, userInfo) => {
    const { userName } = userInfo;
    const { update_any_records, update_own_records } = AccessControlList;
    
    // copied rows
    let copiedRow = RemoveAuditDataFromRow({ ...row });
    
    // row id 
    const rowId = copiedRow[state.tableUniqueId];
    
    if (update_any_records === 1 || (update_own_records === 1 && userName === row.audit__create_user)) {
        event.stopPropagation();
        copiedRow = RowDvToId(copiedRow, state.Config);
        dispatch({ type: 'FORM_OPEN' });
    } else {
        dispatch({ type: 'HANDLE_ACL_POPUP_OPEN' });
    }
    // return row id
    return { rowId };
};

const handleAddClickWorker = (AccessControlList, setFormType, setEditRowId, dispatch) => {
    // check acl list
    if (AccessControlList.create_record) {
        setFormType('add');
        setEditRowId(0);
        dispatch({type:'FORM_OPEN'});
    } else { dispatch({type: 'HANDLE_ACL_POPUP_OPEN'}); }
};
//this func execute if the user confirms deletion via popup alert
const handleDeleteClickWorker = (state, sendUpdateDelete, dispatch) => {
    if (state.targetDelete == null) { return false; }
    const newRows = [...state.Rows];
    const updatedNewRows = [];
    for (let i in newRows) {
        if (newRows[i][state.tableUniqueId] != state.targetDelete) {
            updatedNewRows.push(newRows[i]);
        }
    }
    sendUpdateDelete(state.targetDelete, updatedNewRows);
    //dispatch results
    dispatch({type: 'DELETE_POPUP', openDeletePopup:false, targetDelete: null});
};

export { handleEditClickWorker, handleDeleteClickWorker, handleAddClickWorker };