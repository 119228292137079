import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';

export default function SelectDropDown({ ddLabel, menuItem, selectedValue }) {
    const [dataSrc, setDataSrc] = useState('');
    //On dropdown select change handler function
    const handleChange = event => {
        setDataSrc(event.target.value);
    };
    // Creating Id name for each dropdown
    const dropsownname = ddLabel === 'Schema fields' ? 'schema' : 'dataSrc';

    useEffect(() => {
        //Callback function for sending
        //  selected value to parent component
        if (dataSrc)
            selectedValue(dataSrc);
    }, [dataSrc]);

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                {/* label of dropdown box */}
                {/* menuItem defined for dropdown options */}
                <InputLabel id={dropsownname}>{ddLabel}</InputLabel>
                <Select
                    labelId={dropsownname}
                    data-testid='selectDropdown'
                    value={dataSrc}
                    label={ddLabel}
                    onChange={handleChange}
                >
                    {menuItem && menuItem.map((item, i) => {
                        if (typeof item === 'object')
                            return <MenuItem key={i} value={item}>{item.schema_name}</MenuItem>;
                        else
                            return <MenuItem key={i} value={item}>{item}</MenuItem>;
                    })
                    }

                </Select>
            </FormControl>
        </Box>
    );
}
SelectDropDown.propTypes = {
    ddLabel: PropTypes.string,
    menuItem: PropTypes.array,
    selectedValue: PropTypes.func,
};
