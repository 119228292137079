
//edits empty strings from a dictionary and makes them null
//for use in datagrid to send back null values
const ProcessFormData = (updateData, config) => {
    for (let k in updateData) {
        if (updateData[k] == '') {
            updateData[k] = null;
        }
    }

    let multiSelectData = {};

    for(let i in config){
        if(config[i].data_type === 'multi-select'){
            multiSelectData[i] = {};
            multiSelectData[i]['data'] = updateData[i];
            multiSelectData[i]['conf'] = config[i];
            delete updateData[i];
        }
    }

    
    return {updateData, multiSelectData};
};
//inverse reverses a dictionary keys and values
//so cool!
function inverse(obj) {
    const retobj = {};
    for (const key in obj) {
        retobj[obj[key]] = key;
    }
    return retobj;
}

// returns true if item is not null
const IsTrue = myBool =>  myBool ? true : false;

//checks for audit columns and removes them to return object without.
const RemoveAuditDataFromColumns = cols => {
    let noAuditDataColumns = { ...cols };
    for (let i in noAuditDataColumns) {
        if (noAuditDataColumns[i].startsWith('audit__')) {
            delete noAuditDataColumns[i];
        }
    }
    return Object.values(noAuditDataColumns);
};


export { ProcessFormData, inverse, IsTrue, RemoveAuditDataFromColumns };