import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
export function FileManagerTable({ data, handlePathChange, handleDownload }) {
    const sizePrecision = 2;
    const bytes = 1024;
    let count = 0;
    const rows = data.map(file => {
        count++;
        return {
            id: count,
            name: file.name,
            size: file.isDir
                ? '-'
                : parseFloat(file.sizeBytes / bytes).toFixed(sizePrecision),
            modifiedTime: new Date(file.modifiedTime).toLocaleString('en-US'),
            isDir: file.isDir,
            path: file.path,
        };
    });

    const columns = [
        {
            field: 'isDir',
            headerName: '',
            maxWidth: 64,
            align: 'center',
            renderCell: params => {
                return (
                    <div
                        data-testid={`folder-file-icon-${params.row.name}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRowClick(params)}
                    >
                        {params.row.isDir ? (
                            <FolderRoundedIcon />
                        ) : (
                            <InsertDriveFileRoundedIcon />
                        )}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: '',
            maxWidth: 48,
            flex: 1,
            align: 'center',
            renderCell: params => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDownload(params.row)}
                    >
                        {!params.row.isDir ? <DownloadRoundedIcon /> : null}
                    </div>
                );
            },
        },
        {
            field: 'name',
            headerName: 'File Name',
            minWidth: 350,
            flex: 1,
            renderCell: params => {
                if (params.row.isDir) {
                    return <strong>{params.row.name}</strong>;
                }
            },
        },
        { field: 'size', headerName: 'File Size (KB)', minWidth: 150, flex: 1 },
        {
            field: 'modifiedTime',
            headerName: 'Last Modified',
            minWidth: 200,
            flex: 1,
        },
    ];

    const handleRowClick = params => {
        if (params.row.isDir) {
            handlePathChange(params.row.name);
        }
    };

    return (
        <div data-testid={'filemanagertable-div'}>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowDoubleClick={handleRowClick}
                autoHeight
                rowHeight={36}
                disableSelectionOnClick
            />
        </div>
    );
}

FileManagerTable.propTypes = {
    data: PropTypes.array,
    handlePathChange: PropTypes.func,
    handleDownload: PropTypes.func,
};
