import {CircularProgress, Backdrop} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Loading = props => {
    /* A destructuring 
    assignment. */
    const isLoading = props.isLoading;

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', }}
                open={isLoading}
            >
            Loading...
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

/* A validation of the props. */
Loading.propTypes = {
    isLoading:PropTypes.bool
};

export default Loading;
