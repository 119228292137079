import React, {useCallback} from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { getRequest } from '../../../services/axiosClient';

//debounce function
const debounceTimeoutMS = 250;
function debounce(fn, delay = debounceTimeoutMS) {
    let timeout;
    //reads function args and timeouts to create buffer/debounce
    //in typed response.
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}


const CustomAsyncSelect = props => {
    const field = props.field.field;
    const valueD = props.field.field.value;

    /* This is making a call to a REST API and returning a list of objects property.
    You also need to set label and value fields to show up in select dropdown or set manually
    with getOptionLabel and getOptionValue methods, shown at 47/48 lines.
    */
    const loadOptionData = async (inputText, callback) => {
        const res = await getRequest(props.urlReq + inputText);
        callback(res.data.data);
    };

    //returns options after debounceDurationMS time
    const debounceDurationMS = 500;
    const loadOptionDataDebounced = useCallback(
        debounce(loadOptionData, debounceDurationMS),
        []
    );

    //getting option label
    const optionLabel = option =>{
        let result =   props.hideId ? `${option[props.defaultKeyNames.label]}` : `${option[props.defaultKeyNames.value]} - ${option[props.defaultKeyNames.label]}`;
        return result == ' - ' ? '' : result;
    };

    return (<>
        {!props.isMulti ? <AsyncSelect
            onChange={value => field.onChange(value[props.defaultKeyNames.value])}
            loadOptions={loadOptionDataDebounced}      
            cacheOptions
            getOptionLabel={option =>  optionLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field?.ref}
            name={props?.name}
            value={props.defValue?.find(c => c[props.defaultKeyNames.value] === valueD)}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        /> : <AsyncSelect
            loadOptions={loadOptionDataDebounced}      
            onChange={value=> field.onChange([...value])}
            getOptionLabel={option => optionLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field?.ref}
            name={props.name}
            defaultValue={props.defValue}
            isClearable={false}
            isMulti
            backspaceRemovesValue={false}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        /> }</>
    );
};

CustomAsyncSelect.propTypes = {
    urlReq: PropTypes.string,
    defValue: PropTypes.any,
    defaultKeyNames: PropTypes.object,
    field:PropTypes.any,
    name:PropTypes.string,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
};

export default CustomAsyncSelect;