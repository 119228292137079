import { createWithEqualityFn } from 'zustand/traditional';
import {shallow} from 'zustand/shallow';
// user store 
const userStore = createWithEqualityFn(set => (
    {
        userInfo: {},
        ACL:{},
        isAdmin: false,
        setUserInfo: data => set({userInfo: data}),
        setACL: data => set({ACL: data}),
        setIsAdmin: data => set({isAdmin: data}),
    }
));

// A hook for accessing the dashboard store.
// This hook uses selector to return only the parts of the state that are needed.
// This way, components that use this hook won't re-render unless these specific parts of the state change.
const useUserStore = () => {
    const {userInfo, ACL, isAdmin, setUserInfo, setACL, setIsAdmin} = userStore(state => ({ 
        userInfo:state.userInfo, 
        ACL:state.ACL, 
        isAdmin:state.isAdmin,
        setUserInfo:state.setUserInfo, 
        setACL:state.setACL,
        setIsAdmin:state.setIsAdmin,
    }), shallow);

    return { userInfo, ACL, isAdmin, setUserInfo, setACL, setIsAdmin };
};

export default useUserStore;