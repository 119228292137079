import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './linkDataForm.css';
import DataFilters from './dataFilters';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import DataAsyncSelect from './DataAsyncSelect';
import { getRequest, postRequest, putRequest } from '../../services/axiosClient';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import MyLinkDataFormColumns from './MyLinkDataFormColumns';
import InfoMessage from '../../components/common/InfoMessage';
import { getErrorMsg } from '../../util/functions';
import MyLinkParams from './MyLinkParams';
import useDashboardStore from '../../store/dashboard/useDashboardStore';
import { useNavigate } from 'react-router-dom';


const urlColumns = `${getBaseUrl()}${backendURL().getDataExplorationTableColumns}`;
const urlFilteredColumns = `${getBaseUrl()}${backendURL().getDataExpTableCols}`;
const urlFilteredDataSave = `${getBaseUrl()}${backendURL().filteredDataSave}`;
const urlColumnDataSave = `${getBaseUrl()}${backendURL().columnDataSave}`;
const urlUserSave = `${getBaseUrl()}/api/datasrcs/1/schemas/dataviz/tables/mylink_user/records`;
const urlFilteredRecCount = `${getBaseUrl()}${backendURL().getFilterRecordsCount}`;
const urlFilterRecordValues = `${getBaseUrl()}${backendURL().getFilterRecordValues}`;
const urlSaveMyLinkId = `${getBaseUrl()}${backendURL().getMyLinkId}`;
const urlGetSavedFilterValues = getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/mylink_filter/records?display-value=true&mylink_id=';
const urlGetSavedColumnValues = getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/mydata_column/records?mylink_id=';
const urlGetTabelId = `${getBaseUrl()}${backendURL().getTableIdWith_WorkbookViewId}`;
const urlGetParams = `${getBaseUrl()}${backendURL().getFilterParams}`;
const getAsyncFilterRecordsValueURL = `${getBaseUrl()}${backendURL().getAsyncFilterRecordValues}`;
const urlGenerateData = `${getBaseUrl()}${backendURL().getGenerateMyData}`;

// Function to get the link ID for the user's data
const getMyLinkId = async (dataFormName, selectedTableId, formType, workbookViewId, productId = null, workbookId = null,) => {

    try {
        const myDataFormId = 2;
        const myLinkFormId = 1;
        // Make a POST request to get the link ID for the user's data
        const obj = {
            name: dataFormName,
            datamart_table_id: selectedTableId,
            type_id: formType === 'myData' ? myDataFormId : myLinkFormId,
            product_id: productId,
            workbook_id: workbookId,
            workbook_view_id: workbookViewId,
        };

        const response = await postRequest(
            urlSaveMyLinkId, obj
        );

        // If the response is valid, return the link ID
        return { error: false, myLinkId: response.data.data[0].id };
    } catch (error) {

        // If there is an error, return an error message
        return { error: true, message: 'An error occurred while saving my link data!' };
    }
};
// Fetches column data and filter data for a given table ID
const getColumnData = async tableId => {
    // Make two requests in parallel using Promise.all, 
    // then wait for both to finish before continuing
    const columnDataResponse = await getRequest(urlColumns + tableId);

    // Extract the necessary data from the response and 
    // reformat it into an array of objects with specific keys
    return columnDataResponse.data.data.map(({ column_name_display, column_name, id }) => ({
        id: id,
        columnName: column_name_display ? column_name_display : column_name,
        isChecked: false,
        columnId: null,
    }));
};
// Fetches column data and filter data for a given table ID
const getColumnDataFilter = async tableId => {
    // Make two requests in parallel using Promise.all, then wait for both to finish before continuing
    const filterDataResponse = await getRequest(urlFilteredColumns + tableId);

    // Return an object with the column data and filter data arrays
    return filterDataResponse.data.data;
};

// Fetches saved column data for a given form and returns it as a hash object
const getSavedColumnData = async formInfo => {
    // If the form action is 'new', return null
    if (formInfo.action === 'new') {
        return null;
    }

    // Otherwise, make a request to get saved column data for the form's link ID and reformat it into a hash object
    const resCols = await getRequest(urlGetSavedColumnValues + formInfo.myLinkId);
    const hash = {};
    if (resCols.data.rowCount > 0) {
        resCols.data.data.forEach(col => {
            hash[col.datamart_column_id] = col.id;
        });
    }

    return hash;
};

// Updates the 'isChecked' and 'columnId' properties of the column data items based on saved data
const updateColumnDataWithSavedData = (columnData, savedData) => {
    // If there is no saved data, return the original column data array
    if (!savedData) {
        return columnData;
    }

    // Otherwise, map over the column data array and update items that have saved data
    return columnData.map(item => {
        if (savedData[item.id]) {
            return {
                ...item,
                isChecked: true,
                columnId: savedData[item.id],
            };
        }

        return item;
    });
};

// Formats filter data into an array of objects with column IDs as keys and filter values as values
const processFilterResponse = filterData => {
    return [...new Set(filterData?.map(item => item.column_id))].map(item => {
        return {
            [item]: filterData?.filter(obj => obj.column_id === item).map(it => ({ ...it, isChecked: false, filterId: null })),
        };
    });
};

// Processes default filter values and returns a modified version of the filter values array
const processDefaultFilterValues = (filterVals, defaultValues, defFilterValues, defaultAsyncValues) => {
    const hash = {};
    const newValue = filterVals.map(column => {
        const columnId = Object.keys(column)[0];
        const defaultValuesForColumn = defaultValues.filter(defaultValue => defaultValue.column_id === Number(columnId));
        hash[columnId] = true;
        if (defaultValuesForColumn.length === 0) {
            return column;
        }

        return {
            [columnId]: column[columnId].map(filterValue => {
                const defaultValue = defaultValuesForColumn.find(defaultVal => defaultVal.filter_value_id === filterValue.id);

                if (defaultValue) {
                    defFilterValues.push({
                        ...filterValue,
                        isChecked: true,
                        filterId: defaultValue.id
                    });

                    return {
                        ...filterValue,
                        isChecked: true,
                        filterId: defaultValue.id
                    };
                }

                return filterValue;
            })
        };
    });

    // Add any default async filter values that were not already processed
    defaultValues.forEach(item => {
        if (!hash[item.column_id]) {
            defaultAsyncValues.push(
                {
                    filterId: item.id,
                    column_id: item.column_id,
                    id: item.filter_value_id,
                    filter_value_name: item.filter_value_id_dv,
                    myLinkId: item.mylink_id,
                    start_date: item.start_date,
                    end_date: item.end_date,
                }
            );
        }
    });

    return newValue;
};

// Fetches filter counts for a given list of column IDs and form information
const getFiltersCount = async (columnIds, formInfo) => {
    // Make a request to get filter record counts for the column IDs
    const response = await getRequest(urlFilteredRecCount + columnIds.join(','));
    const maxAllowedRecord = 10;

    // Separate the column IDs with more than 10 values from those with 10 or fewer
    const columnIdsAboveTenVals = response.data?.data?.filter(obj => obj.id > maxAllowedRecord);
    const columnIdsUnderTenValues = response.data?.data?.filter(obj => obj.id <= maxAllowedRecord)?.map(obj => obj.column_id)?.join(',');

    // Make a request to get filter record values for the column IDs with 10 or fewer values
    let filterVals = [];
    if (columnIdsUnderTenValues) {
        const filterResponse = await getRequest(urlFilterRecordValues + columnIdsUnderTenValues);
        filterVals = processFilterResponse(filterResponse.data?.data);
    }

    let defFilterValues = [];
    const defaultAsyncValues = [];

    // If this is not a new form, make a request to get saved filter values and process them
    if (formInfo.action !== 'new') {
        const defaultValues = await getRequest(urlGetSavedFilterValues + formInfo.myLinkId);
        filterVals = processDefaultFilterValues(filterVals, defaultValues.data.data, defFilterValues, defaultAsyncValues);
    }

    // Return an object with the processed data
    return {
        columnIdsAboveTenVals,
        filterVals,
        defFilterValues,
        defaultAsyncValues
    };
};
const getDisableStatus = (apiStatus, formName, defaultName) => (apiStatus || (formName === defaultName)) ? true : false;

const MyLinkDataForm = ({ selectedTableId, userId, getUserViews, formInfo, setIsEditClicked, formType, workbookViewId, productId, workbookId }) => {
    const [columnList, setColumnList] = useState({ fetched: false, data: [] });
    const [columnListFilter, setColumnListFilter] = useState({ fetched: false, data: [], rowCount: 0 });
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredColumns, setFilteredColumns] = useState([]);
    // form name
    const [formDataName, setFormDataName] = useState(formInfo.formName);
    const [formDataTableId, setFormDataTableId] = useState(selectedTableId);
    const [filterValues, setFilterValues] = useState([]);
    const [columnIdsAboveTenValues, setColumnIdsAboveTenValues] = useState([]);
    const [selectedFilterList, setSelectedFilterList] = useState([]);
    const [selectedAsyncFilterList, setSelectedAsyncFilterList] = useState([]);
    const [apiStatus, setApiStatus] = useState({ status: false, timer: false, isError: false, message: '', loading: false });
    const [filterValuesFetched, setFilterValuesFetched] = useState(false);
    const [startEndDate, setStartEndDate] = useState({ fetched: false, start_date: { value: '' }, end_date: { value: '' }, status: false });
    const { getDashboardLinks, setNewView } = useDashboardStore();
    const navigate = useNavigate();
    // Main function to handle saving user data
    const saveUserData = async myLinkId => {
        try {
            // Save the basic user information
            await saveUser(myLinkId);

            // Check form type and handle accordingly
            if (formType !== 'myData') {
                await handleNonMyData(myLinkId);
            } else {
                await handleMyData(myLinkId);
            }

            // Save the selected filters for the user's data, if any
            if (columnListFilter.rowCount !== 0) {
                await saveFilters(myLinkId);
            }
            // If all the operations are successful, update the API status
            setApiStatus({ status: true, timer: 7000, isError: false, message: 'Data saved successfully!', loading: false });
            await handleFormTypeAndEditStatus(myLinkId);
        } catch (error) {
            // handling possible errors

            const msg = getErrorMsg(error);
            setApiStatus({ status: true, timer: 7000, isError: true, message: 'An error occurred while saving data' + msg, loading: false });

        }
    };

    // Save the basic user information to the 'mylink_user' table
    const saveUser = async myLinkId => {
        const userPayload = {
            user_wwid: userId,
            mylink_id: myLinkId,
            owner_flag: true,
            active_flag: true
        };
        await postRequest(urlUserSave, userPayload);
    };

    // Handle the operations when form type is not 'myData'
    const handleNonMyData = async myLinkId => {
        if (startEndDate.status) {
            const obj = [
                {
                    column_id: startEndDate.start_date.columnId,
                    mylink_id: myLinkId,
                    filter_value_id: null,
                    start_date: startEndDate.start_date.value,
                    end_date: startEndDate.end_date.value,
                }
            ];
            await postRequest(urlFilteredDataSave, obj);
        }
    };

    // Handle the operations when form type is 'myData'
    const handleMyData = async myLinkId => {
        const selectedColumnsList = [];
        columnList.data.forEach(item => {
            if (item.isChecked) {
                selectedColumnsList.push(
                    {
                        datamart_column_id: item.id,
                        mylink_id: myLinkId
                    }
                );
            }
        });
        if (selectedColumnsList.length > 0) {
            await postRequest(urlColumnDataSave, selectedColumnsList);
        }
    };

    // Save the selected filters for the user's data
    const saveFilters = async myLinkId => {
        const filterPayloads = [];
        const filterSelectedValues = [...selectedFilterList, ...selectedAsyncFilterList];
        filterSelectedValues.forEach(item => {
            filterPayloads.push({
                column_id: item.column_id,
                mylink_id: myLinkId,
                filter_value_id: item.id
            });
        });
        await postRequest(urlFilteredDataSave, filterPayloads);
    };

    // Handle the form type and edit status based on certain conditions
    const handleFormTypeAndEditStatus = async myLinkId => {
        if (formType !== 'myData') {
            await getDashboardLinks(userId);
        }
        if ((formType === 'myLink') && (formInfo.action === 'new')) {
            setNewView(true);
            navigate(`/dashboards/${productId}/${workbookId}/${workbookViewId}?mylink=${myLinkId}`);
        }
        if (formType !== 'myLink') {
            setIsEditClicked({
                status: true, formInfo: {
                    action: 'edit',
                    myLinkId: myLinkId,
                    formName: formDataName,
                }
            });
            getUserViews();
        } else {
            const timeout = 3000;
            setTimeout(() => {
                setIsEditClicked({ status: false, formInfo: null, tableId: null });
            }, timeout);
        }
    };





    // Function to handle saving the user's data
    const handleSaveData = async () => {
        setApiStatus({ status: false, timer: false, isError: false, message: '', loading: true });
        if (formInfo.action === 'edit') {

            try {
                await putRequest(urlSaveMyLinkId + `/${formInfo.myLinkId}`, { name: formDataName });
                setApiStatus({ status: true, timer: 7000, isError: false, message: 'Name saved successfully!', loading: false });
                setIsEditClicked({
                    status: true, formInfo: {
                        action: 'edit',
                        myLinkId: formInfo.myLinkId,
                        formName: formDataName,
                    }
                });
                getUserViews();
            } catch (err) {
                const msg = getErrorMsg(err);
                setApiStatus({ status: true, timer: 7000, isError: true, message: 'An error occurred while saving data' + msg, loading: false });
            }

        } else {
            // Call getMyLinkId to get the link ID for the user's data
            const result = await getMyLinkId(formDataName, formDataTableId, formType, workbookViewId, productId, workbookId,);

            // If there is an error, log the error message to the console
            if (result.error) {
                setApiStatus({ status: true, timer: 7000, isError: true, message: result.message, loading: false });
            } else {
                // If there is no error, save the user's data to the server
                saveUserData(result.myLinkId);
            }
        }
    };

    // Function to handle Generate the user's data
    const handleGenerateData = async () => {
        setApiStatus({ status: false, timer: false, isError: false, message: '', loading: true });
        try {
            const res = await getRequest(`${urlGenerateData}/${formInfo.myLinkDataId}/${userId}/${formInfo.myLinkDataName}`);           
            setApiStatus({status:true, timer:7000, isError:false, message: res?.data?.message, loading:false});
            
        } catch (err) {
            const msg = getErrorMsg(err);
            setApiStatus({ status: true, timer: 7000, isError: true, message: 'An error occurred while saving data' + msg, loading: false });
        }
    };

    useEffect(() => {
        // Filter the original list of columns based on the search term
        const filterItems = columnList.data.filter(item =>
            item.columnName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Update the filtered columns state with the filtered list
        setFilteredColumns(filterItems);

    }, [searchTerm]);

    const fetchParams = async tableId => {
        try {
            const response = await getRequest(urlGetParams + tableId);
            if (response.data.rowCount > 0) {
                const dateItem = response.data.data.filter(item =>{
                    if(item.filter_tableau_start_dt_param_nm && item.filter_tableau_end_dt_param_nm){
                        return item;
                    }
                })[0] || null;

                let start_date = { value: '', columnId: dateItem?.id || null, columnName: dateItem?.filter_tableau_start_dt_param_nm || null };
                let end_date = { value: '', columnId: dateItem?.id || null, columnName: dateItem?.filter_tableau_end_dt_param_nm || null };
                let id;
                if (formInfo.action !== 'new') {
                    const defValue = await getRequest(urlGetSavedFilterValues + formInfo.myLinkId + '&column_id=' + dateItem.id);
                    start_date.value = defValue.data.data[0]?.start_date || '';
                    end_date.value = defValue.data.data[0]?.end_date || '';
                    id = defValue.data.data[0].id;
                }
                setStartEndDate({ fetched: true, start_date: start_date, end_date: end_date, status: true, id: id });
            } else {
                setStartEndDate({ fetched: true, start_date: null, end_date: null, status: false, id: null });
            }
        } catch (err) {
            const msg = getErrorMsg(err);

            setStartEndDate({ fetched: true, start_date: { value: '' }, end_date: { value: '' }, status: false });
            setApiStatus({ status: true, timer: false, isError: true, message: 'An error occurred while fetching data' + msg, close: true });
        }
    };

    // Fetches filter data for a given list of filters and sets state variables accordingly
    const fetchFilters = async filterData => {
        try {
            // Call the 'getFiltersCount' function and wait for it to return before continuing
            const { columnIdsAboveTenVals, filterVals, defFilterValues, defaultAsyncValues } = await getFiltersCount(filterData.map(obj => obj.id), formInfo);
            // Set state variables based on the returned data
            setColumnIdsAboveTenValues(columnIdsAboveTenVals);
            setFilterValues(filterVals);
            setSelectedFilterList(defFilterValues);
            setSelectedAsyncFilterList(defaultAsyncValues);
            setFilterValuesFetched(true);
        } catch (err) {
            // If there was an error, set 'filterValuesFetched' to true and display an error message
            setFilterValuesFetched(true);
            const msg = getErrorMsg(err);
            setApiStatus({ status: true, timer: false, isError: true, message: 'An error occurred while fetching filter data' + msg, close: true });
        }
    };

    // Fetches column data for a given table and sets state variables accordingly
    const fetchColumnData = async tableId => {
        try {
            if (formType !== 'myLink') {
                // Call the 'getColumnData' function and wait for it to return before continuing
                const columnData = await getColumnData(tableId);
                // Call the 'getSavedColumnData' function and wait for it to return before continuing
                const savedColumnData = await getSavedColumnData(formInfo);
                // Call the 'updateColumnDataWithSavedData' function to combine the column data with the saved data, then set state variables accordingly
                const updatedColumnData = updateColumnDataWithSavedData(columnData, savedColumnData);
                setColumnList({ fetched: true, data: updatedColumnData });
                setFilteredColumns(updatedColumnData);
            }
            if (formType !== 'myData') {
                fetchParams(tableId);
            }

            const filterData = await getColumnDataFilter(tableId);
            setColumnListFilter({ fetched: true, data: filterData, rowCount: filterData.length });
            if (filterData.length > 0) {
                // Call the 'fetchFilters' function to get filter data for this column data
                fetchFilters(filterData);
            } else {
                setFilterValuesFetched(true);
            }


        } catch (err) {
            // If there was an error, set state variables accordingly and display an error message
            setColumnList({ fetched: true, data: [] });
            setColumnListFilter({ fetched: true, data: [], rowCount: 0 });
            const msg = getErrorMsg(err);
            setApiStatus({ status: true, timer: false, isError: true, message: 'An error occurred while fetching column data' + msg, close: true });
            setFilterValuesFetched(true);
        }
    };

    const getTableId = async id => {
        try {
            const res = await getRequest(urlGetTabelId + id);
            setFormDataTableId(res.data.data[0].datamart_table_id);
            fetchColumnData(res.data.data[0].datamart_table_id);

        } catch (err) {
            // If there is an error, return an error message
            const msg = getErrorMsg(err);
            setApiStatus({ status: true, timer: false, isError: true, message: 'An error occurred while getting table id' + msg, close: true });
        }
    };

    useEffect(() => {
        if (formType !== 'myLink' && selectedTableId) {
            fetchColumnData(selectedTableId);
        }
    }, [selectedTableId, formInfo.action]);

    useEffect(() => {
        if (selectedTableId) {
            fetchColumnData(selectedTableId);
        } else {
            getTableId(workbookViewId);
        }
    }, []);
    // Use a ref to persist the timer ID between calls
    const timeoutRef = useRef(null);
    const handleSearch = value => {

        // Clear the previous timeout, if any
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        const timeOutDelay = 250;
        // Set the new timeout to update the search term state after 500ms
        timeoutRef.current = setTimeout(() => {
            setSearchTerm(value);
        }, timeOutDelay);
    };
    const handleApiMessage = showMessage => {
        // Handle the success message here
        // For example, set the state to show or hide the message in the parent component

        setApiStatus(showMessage);
    };

    const renderMyLinkDataFormColumns = () => {
        if (columnList.fetched) {
            return (
                <MyLinkDataFormColumns
                    {...{
                        columnList,
                        handleSearch,
                        filteredColumns,
                        formInfo,
                        setColumnList,
                        setFilteredColumns,
                        handleApiMessage,
                    }}
                />
            );
        }
        return <LoadingAnimation />;
    };

    const renderMyLinkParams = () => {
        if (startEndDate.fetched) {
            return <MyLinkParams startEndDate={startEndDate} setStartEndDate={setStartEndDate} formInfo={formInfo}
                handleApiMessage={handleApiMessage} />;
        }
        return <LoadingAnimation />;
    };

    const renderHeaderSection = () => (
        <section className="myLinkDataForm-header">
            {formType === 'myData' ? (
                <p>
                    Enter a descriptive name for your My Data View. Select columns that you
                    would like to include in the view. <br />
                    Define filters for the data that you would like to focus in on and
                    Save. There is a limit of either 100,000 records or around 30mb that will be returned.
                </p>
            ) : (
                <div>
                    <p>
                        <strong>Please Note:</strong> Filters do not update to show only
                        relevant values, making it possible to set filters that return no
                        data. If the Mylink dashboard loads blank, please check/remove
                        filters.
                    </p>
                    <p>
                        <strong>Disclaimer: </strong>If you do not select a filter value for a
                        filter on this form, then the default filters in the dashboard (if
                        any) will be applied.
                    </p>
                </div>
            )}
            <div className="myLinkDataForm-btns">
                <label style={{ fontWeight: 500 }}>Name:</label>
                <input
                    className="myLinkDataForm-input"
                    label="Data Form Name"
                    onChange={e => setFormDataName(e.target.value)}
                    value={formDataName}
                    placeholder="Enter data name"
                />
                <button
                    disabled={getDisableStatus(
                        apiStatus.loading,
                        formDataName,
                        formInfo.formName
                    )}
                    onClick={handleSaveData}
                    className="btn"
                >
                    Save
                </button>
                {formInfo.generateBtn  && <button id="btn-generate"
                    onClick={handleGenerateData}
                    className="btn"
                >
                    Generate
                </button>}
            </div>
            {apiStatus.loading && (
                <LoadingAnimation width={'30px'} height={'30px'} />
            )}
            <InfoMessage
                message={apiStatus.message}
                isError={apiStatus.isError}
                onClose={() =>
                    setApiStatus({
                        status: false,
                        message: '',
                        isError: false,
                        loading: false,
                    })
                }
                status={apiStatus.status}
                timer={apiStatus.timer}
                showClose={apiStatus.close || false}
            />
        </section>
    );

    return (
        <div className="myLinkDataForm">
            {renderHeaderSection()}
            {formType !== 'myData' ? renderMyLinkParams() : null}
            {formType !== 'myLink' ? renderMyLinkDataFormColumns() : null}

            <div className="myLinkDataForm-title-box">
                Choose filter values for the given columns listed below
            </div>
            {!filterValuesFetched && <LoadingAnimation type={true} />}
            {filterValuesFetched && columnListFilter.rowCount === 0 && (
                <p>
                    Current table does not have filters to show {columnListFilter.message}
                </p>
            )}
            {filterValuesFetched && columnListFilter.rowCount !== 0 && (
                <section className="myLinkDataForm-filters">
                    {(filterValues.length > 0) && <DataFilters
                        filterValues={filterValues}
                        processedData={columnListFilter.data}
                        setSelectedFilterList={setSelectedFilterList}
                        setFilterValues={setFilterValues}
                        formInfo={formInfo}
                        handleApiMessage={handleApiMessage}
                    />}
                    {(columnIdsAboveTenValues.length > 0) && <DataAsyncSelect
                        dataURL={getAsyncFilterRecordsValueURL}
                        columnIdsAboveTenValues={columnIdsAboveTenValues}
                        columnData={columnListFilter.data}
                        setSelectedAsyncFilterList={setSelectedAsyncFilterList}
                        selectedAsyncFilterList={selectedAsyncFilterList}
                        formInfo={formInfo}
                        handleApiMessage={handleApiMessage}
                    />}
                </section>
            )}

        </div>
    );
};

MyLinkDataForm.propTypes = {
    selectedTableId: PropTypes.number,
    userId: PropTypes.string,
    getUserViews: PropTypes.func,
    formInfo: PropTypes.object,
    setIsEditClicked: PropTypes.func,
    formType: PropTypes.string,
    workbookViewId: PropTypes.any,
    productId: PropTypes.any,
    workbookId: PropTypes.any,
};
export default MyLinkDataForm;