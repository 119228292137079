//debounce function
const defaultDelay = 250;
export function debounce(fn, delay = defaultDelay) {
    let timeout;
    
    // return after set time
    // if arrgs change 
    // start again
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

// getting error message from api error
export const getErrorMsg = err => err.request?.statusText ? ` ~ ${err.request?.statusText}!` : '!';

export const getDetailErrorMsg = err => err.response?.data?.errors?.message ? ` ~ ${err.response?.data?.errors?.message}!`: '!';


// used in header component
export const groupByProductId = data => {
    const result = {};
    data.forEach(item => {
        if (result[item.product_name]) {
            result[item.product_name].push(item);
        } else {
            const newItem = { ...item };
            result[item.product_name] = [newItem];
        }
    });
      
    return result;
};

export const groupByProductAndWorkbookView = data => {
    const result = {};

    data.forEach(item => {
        const { product_name, workbook_view_name } = item;

        // If product group already exists
        if (result[product_name]) {
            // If workbook view group already exists within the product
            if (result[product_name][workbook_view_name]) {
                result[product_name][workbook_view_name].push(item);
            } 
            // If workbook view group doesn't exist within the product
            else {
                result[product_name][workbook_view_name] = [item];
            }
        } 
        // If product group doesn't exist
        else {
            result[product_name] = {};
            result[product_name][workbook_view_name] = [item];
        }
    });

    return result;
};
// used in header component
export const filterByMylinkId = (obj, mylinkId) => {
    const productKeys = Object.keys(obj);
    for (const product of productKeys) {
        const viewKeys = Object.keys(obj[product]);
        for (const view of viewKeys) {
            obj[product][view] = obj[product][view].filter(item => item.my_link_user_id !== mylinkId);
        }
    }
    return obj;
};

// used in header component
export const updateMylinkName = (obj, mylinkId, name) => {
    const productKeys = Object.keys(obj);
    for (const product of productKeys) {
        const viewKeys = Object.keys(obj[product]);
        for (const view of viewKeys) {
            obj[product][view] = obj[product][view].map(item => {
                if (item.mylink_id === mylinkId) {
                    item.mydata_view_name = name;
                }
                return item;
            });
        }
    }
    return obj;
};

// used in header component
export const removeDefaults = (obj, mylinkId, productName, workbookViewName) => {
    let ids = [];

    if (obj[productName] && obj[productName][workbookViewName]) {
        obj[productName][workbookViewName] = obj[productName][workbookViewName].map(item => {
            // Check if the item's mylink_id matches the provided mylinkId
            if (item.mylink_id === mylinkId) {
                // If the default_flag is not already set to true, set it to true and add to the ids array
                if (!item.default_flag) {
                    item.default_flag = true;
                    ids.push({ id: item.mylink_id, default_flag: true });
                } else {
                    item.default_flag = false;
                    ids.push({ id: item.mylink_id, default_flag: null });
                }
            } else {
                // For other items, if the default_flag is set, set it to null and add to the ids array
                if (item.default_flag) {
                    item.default_flag = null;
                    ids.push({ id: item.mylink_id, default_flag: null });
                }
            }
            return item;
        });
    }

    return { obj, removedRecords: ids };
};

export const formatDateTime = (date, dateOnly = false) =>{
    const two = 2;
    const three = 3;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(two, '0');
    const day = date.getDate().toString().padStart(two, '0');
    const hours = date.getHours().toString().padStart(two, '0');
    const minutes = date.getMinutes().toString().padStart(two, '0');
    const seconds = date.getSeconds().toString().padStart(two, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(three, '0');
    
    if (dateOnly)
        return `${year}-${month}-${day}`;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};
  

// used in dashboardTableau components
export const checkDefault = (links, path) => {
    
    let myLinkId = null;
    links.forEach(item => {
        if (item.dashboard_path === path && item.default_flag) {
            myLinkId = item.mylink_id;
        }
    });
    return myLinkId;
};

// used in dashboardTableau components
export const separateFiltersAndParameters = inputArray=> {
    const filtersMap = new Map();
    const parametersMap = new Map();

    inputArray.forEach(item => {
        if (item.type === 'filter') {
            // Check if the filter already exists in the map
            if (filtersMap.has(item.filter_field)) {
                // Add the new value to the existing array of values
                filtersMap.get(item.filter_field).values.push(item.filter_value_name);
            } else {
                // Create a new entry in the map
                filtersMap.set(item.filter_field, { field: item.filter_field, values: [item.filter_value_name] });
            }
        } else if (item.type === 'parameter') {
            // Parameters should be treated similarly
            if (parametersMap.has(item.filter_field)) {
                parametersMap.get(item.filter_field).value += ', ' + item.filter_value_name;
            } else {
                parametersMap.set(item.filter_field, { name: item.filter_field, value: item.filter_value_name });
            }
        }
    });

    // Convert maps to arrays
    const initialFilters = Array.from(filtersMap.values());
    const initialParameters = Array.from(parametersMap.values());

    return { initialFilters, initialParameters };
};
  
//used in dashboardTopMenu
export const getDownloadBtnClass = (mylinkStatus, mylink) => {
    if (!mylinkStatus)
        return 'mylinks-save-icon mylinks-save-disabled';
    else if (!mylink)
        return 'mylinks-save-icon mylinks-save-inactive';
    else
        return 'mylinks-save-icon';
};

export const checkSixtyDayDifference = lastDate =>{
    const givenDate = new Date(lastDate);
    const currentDate = new Date();

    const timeDiff = currentDate.getTime() - givenDate.getTime();

    const daysDiff = timeDiff / (1000 * 3600 * 24);

    return daysDiff >= 60;
};
