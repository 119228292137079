import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import {getBaseUrl} from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import {useApiSave } from './useHooks';

const urlColumn =`${getBaseUrl()}${backendURL().columnDataUrl}`;

const MyLinkDataFormColumns = ({ columnList, handleSearch, filteredColumns, formInfo, setColumnList, setFilteredColumns, handleApiMessage}) => {
    const { isLoading, error, success, handleRequest } = useApiSave();
    
    // Updates the 'isChecked' and 
    // 'columnId' properties of a column item
    const updateColumns = (columns, record, columnId) => {
        return columns.map(item => {
            if (item.id === record.id) {
                return {
                    ...item,
                    isChecked: !item.isChecked,
                    columnId: columnId || null,
                };
            }
            return item;
        });
    };

    // Handles changes to the 
    // checkbox state of a column item
    const handleCheckboxChange = async record => {
        let updatedColumns;
        let updatedFilteredColumns;
  
        if (formInfo.action === 'edit') {
        // If editing an existing form and 
        // the checkbox is being checked, 
        // delete the column ID from the server
            if (record.isChecked) {
                const resId = await handleRequest(`${urlColumn}/${record.columnId}`, 'DELETE');
                if (resId) {
                    updatedColumns = updateColumns(columnList.data, record);
                    updatedFilteredColumns = updateColumns(filteredColumns, record);
                }
            } else {
            // If editing an existing form and 
            // the checkbox is being unchecked, 
            // add the column ID to the server
                const columnData = { 
                    datamart_column_id: record.id, 
                    mylink_id: formInfo.myLinkId 
                };
                const resId = await handleRequest(urlColumn, 'POST', columnData);
                if (resId) {
                    updatedColumns = updateColumns(columnList.data, record, resId);
                    updatedFilteredColumns = updateColumns(filteredColumns, record, resId);
                }
            }
        } else {
        // If creating a new form, simply update the checkbox state
            updatedColumns = updateColumns(columnList.data, record);
            updatedFilteredColumns = updateColumns(filteredColumns, record);
        }
  
        // Update state with the new column data
        setColumnList({
            fetched: true,
            data: updatedColumns,
        });
  
        setFilteredColumns(updatedFilteredColumns);
    };

    // Handles displaying success 
    // and error messages when the 
    // 'success' or 'error' values change
    useEffect(() => {
        if(success){
            handleApiMessage({status:success, timer:1000, isError:false, message:'Changes saved!'});
        }
        if(error){
            handleApiMessage({status:true, timer:5000, isError:true, message:error});
        }
    },[success, error]);

    return (<>
        <div className='myLinkDataForm-title-box'>
          Search for and select columns to include in the view. Leave blank if you would like to include ALL columns
        </div>
        <section className='myLinkDataForm-body'>
            <div className='myLinkDataForm-column-wrapper'>
                <div className='myLinkDataForm-search'>
                    <label>Search:</label>
                    <input className='myLinkDataForm-input' label="Search" onChange={e => handleSearch(e.target.value)} data-testid='myLinkDataForm-search' />
                </div>
                <div className='myLinkDataForm-column-box'>
                    {filteredColumns.map((item, i) =>{
                    
                        if(!item.isChecked){
                            return  <label key={i} className='myLinkDataForm-input-checkbox'>
                                <input
                                    type="checkbox"
                                    name="lang"
                                    value={item.columnName}
                                    checked={item.isChecked}
                                    onChange={() => handleCheckboxChange(item)}
                                    disabled={isLoading}
                                /> {item.columnName}
                            </label>;
                    
                        }})}
                </div>
            </div>
            <div className='myLinkDataForm-column-wrapper'>
                <div className='myLinkDataForm-search'>
                    Selected:
                </div>
                <div className='myLinkDataForm-column-box'>
                    {columnList.data.map((item, i) => {
                        if(item.isChecked){
                            
                            return <label key={i} className='myLinkDataForm-input-checkbox'>
                                <input
                                    type="checkbox"
                                    name="lang"
                                    value={item.columnName}
                                    checked={true}
                                    onChange={() => handleCheckboxChange(item)}
                                    disabled={isLoading}
                                /> {item.columnName}
                            </label>;
                        }
                    }
                    
                    
                    )}
                </div>
            </div>
        </section> </>
    );
};

MyLinkDataFormColumns.propTypes = {
    columnList: PropTypes.object.isRequired,
    handleSearch: PropTypes.func.isRequired,
    filteredColumns: PropTypes.array.isRequired,
    formInfo:PropTypes.object.isRequired,
    setFilteredColumns: PropTypes.func.isRequired,
    setColumnList: PropTypes.func.isRequired,
    handleApiMessage:PropTypes.func.isRequired,
};

export default MyLinkDataFormColumns;