import React,{ forwardRef} from 'react';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './customDatePicker.css';

// CustomInput component that takes in several props 
// including value, onClick, onChange, testId and disabled
export const InputDatePicker = forwardRef(({ value, onClick, onChange, testId, isDisabled}, ref) => (
    <div style={{position:'relative'}}>
        <input 
            onClick={onClick} 
            ref={ref}
            id="rf-date"
            value={value} 
            onChange={onChange} 
            placeholder='mm/dd/yyyy'
            // test id for the input
            data-testid={testId} 
            readOnly={isDisabled} 
            disabled={isDisabled}
            className='date-picker-input'
        />
        {!isDisabled && <span className='date-picker-icon' onClick={onClick}><CalendarTodayIcon sx={{ fontSize: 14 }}/></span>}
        
    </div>
    
));

InputDatePicker.displayName = 'CustomDatePickerInput';


// PropTypes for the Input component
InputDatePicker.propTypes = {
    value: PropTypes.string,
    onClick:PropTypes.func,
    onChange:PropTypes.func,
    testId:PropTypes.string,
    isDisabled:PropTypes.bool,
};
