import React, { useEffect, useState } from 'react';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { getRequest, putRequest } from '../../services/axiosClient';
import { getErrorMsg } from '../../util/functions';
import { useParams } from 'react-router-dom';
import './dataMart.css';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import InfoMessage from '../../components/common/InfoMessage';
import { FaCheck, FaEdit, FaSave, FaArrowLeft } from 'react-icons/fa';
import CustomPopup from '../../components/common/customPopup/CustomPopup';
import DataMartPopup from './DataMartPopup';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const DataMart = ({ isAdminGroup }) => {
    /* getting tableId from data exploration page
     by useParam hooks */
    const routerParams = useParams();
    const tableId = routerParams.tableId;
    const isAdmin = isAdminGroup.includes('216978de-32af-40ae-af15-fe6c0511ba1c');
    const stringURL = '&&fields-all=id,column_name,column_name_display,description,data_type,display_order,active_flag';
    const tableInfoURL = `${getBaseUrl()}${backendURL().getDataMart_TableInfoURL}`;
    const columnInfoURL = `${getBaseUrl()}${backendURL().getDataMart_ColumnInfoURL}?table_id=${tableId}${stringURL}&sort-column_name=ASC`;
    const [apiStatus, setApiStatus] = useState({ status: false, message: '', error: false });

    const [tableInfoData, setTableInfoData] = useState({ fetched: false, data: {} });
    const [columnInfoData, setColumnInfoData] = useState({ fetched: false, data: [] });
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [clickedRowObj, setClickedRowObj] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [showEditTableInfo, setShowEditTableInfo] = useState(false);
    const [showEditTableInfoData, setShowEditTableInfoData] = useState({ table_name_display: tableInfoData?.data[0]?.table_name_display, description: tableInfoData?.data[0]?.table_name_display });
    const navigate = useNavigate();	
    /**
     * Fetches data mart tables
     * Sets the state of fetched data to 
     * be displayed on the UI
     * If there is an error, sets the state of 
     * fetched data to an empty array and logs the error
     */
    const fetchTableData = async () => {
        try {
            const res = await getRequest(`${tableInfoURL}?id=${tableId}&display-value=true`);
            if (res) {
                const tableData = res.data.data;
                setTableInfoData({ fetched: true, data: tableData });
                if (tableData) {
                    setShowEditTableInfoData({ table_name_display: tableData[0]?.table_name_display, description: tableData[0]?.description });
                }
            }
        }
        catch (error) {
            setTableInfoData({ fetched: true, data: {} });
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting table data, please refresh page or try again later' + msg, isError: true });
        } finally {
            if (showEditTableInfo) {
                setIsLoaded(false);
                setShowEditTableInfo(false);
            }
        }
    };
    /**
     * Fetches column data tables
     * Sets the state of fetched data to 
     * be displayed on the UI
     * If there is an error, sets the state of 
     * fetched data to an empty array and logs the error
     */
    const fetchColumnData = async () => {
        try {
            const res = await getRequest(columnInfoURL);
            if (res) {
                setColumnInfoData({ fetched: true, data: res.data?.data });
            }
        }
        catch (error) {
            setColumnInfoData({ fetched: true, data: [] });
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting table data, please refresh page or try again later' + msg, isError: true });
        }
    };
    // Initial API call for DBAdmin
    useEffect(() => {
        if (apiStatus.status) setApiStatus({ status: false, message: '', isError: false });

        fetchTableData();
        fetchColumnData();
    }, []);

    const editTableHandler = () => {
        setShowEditTableInfo(true);
    };

    const onChangeTableInfo = e => {
        const value = e.target.value;
        setShowEditTableInfoData({ ...showEditTableInfoData, [e.target.name]: value });
    };
    /**
     * Saving data on Put request for 
     * info data tables and
     * result displayed on the UI
     * If there is an error, logs the error
     */
    const infoTableSaveHandler = async () => {
        try {
            setIsLoaded(true);
            await putRequest(`${tableInfoURL}/${tableId}`, showEditTableInfoData);
            fetchTableData();
        }
        catch (error) {
            setColumnInfoData({ fetched: true, data: [] });
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting table data, please refresh page or try again later' + msg, isError: true });
        }
    };
    // Conditinal cloumn in case of Admin
    const editColumn = {
        accessorKey: 'data_edit',
        getCanFilter: false,
        header: 'Edit',
        hideFilter: true,
        minWidth: 60,
        cell: info => (
            <span onClick={() => handleEditClick({
                id: info.row.original.id,
                column_name: info.row.original.column_name,
                column_name_display: info.row.original.column_name_display,
                description: info.row.original.description
            })} className='btn-icon' data-testid='form-edit-data'>< FaEdit size='18px' data-testid='fa-edit'/></span>
        ),
    };

    // These are the columns for the 
    // available data marts table
    const columns = [
        {
            accessorKey: 'column_name',
            getCanFilter: false,
            header: 'Technical Name',
            minWidth: 130,
            hideFilter: false,
            textAlign: 'left'
        },
        {
            accessorKey: 'column_name_display',
            getCanFilter: false,
            header: 'Friendly Name',
            minWidth: 130,
            hideFilter: false,
            textAlign: 'left'
        },
        {
            accessorKey: 'data_type',
            getCanFilter: false,
            header: 'Data Type',
            minWidth: 130,
            hideFilter: false,
            textAlign: 'left'
        },
        {
            accessorKey: 'active_flag',
            getCanFilter: false,
            header: 'Deprecated',
            minWidth: 130,
            hideFilter: false,
            textAlign: 'center',
            cell: info => (
                info.row.original.active_flag !== true && < FaCheck size='18px' />
            ),
        },
        {
            accessorKey: 'description',
            getCanFilter: false,
            header: 'Description ',
            minWidth: 130,
            hideFilter: false,
            textAlign: 'left'
        }];
    if (isAdmin) {
        columns.unshift(editColumn);
    }
    //Edit column information handler
    const handleEditClick = record => {
        setClickedRowObj(record);
        setIsEditClicked(true);
    };
    //Cancel button action handler for close popup
    const handleCancelClick = () => {
        setIsEditClicked(false);
    };
    //info table close button handler
    const infoTableCancelHandler = () => {
        setShowEditTableInfo(false);
    };

    const backToExploreHandler = () =>{       
        navigate(-1);
    };

    return (
        <div className='data-mart-container'>
            <button className='ts-btn' onClick={backToExploreHandler}><FaArrowLeft size='18px'/><span>Back</span></button>
            <section>
                <InfoMessage message={apiStatus.message} isError={apiStatus.isError} onClose={() => setApiStatus({ status: false, message: '', isError: false })} status={apiStatus.status} timer={false} showClose={true} />
            </section>

            {(tableInfoData.fetched && columnInfoData.fetched) ? <section className='data-mart-tables'>
                <div className='table_mart-wrapper'>
                    <table className='table_mart' data-testid='infoTable'>
                        <caption>
                            <h2>Data Mart Table Information</h2> {isAdmin && !showEditTableInfo && <span onClick={editTableHandler}><FaEdit size='18px' data-testid='info-fa-edit'/></span>}
                        </caption>
                        <tbody>
                            <tr><th>Schema</th><td>{tableInfoData.data[0]?.schema_id_dv}</td></tr>
                            <tr><th>Table</th><td>{tableInfoData.data[0]?.table_name}</td></tr>
                            <tr><th>Friendly Name</th>{!showEditTableInfo ? <td>{tableInfoData.data[0]?.table_name_display}</td>
                                : <td><div className='info-form_edit-box'>

                                    <div className='info-form-text'>
                                        <div><input type='text' name='table_name_display' value={showEditTableInfoData.table_name_display} onChange={onChangeTableInfo} autoFocus />{isLoaded && <LoadingAnimation />}</div>
                                        <div><textarea type='text' name='description' value={showEditTableInfoData.description} onChange={onChangeTableInfo} ></textarea></div>
                                    </div>
                                    <div className='info-form-btns'>
                                        <span onClick={infoTableSaveHandler} data-testid='info-fa-save'><FaSave size='18px'/></span>
                                        <span className='info-form-btn-close' onClick={infoTableCancelHandler} data-testid='info-close-btn'>&times;</span>
                                    </div>
                                </div></td>
                            }</tr>
                            <tr><th>Description</th><td className='table_mart-desc'>{tableInfoData.data[0]?.description}</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className='data_mart-info-table-wrapper'>
                    <h2>Column Information</h2>
                    <DataTable
                        {...{
                            tableData:columnInfoData.data,
                            columnConfig:columns, 
                            disableDownload:true,
                            defaultFileName:'table', 
                            showFooter:false,
                        }}
                    />
                </div>
            </section> : <LoadingAnimation />}
            {isEditClicked &&
                <CustomPopup
                    formType='other'
                    tableName={`Data Form: ${clickedRowObj.column_name}`}
                    onClose={handleCancelClick}
                    minWidth='550px'
                    backColor={'#fafafa'}
                >
                    <DataMartPopup records={clickedRowObj} onClose={handleCancelClick} refetch={fetchColumnData} />

                </CustomPopup>

            }
        </div >
    );
};
DataMart.propTypes = {
    isAdminGroup: PropTypes.array
};
export default DataMart;