import React from 'react';
import styled, { keyframes, } from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * this component is meant to 
 * display when a page that does not
 * exist is routed to.
 * Allows redirect to application homepage.
 */

const Background = styled.div`
  background-image: url("https://i.imgur.com/kuW7NPy.png");
  height: 100vh;
  font-family: Quicksand;
`;
const Oops = styled.h1`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 5px;
  padding-top: 60px;
  font-family: Catamaran, sans-serif;
  color: #D15C95;

`;
const LetterCont = styled.div`
  height: 300px;
  display: flex;
  width: 500px
  margin: 0 auto;
  border-style: none;
  flex-direction: row;
  justify-content: center;
`;
const drop = keyframes`
  from{
    transform: translate(0,0)
  }
  to {
    transform: translate(0,143px)
  }
`;

const animate = keyframes`
  from{
    transform: translate(0,0) rotate(0deg);;
  }
  to {
    transform: translate(0,143px) rotate(-60deg);;
  
  }
`;

const Letter = styled.div`
  font-size: 100px;
  width: 100px;
  height: 150px;
  padding-right: 6px;
  padding-left: 6px;
  font-family: Catamaran, sans-serif;
  font-weight: 800;
  color: #D15C95;

  transform: rotateX(180deg);
  &:nth-child(-n+2) {
    animation: ${drop} 0.7s linear 1;
    animation-fill-mode: both;
  };
  &:nth-child(3) {
    position: realtive;
    animation: ${animate} 0.7s linear 1;
    animation-fill-mode: both;
  }
 
`;
// animation: ${drop } 0.7s linear 1;
// animation - fill - mode: both;


//   &:nth-child(3) {
//     color: blue;
// position: realtive;
// transform: rotate(-45deg);
//   }

const Button = styled.div`
  height: 30px;
  width: 220px;
  background: #bbb;
  border: 0px black solid;
  border-radius: 5px;
  margin: 0  auto;
  margin-top: 20px;
  text-align: center;
  padding-top: 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  opacity: 0.9;
  font-family: Quicksand, Arial Black, sans-serif;
`;
const Error404 = () => (
    <Background>
        <Oops>UhOh</Oops>
        <LetterCont>
            <Letter>4</Letter>
            <Letter>0</Letter>
            <Letter>4</Letter>
        </LetterCont>
        <Link exact to={'/home'} className={'erorr-link'}>
            <Button>Return to the homepage</Button>
        </Link>
    </Background>
);

export default Error404;
