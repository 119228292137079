import React, { useState } from 'react';
import './Searchbarnew.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Searchbarnew = ({ placeholder, data }) => {
    /* A prop type checker. It is checking if the placeholder and data props are of the correct type. */
    Searchbarnew.propTypes = {
        placeholder: PropTypes.any,
        data: PropTypes.any,
    };

    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const handleFilter = event => {
        const searchWord = event.target.value;

        let newFilter = [];
        /* A for loop that iterates through the data array. */
        for (let i in data) {
            if (Object.prototype.hasOwnProperty.call(data, i)) {
                const newData = data[i].subMenus.filter(value => value.name.toLowerCase().includes(searchWord.toLowerCase()));
                newFilter = newFilter.concat(newData);
            }
        }
        /* This is checking if the searchWord is empty, if it is, then it will set the filteredData to
        an empty array. If it is not empty, then it will set the filteredData to the newFilter. */
        if (searchWord === '') {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    // handling search result click
    /**
     * When the user clicks on a button, the page will be redirected to the page that the button is
     * linked to, and the filtered data will be cleared.
     */
    const handleClick = props => {
        navigate(props);
        setFilteredData([]);
    };
    const TEN = 10;
    return (
        <div className="search-controller">
            <button className="search-btn">
                <i className="bi bi-search"></i>
            </button>
            <input type="text" placeholder={placeholder} onChange={handleFilter} />
            {filteredData.length != 0 && (
                <div className="dataResult">
                    {filteredData.slice(0, TEN).map((value, key) => {
                        return (
                            <span
                                className="dataItem"
                                to={value.to}
                                key={key}
                                onClick={() => handleClick(value.to)}
                            >
                                <p>{value.name}</p>
                            </span>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Searchbarnew;