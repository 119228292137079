import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './uploadForm.css';
import UploadTable from '../UploadTable';
import LoadingAnimation from '../../common/loadingAnimation/LoadingAnimation';
import { _handleFileChange } from './uploadFormHandlers';
import { BiCheck,BiErrorCircle } from 'react-icons/bi';
import UploadSelectFile from '../UploadSelectFile';
import ProgressBarHandler from '../ProgressBarHandler';
import { postRequest } from '../../../services/axiosClient';


// getting unique field names from config object
const getUniqueFields = data => {
    let obj =[];
    
    for (let key in data) {
        if (data[key].unique_index  !== null) {
            obj.push(data[key].unique_index.slice(1,-1));
        }
        
    }
    return obj;
};

// adding update/add status to every row after data returned from api submit
const tableUpdate = (data, rows, tableUniqueId, setRawRow) =>{
    let newValRes = rows;
  
    data.map(item=>{
        if(item.recordType === 'new'){
            newValRes[item.recordIndex].updateResult = 'Added';
            newValRes[item.recordIndex][tableUniqueId] = item.recordId;
        }
        if(item.recordType === 'update'){
            newValRes[item.recordIndex].updateResult = 'Updated';
        }
        if(item.recordType === 'error'){
            newValRes[item.recordIndex].updateResult = item.errorMsg.split('\n')[0];
        }
    });
    setRawRow(prevState => {
        return {...prevState, rows:newValRes, apiResult:true};
    });
};
//file status obj with different conditions
const fileStatusObj = {
    default: {
        color: 'black',
        message: '',
        errorStatus: false
    },
    ready : {
        color: '#257e25',
        message: 'Ready to submit',
        errorStatus: false
    },
    error: {
        color:'#d9534f',
        message: 'Error with data',
        errorStatus: true
    },
    checking : {
        color: '#036ddf',
        message: 'Checking data...',
        errorStatus: false
    },
    checkingAsync : {
        color: '#036ddf',
        message: 'Checking async data...',
        errorStatus: false
    },
    sending : {
        color: '#257e25',
        message: 'Sending data...',
        errorStatus: false
    },
    
};

const UploadForm = props => {
    const {cancelUploadForm, Columns, Config, urlreq, getTableData, rows, tableUniqueId} = props;
    //hold data coming from input
    const [selectFile, setSelectFile] = useState({status:false});
    //holds data that is ready to submit to DB
    const [fileData, setFileData] = useState([]);
    //holds upload result status and file status
    const [uploadResult, setUploadResult] = useState({upload_status: false});
    const [fileStatus, setFileStatus] = useState({color: 'black', message:''});

    const [error, setError] = useState('');
    const [checking, setChecking] = useState(false);
    // handling parse errors
    const [parseError, setParseError] = useState(false);
    const [progressStatus, setProgressStatus] = useState(false);
    const [submit, setSubmit] = useState(false);

    //this state holds unique fields and data to use in validate function
    const [newUniqueFields, setNewUniqueFields] = useState({});
    const [rawRows, setRawRows] = useState({status:false, rows:[], errors:[], errorStatus:false});

    
    // selecting file from input value
    const handleFileChange = file =>{
        //extracted file change functionality.
        _handleFileChange({file, fileStatusObj, Config, Columns, newUniqueFields}, {setError, setFileStatus,  setChecking, setParseError,  setSubmit}, setSelectFile, setFileData, setRawRows, rows);
    };
    // when reset set all 
    // state to false or 
    // initial state 
    const resetFile = () => {
        setError('');
        setSubmit(false);
        setFileData([]);
        setParseError(false);

        setFileStatus(fileStatusObj.default);
        setUploadResult({upload_status : false});
        setSelectFile({status:false});
        setRawRows({status:false, rows:[], errors:[], errorStatus:false});
        setChecking(false);
    };

    //taking IDs and unique fields from table and 
    // setting the state to use in validation
    useEffect(() => {

        // getting all existing  record ids
        let recordIds = rows.map(item => item[tableUniqueId].toString());

        // getting all unique field names
        let uniqFields = getUniqueFields(Config);
        
        // we will send this object to validation
        const fieldObj2 = {rowPK: recordIds};
        const checkRow = (item, colName, hash) => {
            let fieldsCombined = '';
            for (let field in colName.split(',')) {
                // eslint-disable-next-line no-prototype-builtins
                if(colName.split(',').hasOwnProperty(field)){
                    fieldsCombined = fieldsCombined + '|'+ item[colName.split(',')[field]];
                }
            }
            hash[item[tableUniqueId]] = fieldsCombined;
        };
       

        // loop and add unique field data to array 
        uniqFields.forEach(colName => {

            const uniq2 = [];
            // if unique columns name includes (comma ',') 
            // we merge two field data and add it to object
            // this happens when combination of two field are unique, 
            // it comes in config as 'name,lastname'
            if(colName.includes(',')){
                const obj = {};
                rows.forEach(item => checkRow(item, colName, obj));
                for(let key in obj){
                    if(Object.prototype.hasOwnProperty.call(obj, key)){
                        uniq2.push(obj[key]);
                    }
                }
                fieldObj2[colName] = uniq2;
            } else {
                // assigning unique column name as key 
                // with array of that fields data
                rows.forEach(item => uniq2.push(item[colName]));
                fieldObj2[colName] = uniq2;
            }
        });
        // setting unique fields state
        setNewUniqueFields(fieldObj2);
    }, [rows]);
    

    // closing the popup and form
    const closeForm = () =>{
        setSelectFile({});
        cancelUploadForm();
    };

    // handling uploading to backend server
    const upload = async e => {
        e.preventDefault();
        setFileStatus(fileStatusObj.sending);
        setSubmit(false);
        setChecking(true);
        const resObj = {upload_status: true};
        setProgressStatus(true);
        try{
            const res = await postRequest(urlreq+ '/upload', fileData);
            setProgressStatus(false);
            // set res object, we will use it in
            // results panel
            resObj.message = res.data.successMsg;
            resObj.newRecord= res.data.newRecord;
            resObj.updatedRecord= res.data.updatedRecord;
            resObj.errorCount = res.data.errorCount;
            resObj.successStatus = true;
            setUploadResult(resObj);
            // change file status to success
            setFileStatus({...fileStatusObj.ready, message:'Success'});
            // update table with new data 
            // with new ids and status of file
            tableUpdate(res.data.data, rawRows.rows, tableUniqueId, setRawRows);
            setChecking(false);
            // call update table function
            getTableData();

        }catch(err){
            // setting errors
            resObj.message = 'Failed to add records';
            resObj.newRecord= 0;
            resObj.updatedRecord= 0;
            resObj.errorCount = fileData.length;
            resObj.successStatus = false;
            setUploadResult(resObj);
            setFileStatus({...fileStatusObj.error, message:'Failed to add records'});
            setChecking(false);
            setProgressStatus(false);
        }
    };
    // result icon, error or success
    const resultIcon = uploadResult.successStatus ?  <BiCheck size={40}/> : <BiErrorCircle size={40}/>; 
    // table render
    const customTableResult = rawRows.status ? <UploadTable data={rawRows} filename={selectFile.name} colNames={Columns} fileStatus={fileStatus}/> : <></>;

    return <div className='upload-file' data-testid="upload-form">
        <div className='upload-file-container'>      
            <UploadSelectFile 
                error={error} 
                handleFileChange={handleFileChange} 
                fileStatus={fileStatus} 
                selectFile={selectFile}
                resetFile={resetFile}
            />
            {uploadResult.upload_status  && 
                <div className='status-wrapper'>
                    <div className='status-success'>
                        <div className='status-message'>
                            <span className='status-icon' style={{color: fileStatus.color}}>
                                {
                                    resultIcon
                                }
                            </span> 
                            <span className='status-text' style={{color: fileStatus.color}}>{uploadResult.message}</span>
                        </div>
                        <span className='status-summary'>Below is a quick summary:</span>
                    </div>
                    <div className='status-details'>
                        <div className='details-row'>
                            <span className='details-label'>Added</span>
                            <span className='details-value'>{uploadResult.newRecord}</span>
                        </div>
                        <div className='details-row'>
                            <span className='details-label'>Updated</span>
                            <span className='details-value'>{uploadResult.updatedRecord}</span>
                        </div>
                        <div className='details-row'>
                            <span className='details-label'>Error</span>
                            <span className='details-value'>{uploadResult.errorCount}</span>
                        </div>
                        <div className='details-row'>
                            <span className='details-label'>Total</span>
                            <span className='details-value'>{uploadResult.updatedRecord + uploadResult.newRecord}</span>
                        </div>
                    </div>
                </div>
            
            }
        </div>
        {parseError && <div className='upload-error-cont'>
            <h4>Error Log:</h4>
            <div className='upload-error-log'>
                {parseError.map((item, index) => {
                    return (
                        <pre key={index}>
                            <code >
                                {(index+1) + '-' + JSON.stringify(item, null, 1)}
                            </code>
                        </pre>
                    );
                })}
            </div>
        </div>}
        
        {checking ? <LoadingAnimation width={'30px'} height={'30px'} /> : customTableResult }
        {progressStatus && <ProgressBarHandler count={fileData.length}/>}
        <div className='upload-form-btns'>
            <button className='btn btn-outlined' onClick={closeForm}>Close</button>
            <button className='btn' onClick={upload}  disabled= {submit ? false : true}>Submit</button>
        </div>
    </div>;
};

UploadForm.propTypes = {
    cancelUploadForm: PropTypes.func,
    Columns: PropTypes.array,
    Config: PropTypes.object,
    urlreq: PropTypes.string,
    getTableData: PropTypes.func,
    rows: PropTypes.array,
    tableUniqueId: PropTypes.string,
};

export default UploadForm;
