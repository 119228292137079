import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { getRequest, postRequest } from '../../services/axiosClient';
import { getErrorMsg } from '../../util/functions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import InfoMessage from '../../components/common/InfoMessage';

const MsrRequest = ({ userId, onClose }) => {
    /* getting tableId from data exploration page
     by useParam hooks */
    const msrFormURL = `${getBaseUrl()}${backendURL().msrFormURL}?filters=${userId}`;
    const msrDataURL = `${getBaseUrl()}${backendURL().msrDataURL}`;
    const msrPostDataURL = `${getBaseUrl()}${backendURL().msrPostDataURL}`;
    const [apiStatus, setApiStatus] = useState({ status: false, timer: false, isError: false, message: '', loading: false });

    const [msrDropDowns, setMsrDropDowns] = useState([]);
    const [msrInfoData, setMsrInfoData] = useState({ fetched: false, data: {} });
    const [selectedOption, setSelectedOption] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const formatDropDownData = (dropDowns, data) => {
        const formattedDropDownData = {};
        if (dropDowns?.length > 0) {
            dropDowns.forEach((dropDown) => {
                const name = dropDown.name;
                if (!formattedDropDownData[name]) {
                    const filteredData = data.filter(option => option.msr_request_dashboard === name).map(option => {
                        return {
                            label: option.mylink_name,
                            value: option.mylink_id
                        };
                    });
                    formattedDropDownData[name] = filteredData;
                }
            });
        }
        setMsrInfoData({ fetched: true, data: formattedDropDownData });
    };
    /**
     * Fetches data mart tables
     * Sets the state of fetched data to 
     * be displayed on the UI
     * If there is an error, sets the state of 
     * fetched data to an empty array and logs the error
     */
    const fetcMsrData = async () => {
        try {
            const msrDropdownList = await getRequest(msrDataURL);
            const res = await getRequest(msrFormURL);
            if (msrDropdownList) {
                const dropDownData = msrDropdownList?.data?.data;
                setMsrDropDowns(dropDownData);
            }
            if (res) {
                const msrData = res.data.data;
                if (msrDropdownList) {
                    const dropDowns = msrDropdownList?.data?.data;
                    formatDropDownData(dropDowns, msrData);
                }
            }
        }
        catch (error) {
            setMsrInfoData({ fetched: true, data: {} });
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, timer: 7000, isError: true, message: 'An error occurred while fetching data' + msg, loading: false });
        }
    };
    /**
     * Saving data on Put request for 
     * info data tables and
     * result displayed on the UI
     * If there is an error, logs the error
     */
    const postMsrData = async (event) => {
        event.preventDefault();
        try {
            const data = await postRequest(`${msrPostDataURL}/msr_request/records`, { msr_request_status_id: 1, requester_wwid: userId, mylink_start_date: startDate, mylink_end_date: endDate, name: event.target.name.value });
            if (data?.data?.data?.[0]?.id) {
                const dropdowns = Object.values(selectedOption);
                if (dropdowns?.length > 0) {
                    dropdowns.forEach(async (value) => {
                        await postRequest(`${msrPostDataURL}/msr_request_mylink/records`, { msr_request_id: data?.data?.data?.[0]?.id, mylink_id: value });
                        onClose();
                    });
                }
            }
        }
        catch (error) {
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, timer: 7000, isError: true, message: 'An error occurred while saving data' + msg, loading: false });
        }
    };

    useEffect(() => {
        fetcMsrData();
    }, []);

    const onSelectHandler = (data, name) => {
        const selectedOptionObj = { ...selectedOption };
        selectedOptionObj[name] = data.value;
        setSelectedOption(selectedOptionObj);
    };
    return (
        <div className='msr-data'>
            <form className='datagrid-form' onSubmit={postMsrData}>
                {apiStatus.status && <InfoMessage
                    message={apiStatus.message}
                    isError={apiStatus.isError}
                    onClose={() =>
                        setApiStatus({
                            status: false,
                            message: '',
                            isError: false,
                            loading: false,
                        })
                    }
                    status={apiStatus.status}
                    timer={apiStatus.timer}
                    showClose={apiStatus.close || false}
                />}
                <div><label>Name</label><input type='text' name="name" required={true} placeholder='Enter name'/></div>
                {msrDropDowns?.length > 0 && msrDropDowns.map((dropdown, index) => {
                    const name = dropdown.name;
                    const dropdownOptions = msrInfoData?.data?.[name];
                    return <div key={index}>
                        <label><span>{dropdown.name}</span></label>
                        <Select
                            defaultValue={selectedOption[name]}
                            onChange={(data) => onSelectHandler(data, name)}
                            name={name}
                            options={dropdownOptions ? dropdownOptions : []}
                            required={dropdown.mandatory_flag ? true : false}
                        />
                    </div>;

                })}
                <div className='msrDatePicker'>
                    <label><span htmlFor='start'>Start Date</span></label>
                    <DatePicker id='start' placeholderText='mm/dd/yyyy' className='kk' data-testid={'link-start-date'} selected={startDate} onChange={(date) => setStartDate(date)}/>
                </div>
                <div className='msrDatePicker'>
                    <label><span htmlFor='end'>End Date</span></label>
                    <DatePicker data-testid='link-end-date' id='end' placeholderText='mm/dd/yyyy' selected={endDate} onChange={(date) => setEndDate(date)}/>
                </div>

                <><button className='btn' type='submit'>Submit</button></>
                {apiStatus.loading && (<LoadingAnimation />)}
            </form>
        </div >
    );
};
MsrRequest.propTypes = {
    userId: PropTypes.string,
    onClose: PropTypes.func,
};
export default MsrRequest;