import React from 'react';
import CustomPopup from './customPopup/CustomPopup';
import useMainStore from '../../store/useMainStore';



const AuthReload = () => {
    const {isAuthorized, setIsAuthorized} = useMainStore();

    /**
     * The function refreshPage() will reload the page when called.
     */
    const  refreshPage = ()=> {
        window.location.reload(true);
    };

    // we will also set isUnauthorized state in redux to false,
    const handleClose = () => {
        setIsAuthorized(true);
    };

    const style = {
        display: 'flex',
        justifyContent: 'center',
        flexDDirection: 'row',
        gap: '1rem',
    };
    // only show popup if unauthorized
    if(!isAuthorized) {
        return (
            <CustomPopup zIndex={999}>
                <p style={{fontSize:18, fontWeight:400, marginBottom:'18px'}}>The system has timed out due to inactivity, please refresh page.</p>
                <div style={style}>

                    <button className='btn btn-trans' onClick={handleClose} style={{color:'blue'}}>Cancel</button>
                    <button className='btn btn-red' onClick={refreshPage}>Refresh</button>
                </div>
                
            </CustomPopup>
        );
    }

    return null;
};

export default AuthReload;