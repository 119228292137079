export const INITIAL_STATE = {

    isRequestError: false,
    //control for delete errors
    isDeleteError: false,
    requestMsg: '',
    requestStatus: null,
    isLoading: true,
    //data on configuration for forms/rows
    Rows: [],
    dvRows: null,
    openDeletePopup: false,
    targetDelete: null,
    //defines table primary key
    Config: [],
    Columns: [],
    //define form title if none if given
    tableName: '',
    rawRows: {},
    tableNameDV: '',
    //holds table unique column name
    tableUniqueId: null,
    //holds data to be added from form submission
    uploadForm: false,
    formOpen: false,
    configLoading: true,
    ACLPopup: false,
};


export const datagridReducer = (state, action) => {
    switch (action.type) {
    case 'FETCH_START':
        // Begin data fetching process
        return {
            ...state,
            isLoading:true,
        };
    case 'FETCH_END':
        // End data fetching process
        return{
            ...state,
            isLoading: false,
        };
    case 'FETCH_TABLE_AllDATA_SUCCESS':
        // Successful retrieval of all data
        return {
            ...state,
            Rows: action.Rows,
            Columns: action.Columns,
        };
    case 'FETCH_TABLE_RAW_SUCCESS':
        // Successful retrieval of raw data
        return {
            ...state,
            rawRows: action.rawRows,
            isLoading:false,
        };
    case 'FETCH_CONFIG_SUCCESS':
        // Successfully fetched configuration data
        return {
            ...state,
            Config: action.Config,
            tableName: action.tableName,
            tableNameDV: action.tableNameDV,
            tableUniqueId: action.tableUniqueId,
            configLoading: false,
        };
    case 'FETCH_UPDATE_STATUS':
        // Update the request status
        return {
            ...state,
            requestStatus: action.requestStatus,
        };
    case 'FETCH_UPDATE_ADD_SUCCESS':
        // Successfully added new data
        return {
            ...state,
            formOpen: false,
            isRequestError: false,
            isLoading: false,
        };
    case 'FETCH_UPDATE_EDIT_SUCCESS':
        // Successfully edited existing data
        return {
            ...state,
            formOpen: false,
            isLoading: false,
            formType: '',
            isRequestError: false,
        };
    case 'FETCH_UPDATE_DELETE_SUCCESS':
        // Successfully deleted data
        return {
            ...state,
            isDeleteError: false,
            Rows: action.Rows,
            isLoading: false,
            formOpen: false,
        };
    case 'FETCH_ERROR':
        // Fetching encountered an error
        return {
            ...state,
            isRequestError: true,
            requestMsg: action.requestMsg,
            requestStatus: action.requestStatus,
            isLoading: false,
        };
    case 'FETCH_DELETE_ERROR':
        // Encountered an error during data deletion
        return {
            ...state,
            isDeleteError: true,
            requestMsg: action.requestMsg,
            requestStatus: action.requestStatus,
            isLoading: false,
        };
    case 'HANDLE_UPLOAD':
        // Begin data upload
        return {
            ...state,
            uploadForm: action.uploadForm,
            ACLPopup: action.ACLPopup,
        };
    case 'HANDLE_UPLOAD_CANCEL_CLICK':
        // Cancel data upload
        return {
            ...state,
            uploadForm: false,
        };
    case 'HANDLE_ERROR_WAS_CLOSED':
        // Handle closure of error message
        return{
            ...state,
            isRequestError: false,
            isDeleteError: false,
        };
    case 'HANDLE_CANCEL_CLICK':
        // Handle cancelation of form or upload
        return{
            ...state,
            formOpen: false,
            uploadForm: false,
            isRequestError: false,
        };
    case 'HANDLE_ACL_WAS_CLOSED':
        // Close ACL Popup
        return {
            ...state,
            ACLPopup: false,
        };
    case 'HANDLE_ACL_POPUP_OPEN':
        // Open ACL Popup
        return {
            ...state,
            ACLPopup: true,
        };
    case 'FORM_OPEN':
        // Open form
        return{
            ...state,
            formOpen: true,
        };
    case 'DELETE_POPUP':
        // Handle Delete Popup
        return{
            ...state,
            openDeletePopup: action.openDeletePopup,
            targetDelete: action.targetDelete,
        };
    case 'CLEAN_STATE_USEEFFECT':
        // Reset state
        return {
            ...state,
            Rows: {},
            Columns: {},
            Config: {},
            tableName: '',
        };
    case 'SET_DISPLAY_VALUE_ROWS':
        // Set display value rows
        return{ ...state, 
            dvRows: action.dvRows
        };
    default:
        // Default case returns current state
        return state;
    }
};


