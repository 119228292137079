import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const InfoMessage = ({ message, isError, onClose, status, timer, close }) => {
    // State to manage visibility of the message
    const [showMessage, setShowMessage] = useState(status);

    // Effect to update showMessage state and set timer to hide message
    useEffect(() => {
        setShowMessage(status);

        // If timer is specified and message is shown, 
        // set timeout to hide message
        if (timer && status) {
            const timeoutId = setTimeout(() => {
                setShowMessage(false);
                onClose();
            }, timer);
            
            // Return function to clear timer when 
            // component unmounts or status changes
            return () => clearTimeout(timeoutId);
        }
    }, [status, timer, onClose]);

    // Inline styles for close button
    const closeStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ':hover': {
            opacity: '0.8',
        },
    };

    // Inline styles for message wrapper
    const wrapper = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
        borderRadius: '5px',
        paddingLeft: '10px',
        width: 'fit-content',
        paddingRight: '10px',
        backgroundColor: isError ? 'rgb(253, 236, 234)' : 'rgb(216 242 218)',
        minHeight: '40px',
    };

    // If showMessage is false, 
    // return null and don't render anything
    if (!showMessage) {
        return null;
    }

    // Render message with optional close button
    return (
        <div style={wrapper}>
            {isError && <ErrorOutlineIcon sx={{ color: 'rgb(97, 26, 21)' }} />}
            <p style={{ color: isError ? 'rgb(97, 26, 21)' : 'rgb(22 91 40)' }}> {message}</p>
            {!close && <span onClick={() => { setShowMessage(false); onClose(); }} style={closeStyle} data-testid="message-close">
                <CloseIcon sx={{ fontSize: 20, color: isError ? 'rgb(97, 26, 21)' : 'rgb(22 91 40)' }} />
            </span>}
        </div>
    );
};

InfoMessage.propTypes = {
    message: PropTypes.string,
    isError: PropTypes.bool,
    onClose: PropTypes.func,
    status: PropTypes.bool,
    timer: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    close: PropTypes.bool,
};

InfoMessage.defaultProps = {
    timer: false,
};

export default InfoMessage;
