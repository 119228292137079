import { useEffect, useState } from 'react';
import checkIfAdmin from './checkIfAdmin';
import { getBaseUrl } from '../util/getBaseUrl';
import useUserStore from '../store/user/useUserStore';
import { getRequest } from '../services/axiosClient';
import { getErrorMsg } from '../util/functions';
import useMainStore from '../store/useMainStore';

export default function useAuthentication() {

    const {userInfo, isAdmin, setUserInfo, setACL, setIsAdmin} = useUserStore();
    const {setIsAuthorized} = useMainStore();
    //error state
    const [authError, setAuthError] = useState(false);
    const [authStatus, setStatus] = useState();
    //contains array for access control list
    //defines if user is an administrator and passes context to the rest of the application
    //authentication of me call state
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');

    //makes api call to retrieve user data. Call executes any time the application is rendered 
    //into a new component
    const checkAuth = async ()=>{
        try {
            const res = await getRequest(`${getBaseUrl()}/api/me`);
            setStatus(res.request.status);
            setUserInfo(res.data.user);
            setIsAdmin(checkIfAdmin(res.data.user.groups));
            setACL(res.data.ACL);
            setIsAuthenticated(true);
            setAuthError(false);
            setIsAuthorized(true);
        } catch (error) {
            //sets status to error, which triggers auth error component
            setStatus(error?.request?.status);
            setAuthError(true);
            setStatusMsg(getErrorMsg(error));
            setIsAuthorized(false);
        }
    };
    useEffect(() => {
        checkAuth();
    }, []);

    return {authError, isAuthenticated, isAdmin, authStatus, userInfo, statusMsg};
}




