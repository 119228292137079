import React from 'react';
import { useParams } from 'react-router-dom';
import SurveyResponses from './SurveyResponses';
import './survey.css';
import ActiveSurveys from './ActiveSurveys';
import PropTypes from 'prop-types';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import { getBaseUrl } from '../../util/getBaseUrl';

const Surveys = ({userWwid}) => {
    const params = useParams();
    return (
        <div className='survey-page'>

            <ActiveSurveys wwid={userWwid}/>
            
            <SurveyResponses wwid={userWwid}/>
            {params?.id && <Survey
                baseUrl={getBaseUrl()}
                productId={params.id}
                wwid={userWwid}
                showIcon={false}
                isOpen={true}
                hideControls={true}
            />}
        </div>
    );
};

Surveys.propTypes = {
    userWwid:PropTypes.string.isRequired
};

export default Surveys;