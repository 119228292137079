import React, { useEffect, useState } from 'react';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { getRequest, postRequest } from '../../services/axiosClient';
import { Tooltip, IconButton, CircularProgress } from '@mui/material';
import { BiCopy, BiServer } from 'react-icons/bi';
import SelectDropDown from './SelectDropDown';
import Grid from '@mui/material/Unstable_Grid2';
import './DBAdmin.css';
import Box from '@mui/material/Box';
import Loading from '../../components/common/loading';
import Error from '../../components/common/error';
import { getErrorMsg } from '../../util/functions';
import InfoMessage from '../../components/common/InfoMessage';


const DBAdmin = () => {
    // Defined API DBAdmin url
    // isLoaded:  Loading state
    // isError: Error state
    // errorMessage:  Error message state
    // tabledata: for handling tables
    // ShowTableData: state for showing and hiding table on condition
    // schemaDropdowns:schema dropdown statr
    // selectedDataSource: data source state
    // status: status of error message
    const dbaAdminURL = `${getBaseUrl()}${backendURL().getDBAdminUrl}`;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [status, setStatus] = useState();
    const [schemaDropdowns, setSchemaDropdowns] = useState(null);
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [selectedSchema, setSelectedSchema] = useState('');
    const [tableData, setTableData] = useState(null);
    const [showTableData, setShowTableData] = useState(true);
    const [btnHide, setBtnHide] = useState(false);
    const [apiStatus, setApiStatus] = useState({ status: false, timer: false, isError: false, message: '', loading: false });
    const [executeLoading, setExecuteLoading] = useState(false);

    const scripts = tableData?.scripts;
    // Initial API call for DBAdmin
    useEffect(() => {
        const checkAuth = async () => {
            try {
                setIsLoaded(false);
                const res = await getRequest(dbaAdminURL);
                if (res) {
                    fetchDataSrcData(res.data.data);
                    setIsLoaded(true);
                    setIsError(false);
                }
            }
            catch (err) {
                const msg = getErrorMsg(err);
                setErrorMessage('Error with getting DB data, please refresh page or try again later' + msg);
                setIsError(true);
                setStatus(err?.request?.status);
            }
        };
        checkAuth();
    }, []);
    // This Function is call an API when both dropdown value selected 
    const submitData = async data => {
        try {
            //customTimer: handle axios custom Timer 
            const customTimer = 60000;
            setIsLoaded(false);
            // API for when botn dropdown value selected
            const tableDataUrl = `${getBaseUrl()}/api/datasrcs/${data.datasrc_id}/schemas/${data.schema_name}/dbbuild`;
            const res = await getRequest(tableDataUrl, { timeout: customTimer });
            if (res)
                setTableData(res.data);
            setIsLoaded(true);
            setIsError(false);
        }
        catch (err) {
            const msg = getErrorMsg(err);
            setErrorMessage('Error with getting DB data, please refresh page or try again later' + msg);
            setIsError(true);
            setStatus(err?.request?.status);
        }
    };
    // Function for handling both dropdown data
    //  via refactoring diffrent object for each dropdown
    const fetchDataSrcData = array => {
        let newData = {};
        array.forEach(schema => {
            const { datasrc_id, datasrc_id_dv, schema_name } = schema;
            if (newData[datasrc_id_dv]) {
                newData[datasrc_id_dv].push({
                    datasrc_id,
                    schema_name
                });
            }
            else {
                newData[datasrc_id_dv] = [{
                    datasrc_id,
                    schema_name
                }];
            }
        });
        setSchemaDropdowns(newData);
        setShowTableData(true);
    };
    //Handle function for data source dropdown
    function handleSelectedValue(value) {
        setSelectedDataSource(value);
        setShowTableData(false);
    }
    //Handle function for schema dropdown
    const handleSchemaSelection = value => {
        submitData(value);
        setShowTableData(true);
        setSelectedSchema(value);
    };

    // Execute handler when user select scripts data
    const executeHandler = async () => {
        setApiStatus({ status: false, timer: false, isError: false, message: '', loading: true });
        setExecuteLoading(true);
        try {
            // post request when scripts selected
            const dbaScriptUrl = `${getBaseUrl()}/api/datasrcs/${selectedSchema.datasrc_id}/schemas/${selectedSchema.schema_name}/dbbuild`;
            await postRequest(dbaScriptUrl, {
                action: 'execute_build',
            });
            setApiStatus({ status: true, message: 'Your statement executed Successfully', isError: false });
            setBtnHide(true);
        }
        catch (err) {
            const msg = getErrorMsg(err);
            setApiStatus({ status: true, message: 'Error while execute, please refresh page or try again later' + msg, isError: true });
        }
        setExecuteLoading(false);
    };
    {/* Display Change Tables section -- Render new col */ }
    const renderCommonTableData = (data, isErrorData) => {
        if (data?.length > 0) {
            return (data.map((name, i) =>
                isErrorData === false ? <div className='box-items' key={i}>{name}</div> : <p key={i}>{name}</p>
            )
            );
        }
    };
    {/* Display Change Tables section -- Render change col */ }
    const renderChgCol = data => {
        const dataInArray = Object.keys(data);
        if (dataInArray.length > 0) {
            return (dataInArray.map((name, i) => {
                return (
                    <div className='box-items' key={i}>
                        <div className="name">{name}</div>
                        <div>{Object.keys(data[name]).map((changes, index) =>
                            <p key={index}>{`${changes} changes from ${data[name][changes].existing} to ${data[name][changes].newbuild}`}</p>
                        )}</div>
                    </div>
                );

            })
            );
        }
    };

    {/* Display Change Tables function */ }
    const displayChangeTable = data => {
        return (
            <div className="tableData-box">
                <div className="heading-box"><h2>Change Tables section</h2></div>
                <ul>
                    {Object.keys(data).map((table, i) => {
                        return (
                            <li key={i}>
                                <h3 className='heading-padding'>{table}:</h3>
                                <div className="heading-item">
                                    {
                                        Object.keys(data[table]).map(column => {
                                            return (
                                                <div className="heading-inner" key={column}>
                                                    <div>{column}</div>
                                                    {column === 'newCol' && renderCommonTableData((data[table]).newCol, false)}
                                                    {column === 'rmvCol' && renderCommonTableData((data[table]).rmvCol, false)}
                                                    {column === 'chgCol' && renderChgCol((data[table]).chgCol)}
                                                </div>);
                                        })
                                    }
                                </div>
                            </li>);
                    })
                    }
                </ul>
            </div>
        );
    };
    {/* Display Error Tables function */ }
    const displayErrorTable = data => {
        return (
            <div className="tableData-box">
                <div className="heading-box"><h2>Errors</h2></div>
                <ul>  {
                    Object.keys(data).map((text, i) => {
                        return (
                            data[text] && <li className='box-items margin' key={i}>
                                <h3>{text}</h3>
                                {typeof data[text] === 'string' ? <p>{data[text]}</p> :
                                    Object.keys(data[text]).map((msg, index) => {
                                        return (<div className="box-items" key={index}><span>{msg}:</span>
                                            {
                                                renderCommonTableData(data[text][msg], true)
                                            }
                                        </div>);
                                    })
                                }
                            </li>
                        );
                    })
                }

                </ul>
            </div>
        );
    };

    {/* Display scripts Tables function */ }
    const displayScriptTable = data => {
        return (
            <div className="tableData-box">
                <div className="heading-box"><h2>Scripts</h2>
                    {!btnHide && <span>
                        <Tooltip title='Execute'>
                            <IconButton tooltip='Execute' className='run-btn' role='executetable' disabled={executeLoading} onClick={() => executeHandler()} data-hover='Copy'>
                                {executeLoading ? <CircularProgress style={{'color': 'white'}} size={20} /> : <BiServer size='20px' />}
                            </IconButton>
                        </Tooltip>
                    </span>}
                </div>
                <ul>
                    {Object.keys(data).map((script, i) => {
                        return (
                            data[script] && <li className='box-items margin' key={i}>
                                <h3>{script}</h3>

                                <Tooltip title='Copy'>
                                    <IconButton tooltip='Copy' className='copy-btn' role='infocopy' onClick={() => navigator.clipboard.writeText(data[script])} data-hover='Copy'>
                                        <BiCopy size='20px' />
                                    </IconButton>
                                </Tooltip>
                                <p>{data[script]}</p>
                            </li>
                        );
                    })
                    }
                </ul>
            </div>
        );
    };
    // Function for check length of table data 
    const checkLoaded = () => {
        const { chgTbls = {}, newTbls = {}, rmvTbls = {}, errors = {} } = tableData || {};
        return Object.keys(chgTbls).length > 0
            || Object.keys(errors).length > 0
            || newTbls?.length > 0
            || rmvTbls?.length > 0
            || scripts?.create_statement !== ''
            || scripts?.fk_statement !== ''
            || scripts?.unqIndx_statement !== '';
    };
    /** 
     * Function for Display fetch Tables
     *  data after selecting both dropdown 
     */ 
    const displayFetchedData = () => {
        return <div className='tableData-container'>
            {/* Display New Tables section */}
            {tableData?.newTbls?.length > 0 && <div className="tableData-box"><div className="heading-box"><h2>New Tables section</h2></div>
                <ul>
                    {tableData.newTbls.map(data => <li className='box-items' key={data}>{data}</li>)}
                </ul>
            </div>}
            {/* Display Remove Tables section */}
            {tableData?.rmvTbls?.length > 0 && <div className="tableData-box"><div className="heading-box"><h2>Remove Tables section</h2></div>
                <ul>
                    {tableData.rmvTbls.map(data => <li className='box-items' key={data}>{data}</li>)}
                </ul>
            </div>}
            {/* Display Change Tables section */}
            {tableData?.chgTbls && Object.keys(tableData.chgTbls).length > 0 &&
                displayChangeTable(tableData.chgTbls)
            }
            {/* Display Error Tables section */}
            {tableData?.errors && Object.keys(tableData.errors).length > 0 && displayErrorTable(tableData.errors)}
            {/* Display Scripts Tables section */}
            {scripts && Object.keys(scripts).length > 0 && (scripts.create_statement !== '' || scripts.fk_statement !== '' || scripts.unqIndx_statement !== '') &&
                displayScriptTable(scripts)}
        </div>;
    };
    //Function diplay data tables when both drop down value executes
    // Conditions written for Loader
    //no-data: class Display No data when both dropdown value are blank
    function renderIfLoaded() {
        if (isLoaded) {
            return (
                showTableData && checkLoaded()
                    ? displayFetchedData()
                    : <div className='no-data'><p>There is no data available for this item</p></div>
            );
        }
        return <Loading isLoading={true} />;
    }

    return (
        <div className='dba-container'>
            <h3 className='dba-heading'>Build DB via Selecting data source and schema</h3>
            <Box sx={{ width: '60%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={6}>
                        <SelectDropDown ddLabel='Data source fields' menuItem={schemaDropdowns && Object.keys(schemaDropdowns)} selectedValue={handleSelectedValue} />
                    </Grid>
                    {selectedDataSource && <Grid xs={6}>
                        <SelectDropDown ddLabel='Schema fields' menuItem={schemaDropdowns[selectedDataSource]} selectedValue={handleSchemaSelection} />
                    </Grid>
                    }
                </Grid>
            </Box>
            <section className='info-msg'>
                <InfoMessage message={apiStatus.message} timer={false} isError={apiStatus.isError} onClose={() => setApiStatus({ status: false, message: '', isError: false })} status={apiStatus.status} showClose={true} />
            </section>

            {isError ? (
                <section className='dba-error'>
                    <Error open={true} error={errorMessage} status={`${status}`} onErrorWasClosed={() => setErrorMessage('')} />
                </section>
            ) : renderIfLoaded()}

        </div>
    );
};

export default DBAdmin;