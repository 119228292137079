import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { getRequest } from '../../services/axiosClient';
import { FileManagerTable } from './FileManagerTable';
import { FilePathBar } from './FilePathBar';
import LoadingAnimation from '../common/loadingAnimation/LoadingAnimation';

import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';

import './FileManager.css';

function FileManager() {

    const [searchParams] = useSearchParams();

    const [fileData, setFileData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [pathArr, setPathArr] = useState([]);

    const [fHistory, setFHistory] = useState([]);
    const [bHistory, setBHistory] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRequest(
                    `${getBaseUrl()}${backendURL().logMappingURL}`
                );
                setFileData(res.data);
                if(searchParams.get('path')) { 
                    const p = searchParams.get('path').split('/');
                    setPathArr(['log', ...p]);
                } else {
                    setPathArr(['log']);
                }
                setBHistory([['./log']]);
            } catch (err) {
                toast.error(`Error while retrieving file list. ${err}`);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (pathArr.length > 0) {
            const f = filterFileMapping();
            setFilteredData(f);
        } else {
            setFilteredData(fileData);
        }
    }, [pathArr]);

    const handleDownload = async item => {
        try {
            const resp = await getRequest(
                `${getBaseUrl()}${backendURL().logDownloadURL}/${item.path}/${
                    item.name
                }/`,
                {
                    responseType: 'blob',
                }
            );
            const respBlob = resp.data;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(respBlob);
            link.setAttribute('download', item.name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(`Error downloading file. ${err}`);
        }
    };

    const filterFileMapping = () => {
        let filtered = fileData;
        for (let i = 0; i < pathArr.length > 0; i++) {
            filtered = filtered.filter(e => e.name === pathArr[i]);
            filtered = filtered[0].children;
        }
        if(filtered.length === 0) {
            handleBackward();
            toast.warn('The selected folder does not contain any files.');
        }
        return filtered
            .sort((a, b) => b.name.localeCompare(a.name))
            .sort((a, b) => {
                if(!a.isDir && !b.isDir) return(new Date(b.modifiedTime) - new Date(a.modifiedTime));
                else
                    return 0;
            })
            .sort(x => (x.isDir ? -1 : 1));
    };

    const handlePathChange = itemName => {
        if (pathArr.includes(itemName)) {
            const newPath = pathArr.slice(0, pathArr.indexOf(itemName) + 1);
            setBHistory([...bHistory, pathArr]);
            setPathArr(newPath);
        } else {
            const newPath = pathArr.concat(itemName);
            setBHistory([...bHistory, pathArr]);
            setPathArr(newPath);
        }
        setFHistory([]);
    };

    const handleForward = () => {
        if (fHistory.length > 0) {
            setBHistory([...bHistory, pathArr]);
            setPathArr(fHistory[fHistory.length - 1]);
            setFHistory(fHistory.slice(0, -1));
        }
    };

    const handleBackward = () => {
        if (bHistory.length > 1) {
            setFHistory([...fHistory, pathArr]);
            setPathArr(bHistory[bHistory.length - 1]);
            setBHistory(bHistory.slice(0, -1));
        }
    };

    return (
        <div>
            <ToastContainer theme='dark' position='top-center' />
            {filteredData.length > 0 ? (
                <div>
                    <FilePathBar
                        pathArr={pathArr}
                        handlePathChange={handlePathChange}
                        handleForward={handleForward}
                        handleBackward={handleBackward}
                    />
                    <FileManagerTable
                        data={filteredData}
                        handlePathChange={handlePathChange}
                        handleDownload={handleDownload}
                    />
                </div>
            ) : (
                <LoadingAnimation />
            )}
        </div>
    );
}

export default FileManager;
