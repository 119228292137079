
import React, { useEffect, useState, } from 'react';
import { DatagridBox } from './Datagrid/DatagridBox';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { backendURL, httpStatuses } from '../../config/constants';
import {getBaseUrl} from '../../util/getBaseUrl';
import Loading from '../../components/common/loading';
import Error from '../../components/common/error';
import SideBar from './Sidebar/SideBar';
import './dataMaintenance.css';
import useUserStore from '../../store/user/useUserStore';
import { getRequest } from '../../services/axiosClient';


const masterUrl = `${getBaseUrl()}${backendURL().listOfTablesGenerator}`;
const detailDataUrl = `${getBaseUrl()}${backendURL().detailsTableData}`;

const NoticeBase = ({ tableId }) =>
    !tableId && <h1 className='notice-base'><center>Please select a table...
        If you are unable to see the table you are looking for, please contact your Admin </center>
    </h1>;

const NoticeNoTable = ({ tableId, allowedTables, validTables }) =>{

    if(allowedTables && validTables){
        return (
            <>{!allowedTables?.includes(parseInt(tableId)) && !validTables.includes(parseInt(tableId)) && tableId &&
                <h1 className='notice-noTable'><center>This Table no longer exists...</center>
                </h1>
            }</>
        );
    }
    return null;
};

const NoticeNoAccess = ({ tableId, allowedTables, validTables }) => {
    if(allowedTables && validTables){
        return (
            <>
                {
                    !allowedTables.includes(parseInt(tableId)) && validTables.includes(parseInt(tableId)) &&
                    <h1 className='notice-noAccess'><center>You do not have access to this table, please contact your Admin</center></h1>
                }
            </>
        );
    }
    return null;
};


const DatagridBoxContainer = ({ tableId, recordId, action, query, masterTableInfoKey }) => {
    if(masterTableInfoKey){
        return (
            <>
                {
                    tableId && <DatagridBox
                        url={masterTableInfoKey[tableId]?.url}
                        ACL={masterTableInfoKey[tableId]?.table_acl}
                        tableId={tableId}
                        recordId={recordId}
                        action={action}
                        query={query}
                    />
                }
            </>
        );
    }
    return null;
};
    

const DataMaintenance = props => {

    // --------- objects ------
    const {tableId, action, recordId, query} = useParams();
    const {ACL} = useUserStore();
    //loading state
    const [isLoaded, setIsLoaded] = useState(false);
    let menuItemHold = [];
    //list of table names
    //error state
    const [isError, setIsError] = useState(false);
    //status message
    const [status, setStatus] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const [masterTableInfoKey, setMasterTableInfoKey] = useState();

    //list of json objects to define menu item
    const [menuItems, setMenuItems] = useState([]);

    //keeps track of all allowed and existing tables that user can access.
    //includes list of all tables to ensure there is no confusion if user is unable to access them
    const [allowedTables, setAllowedTables] = useState([]); 
    const [validTables, setValidTables] = useState([]); 

    const createTableValidationList = (data, func) => {
        let temp = [];
        for (let i in data) {
            // eslint-disable-next-line no-prototype-builtins
            if(data.hasOwnProperty(i)){
                temp.push(data[i].id);
            }
        }
        func(temp);
    };
    /** makes api calls to get table creation data.
     * brings in all tables 
     * Allows for validation of table ID
     */
    const getMasterTableDataPromise = async ()=>{
        try {
            const res = await getRequest(masterUrl);
            createTableValidationList(res.data.allTables.data, setValidTables);
            createTableValidationList(res.data.data, setAllowedTables);
            //initiates creation of sidebar based on returned data.
            createSidebarWithParams(res.data.data); 
            setStatus(httpStatuses.OK);
            setIsError(false);
            setIsLoaded(true);

        } catch(err){
            setStatus(err.request?.status || httpStatuses.BAD_REQUEST);
            setErrorMessage(err?.request?.statusText || 'Network Error!');
            setIsError(true);
        }
    };
    useEffect(() => {
        props.setTitle('Data Maintenance');
        getMasterTableDataPromise();

    }, [tableId]);
    
    //Creates menu obejcts for use in sidebar
    const createSidebarWithParams = menuList => {
        if (menuList.length > 0) {
            let menuObjects = {};
            //masterDict used to pull url and table_acl in datagrid.
            let masterDict = {};
            menuList.map(item => {
                let table_acl;
                for (let i in ACL) {if (ACL[i].table_name == item.table_name){
                    table_acl=ACL[i];
                }}
                //declare objects for use in menuitem
                let table_name = item.table_name;
                let table_name_display = item.table_name_display;
                let schema_name = item.schema_id_dv;
                let schema_name_display = item.schema_name_display;
                let table_id = item.id;
                let url = `${detailDataUrl}${schema_name}/tables/${table_name}`;
                masterDict[table_id] = {url: url, table_acl: table_acl};

                if (!Object.prototype.hasOwnProperty.call(menuObjects, schema_name)) {
                    menuObjects[schema_name] = {
                        subMenus: [],
                        name: schema_name_display,
                        to: '#',
                        active:false
                    };
                }
                //creation of menu item
                const menuObject = {
                    name: table_name_display ? table_name_display : table_name,
                    schema_name: schema_name,
                    to: `${table_id}`,
                    url: url, 
                    table_acl:table_acl,
                    recordId:recordId,
                    action:action,
                    query:query
                };
                //acts like creating a new class object. Used to assign action to route links in router using sidebar.
                menuObjects[schema_name].subMenus.push(menuObject);
                menuItemHold = [
                    ...menuItemHold,
                    {
                        name: table_name_display ? table_name_display : table_name,
                        schema_name: schema_name,
                        to: `${table_id}`,
                        url: url, 
                        table_acl:table_acl,
                        recordId:recordId,
                        action:action,
                        query:query
                    },
                ];
            });
            setMasterTableInfoKey(masterDict);
            let menuArr = [];
            for (let i in menuObjects) {
                if (Object.prototype.hasOwnProperty.call(menuObjects, i)) {
                    menuArr.push(menuObjects[i]);
                }
            }
            setMenuItems(menuArr);
        }
    };

    const onErrorWasClosed = () => {
        setErrorMessage('');
    };

    // menu close open
    const [isOpen, setIsOpen] = useState(true);
    let containerClass = isOpen ? 'container' : 'container inactive';
    if(isError){
        return (
            <div>
                <Error open={true} error={errorMessage} status = {`${status}`} onErrorWasClosed={onErrorWasClosed} />
            </div>
            
        );
    }
    
    return (
        <div>
            {isLoaded ? (
                <div className='wrapper'>
                    <SideBar menuItems={menuItems} isOpen={isOpen} setIsOpen={setIsOpen} />
                    <div className={containerClass}>
                        <NoticeBase tableId={tableId} />
                        <NoticeNoTable tableId={tableId} allowedTables={allowedTables} validTables={validTables} />
                        <NoticeNoAccess tableId={tableId} allowedTables={allowedTables} validTables={validTables} />
                        <DatagridBoxContainer tableId={tableId} recordId={recordId} action={action} query={query} masterTableInfoKey={masterTableInfoKey} />
                    </div>
                </div>
            ) : (
                <Loading isLoading={true} />
            )}
        </div>
    );
};
DataMaintenance.propTypes = {
    setTitle:PropTypes.any,
};
NoticeBase.propTypes = {
    tableId: PropTypes.string,
};

NoticeNoTable.propTypes = {
    tableId: PropTypes.string,
    allowedTables: PropTypes.arrayOf(PropTypes.number),
    validTables: PropTypes.arrayOf(PropTypes.number),
};

NoticeNoAccess.propTypes = {
    tableId: PropTypes.string,
    allowedTables: PropTypes.arrayOf(PropTypes.number),
    validTables: PropTypes.arrayOf(PropTypes.number),
};

DatagridBoxContainer.propTypes = {
    tableId: PropTypes.string,
    recordId: PropTypes.string,
    action: PropTypes.string,
    query: PropTypes.object,
    masterTableInfoKey: PropTypes.object,
};

export default DataMaintenance;