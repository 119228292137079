import {ValidationError, string, array, object }from 'yup';
import { postRequest } from '../../../services/axiosClient';
import { getBaseUrl } from '../../../util/getBaseUrl';
import Papa from 'papaparse';

function getKeyByValue(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value);
}
const handleFrontendDVField = (row, key, config, asyncValues, obj) => {
    if (row[key] !== '') {
        if (asyncValues[key] && config[key].async_lookup_flag) {
            obj[key] = asyncValues[key].result[row[key]][0];
        } else {
            obj[key] = getKeyByValue(config[key].display_values, row[key]);
        }
    }
};

const handleNonFrontendDVField = (row, key, config, obj) => {
    if (row[key] === '' && config[key].is_primary_key === 0) {
        obj[key] = null;
    } 
    if (row[key] !== '') {
        obj[key] = row[key];
    }
};

// removing empty string fields from data we are sending to backend
const cleanseEmptyColumns = (data, config, asyncValues) => {
    return  data.map(row => {
        let obj = {};
        for (let key in row) {
            if ((row[key] !== '') && config[key]._frontend_dv_field) {
                handleFrontendDVField(row, key, config, asyncValues, obj);
            } else {
                handleNonFrontendDVField(row, key, config, obj);
            }
        }
        return obj;
    });
};


// Function to filter out certain fields in rows
const filterRows = (rows, fieldsToExclude) => {
    return rows.map(row => {
        return Object.fromEntries(
            Object.entries(row).filter(([key]) => !fieldsToExclude.includes(key))
        );
    });
};

// Function to check for column existence and errors
const checkColumnErrors = (rows, config) => {
    let errors = [];
    let hasError = false;

    Object.keys(rows[0]).forEach(key => {
        const normalizedKey = key.replace('_dv', '');
        if (!config[normalizedKey]) {
            hasError = true;
            errors.push({
                'No': -1,
                'message': `Column ${normalizedKey}, does not exist in table!`
            });
        }
    });

    return { hasError, errors };
};

// Function to clean up row keys and update config
const cleanupRowsAndUpdateConfig = (rows, config) => {
    return rows.map(row => {
        let newRow = { ...row };
        Object.keys(newRow).forEach(key => {

            // Check if the key ends with '_dv'.
            if (key.endsWith('_dv')) {
                // Create a new key by removing '_dv' from the end.
                const newKey = key.replace('_dv', '');
                
               
                // If the row has an equivalent field without '_dv',
                // delete the '_dv' field.
                // eslint-disable-next-line no-prototype-builtins
                if (row.hasOwnProperty(newKey)) {
                    delete newRow[key];
                } else {
                    // If there's no equivalent field without '_dv',
                    // rename the '_dv' field by removing '_dv'.
                    newRow[newKey] = newRow[key];
                    delete newRow[key];
                    // Mark the equivalent field in the config file as a dv field
                    config[newKey]._frontend_dv_field = true;
                }
               
                
            } else{
                
                config[key]['_frontend_dv_field'] = false;
                
            }
        });
        return newRow;
    });
};

const checkColumns = (columns, rows, config) => {
    const fieldsToExclude = ['audit__create_user', 'audit__create_date'];
    let newRows = filterRows(rows, fieldsToExclude);

    const { hasError, errors } = checkColumnErrors(newRows, config);
    if (hasError) {
        return { 'column_error_status': hasError, 'validation_results': errors, rows, newConfig: config };
    }

    newRows = cleanupRowsAndUpdateConfig(newRows, config);

    // if uploaded table has more columns we give error
    if (Object.keys(newRows[0]).length !== columns.length) {
        return {
            'column_error_status': true,
            'validation_results': [...errors, { 'No': -1, 'message': 'Column length is not equal with table.' }],
            rows: newRows,
            newConfig: config
        };
    }

    return { 'column_error_status': false, 'validation_results': errors, rows: newRows, newConfig: config };
};

const two =2;
function validateDateFormat(dateString) {
    const dateParts = dateString.split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[two]);
    return date && (date.getMonth() + 1) == dateParts[1] && date.getDate() == Number(dateParts[two]);
}

//check valid date
function isValidDate(dateString) {
    // Check if date string matches the format YYYY-MM-DD
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        return {isValid:false, error:'Invalid date format, expected "YYYY-MM-DD"!'};
    }

    if (!validateDateFormat(dateString)) {
        return {isValid: false, error: 'Invalid date value!'};
    }

    return {isValid: true, error: ''};
}

const twentyThree = 23;
const fiftyNine = 59;
// checking valid time 
function validateTimeFormat(timeString) {
    const timeParts = timeString.split(':');
    return timeParts[0] >= 0 && timeParts[0] <= twentyThree && timeParts[1] >= 0 && timeParts[1] <= fiftyNine && timeParts[two] >= 0 && timeParts[two] <= fiftyNine;
}

// check timestamp
// Function to validate if a string has a correct timestamp format
function isValidTimestamp(timestampString) {

    // Check if the timestamp string matches the expected format "YYYY-MM-DD HH:MM:SS"
    // If not, return an object with status as false and an error message
    if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(timestampString)) {
        return {isValid: false, error: 'Invalid timestamp format, expected format "YYYY-MM-DD HH:MM:SS"!'};
    }

    // Split the timestamp string into date and time parts
    const dateTimeParts = timestampString.split(' ');

    // Check if the date part of the timestamp string is valid
    // If not, return an object with status as false and an error message
    if (!validateDateFormat(dateTimeParts[0])) {
        return {isValid: false, error: 'Invalid date value!'};
    }

    // Check if the time part of the timestamp string is valid
    // If not, return an object with status as false and an error message
    if (!validateTimeFormat(dateTimeParts[1])) {
        return {isValid: false, error: 'Invalid time value!'};
    }

    // If both date and time parts are valid, return an object with status as true and no error message
    return {isValid: true, error: ''};
}

const maxChar = 4000;
const maxNumber = 2147483647;
const ten = 10;

const validationChecks = {
    integer: value => {
        const isValid = /^[+-]?\d+$/.test(value) && Number(value) <= maxNumber;
        const error = 'Invalid integer value!';
        return { isValid, error };
    },
    bigint: value => {
        const isValid = /^[+-]?\d+$/.test(value);
        const error = 'Invalid integer value!';
        return { isValid, error };
    },
    boolean: value => {
        const isValid = ['true', 'false'].includes(String(value).toLowerCase());
        const error = 'Invalid value, it must be only true or false!';
        return { isValid, error };
    },
    'character varying': (value, details) => {
        const maxLength = details.character_maximum_length || maxChar;
        const isValid = typeof value === 'string' && value.length <= maxLength;
        const error = `Character length must be less than ${maxLength}!`;
        return { isValid, error };
    },
    numeric: (value, details) => {
        const numericValue = Number(value);
        const maxNumericValue = Math.pow(ten, details.numeric_precision - details.numeric_scale);
        const isValid = !isNaN(numericValue) && numericValue <= maxNumericValue;
        const error = 'Invalid value, it must be valid decimal number!';
        return { isValid, error };
    },
    date: isValidDate,
    'timestamp without time zone': isValidTimestamp
};

function createValidatorTest(type, details) {
    return (value, context) => {
    // bypass the test for null values
        if (value === null || value === '') {
            return true; 
        }

        const check = validationChecks[type];

        if (check) {
            const { isValid, error } = check(value, details);

            if (!isValid) {
                throw new ValidationError({
                    name: 'ValidationError',
                    value: context.originalValue,
                    path: context.path,
                    errors: [error],
                    inner: [],
                    type: type,
                });
            }
        }

        return true;
    };
}


const createYupSchema = (dbSchema, uniqArr) => {
    const yupSchema = {};
  
    Object.entries(dbSchema).forEach(([field, details]) => {
        // base validator
        let validator = string(); 

        if (details.foreign_table_name && !details.async_lookup_flag) {
            let options = [];

            let foreign_values = details.display_values;
            if(details._frontend_dv_field){
                Object.keys(foreign_values).forEach(item => {
                    options.push(foreign_values[item]);
                });
            } else {
                options = Object.keys(foreign_values);
            }

            const foreignValues = new Set(options);
            validator = validator
                .test('is-foreign', (value, context) => {
                    // bypass the test for null values
                    if(value === null || value === '') {
                        return true; 
                    }
                    if (!foreignValues.has(value)) {
                        throw new ValidationError({
                            name: 'ValidationError',
                            value: context.originalValue,
                            path: context.path,
                            errors: ['Invalid value, it doesnt exist in foreign table!'],
                            inner: [],
                            type: 'foreign',
                        });
                    }
                    return true;
                });
        }
        // check fields if it not foreign key or if it is foreign key and async check
        if(!details.foreign_table_name || (details.foreign_table_name && details.async_lookup_flag && !details._frontend_dv_field) ) {
        // Create a validator based on the data type.
            const validatorMapping = {
                'integer': 'is-valid-integer',
                'bigint': 'is-valid-bigint',
                'boolean': 'is-valid-boolean',
                'character varying': 'is-valid-character-varying',
                'numeric': 'is-valid-numeric',
                'date': 'is-valid-date',
                'timestamp without time zone': 'is-valid-timestamp'
            };
        
            if(Object.keys(validatorMapping).includes(details.data_type)) {
                validator = validator.test(validatorMapping[details.data_type], createValidatorTest(details.data_type, details));
            } else {
                validator = string();
            }
        } 

        
        // Transform empty strings to null for not required fields.
        validator = validator.nullable().transform((value, originalValue) => originalValue === '' ? null : value);

        // Make the field required if necessary.
        // If the field is a primary key and auto_increment is 0, the field is required.
        // Otherwise, the field is not required.
        if(details.is_primary_key === 1){
            if(details.auto_increment === '0'){
                validator = validator
                    .test('is-primary-required', (value, context)=>{
                        if(value === null || value ===''){
                            throw new ValidationError({
                                name: 'ValidationError',
                                value: context.originalValue,
                                path: context.path,
                                errors: ['Empty field, expected a value!'],
                                inner: [],
                                type: 'primary',
                            });
                        }
                        return true;
                    });
            } else {
                const primaryKeys = new Set(uniqArr.rowPK); 
                validator = validator
                    .test('is-primary', (value, context) => {
                        if(value === null || value ==='') {
                            return true; 
                        }
                        if(!primaryKeys.has(String(value))) {
                            throw new ValidationError({
                                name: 'ValidationError',
                                value: context.originalValue,
                                path: context.path,
                                errors: [`${details.column_name} does not exist in the table!`],
                                inner: [],
                                type: 'primary',
                            });
                        }
                        return true;
                    });
            }
        } 
        if ((details.is_required === 1) && (details.is_primary_key !== 1)) {
            validator = validator
                .test('is-required', (value, context) => {
                    if(value === null || value ==='') {
                        throw new ValidationError({
                            name: 'ValidationError',
                            value: context.originalValue,
                            path: context.path,
                            errors: ['Empty field, expected a value!'],
                            inner: [],
                            type: 'primary',
                        });
                    }
                    return true;
                });
        } 
        yupSchema[field] = validator;
    });

    return array().of(object().shape(yupSchema));
};

// Function to extract unique fields from the field data
const getUniqueFields = fieldData => {
    // Check if the field data has a unique index and if it has more than one field
    if (fieldData.unique_index) {
        const uniqueKey = fieldData.unique_index.replace(/[{}]/g, '');
        const uniqueFields = uniqueKey.split(',');
        return { uniqueKey, uniqueFields };
    }
    return null;
};

// Function to create an error object in a specific format
const createErrorObject = (index, field, uniqueFields, combination) => {
    if(uniqueFields.length >1){
        return {
            name: 'ValidationError',
            value: 'test',
            path: `[${index}].${field}`,
            errors: [
                `Non-unique combination for fields ${uniqueFields.join(', ')}: ${combination}.`
            ],
            type: 'unique-combination'
        };
    }

    return {
        name: 'ValidationError',
        value: 'test',
        path: `[${index}].${field}`,
        errors: [
            `'${combination}' is already exist in table!`
        ],
        type: 'unique-combination'
    };
};

const checkCombinedUniqueFields = ({row, existingRows, uniqueKey, uniqueFields}, newRowsUniqueCombinations, errorMessages, index, field, checker) => {
    const combinations = new Set();
    existingRows.forEach(existingRow => {
        if (Number(existingRow.id) !== Number(row.id)) {
            const combin = uniqueFields.map(uniqueField => existingRow[uniqueField]).join('|');
            combinations.add('|' + combin);
        }
    });

    const combination = uniqueFields.map(uniqueField => row[uniqueField]).join('|');

    if (!newRowsUniqueCombinations[uniqueKey]) {
        newRowsUniqueCombinations[uniqueKey] = new Set();
    }

    if ((combinations.has('|' + combination) || newRowsUniqueCombinations[uniqueKey].has('|' + combination))  && !checker.status) {
        const errObj = createErrorObject(index, field, uniqueFields, combination);
        errorMessages.push(errObj);
    } else {
        checker.status = true;
        newRowsUniqueCombinations[uniqueKey].add('|' + combination);
    }
};

const checkSingleUniqueField = ({row, existingRows, uniqueKey, uniqueFields}, newRowsUniqueCombinations, errorMessages, index, field, checker2) => {
    const unique = new Set();
    existingRows.forEach(existingRow => {
        if (Number(existingRow.id) !== Number(row.id)) {
            unique.add(existingRow[uniqueKey]);
        }
    });

    if (!newRowsUniqueCombinations[uniqueKey]) {
        newRowsUniqueCombinations[uniqueKey] = new Set();
    }

    if ((unique.has(row[uniqueKey]) || newRowsUniqueCombinations[uniqueKey].has(row[uniqueKey]))&& !checker2.status) {
        const errObj = createErrorObject(index, field, uniqueFields, row[uniqueKey]);
        errorMessages.push(errObj);
    } else {
        newRowsUniqueCombinations[uniqueKey].add(row[uniqueKey]);
        checker2.status = true;
    }
};

const uniqueCheckMultiple = (rows, dbSchema, existingRows) => {
    let errorMessages = [];
    const newRowsUniqueCombinations = {}; 

    rows.forEach((row, index) => {
        let checker = {status:false};
        let checker2 = {status:false};
        Object.keys(dbSchema).forEach(field => {
           
            const uniqueData = getUniqueFields(dbSchema[field]);
            if (uniqueData) {
                const { uniqueKey, uniqueFields } = uniqueData;
                if (uniqueFields.length > 1) { 
                    checkCombinedUniqueFields({row, existingRows, uniqueKey, uniqueFields}, newRowsUniqueCombinations, errorMessages, index, field, checker);
                } else  {
                    checkSingleUniqueField({row, existingRows, uniqueKey, uniqueFields}, newRowsUniqueCombinations, errorMessages, index, field, checker2);
                }
            }
        });
    });

    const uniqueStatus = errorMessages.length === 0 ? false : true;
    return { uniqueStatus, uniqueErrors: errorMessages };
};


  
// Function to prepare an object (uniqueArrList) with all async fields 
// and their respective data and URL for further lookup and a flag to 
// check if any field requires async check.
const initializeUniqueField = (field, dbSchema) => {
    const fkLookup = dbSchema[field]._frontend_dv_field ? dbSchema[field].ref_display_value : dbSchema[field].foreign_column_name;
    const returnCol = dbSchema[field]._frontend_dv_field ? dbSchema[field].foreign_column_name : dbSchema[field].ref_display_value;
    return {
        data: [],
        url: `${dbSchema[field].foreign_schema_name}/tables/${dbSchema[field].foreign_table_name}/fk_lookup/${fkLookup}/${returnCol}`,
        result: [],
    };
};

const processRow = (row, dbSchema, uniqueArrList) => {
    let hasAsyncCheck = false;
    // Iterate over each field in the schema
    Object.keys(dbSchema).forEach(field => {
        const isAsyncLookup = dbSchema[field].async_lookup_flag;
        const rowFieldNotEmpty = row[field] !== '';

        // If field is an async field
        if (isAsyncLookup) {
            // If not already added, add field to uniqueArrList
            if (!uniqueArrList[field]) {
                uniqueArrList[field] = initializeUniqueField(field, dbSchema);
            }

            // If field in row is not empty, update hasAsyncCheck flag
            // and add value to data array of field in uniqueArrList
            if (rowFieldNotEmpty) {
                hasAsyncCheck = true;
                uniqueArrList[field].data.push(row[field]);
            }
        }
    });
    return hasAsyncCheck;
};

const mapAsyncFields = (rows, dbSchema) => {
    const uniqueArrList = {};
    let hasAsyncCheck = false;

    // Iterate over each row
    rows.forEach(row => {
        hasAsyncCheck = processRow(row, dbSchema, uniqueArrList) || hasAsyncCheck;
    });

    return {uniqueArrList, hasAsyncCheck};
};



// Function to create a validation error object
const createValidationError = (index, field, message) => {
    return {
        name: 'ValidationError',
        value: 'test',
        path: `[${index}].${field}`,
        errors: [message],
        type: 'async-check'
    };
};

// Function to check each row for async lookup errors
const checkForErrors = (rows, dbSchema, uniqueArrList) => {
    const asyncErrors = [];

    // Iterate over each row
    rows.forEach((row, index) => {
        // Iterate over each field in the schema
        Object.keys(dbSchema).forEach(field => {
            // If field is an async lookup field
            if (dbSchema[field].async_lookup_flag) {
                const valueField = uniqueArrList[field].result[row[field]];
                let errObj;

                // If field is undefined or has multiple records in the lookup results
                // create validation error object
                if(!valueField){
                    errObj = createValidationError(index, field, 'This value does not exist in database!');
                }
                if(valueField?.length > 1) {
                    const message = `Multiple records found, use ${dbSchema[field].foreign_column_name} instead!`;
                    errObj = createValidationError(index, field, message);
                }
                // If field in row is not empty and error object is created, push it to asyncErrors
                if(errObj && row[field] !== '') {
                    asyncErrors.push(errObj);
                }
            }
        });
    });

    return asyncErrors;
};


const asyncFieldCheck = async (rows, dbSchema) => {
    const {uniqueArrList, hasAsyncCheck} = mapAsyncFields(rows, dbSchema);
    const asyncErrors = [];
    if (!hasAsyncCheck) {
        return {asyncErrors, asyncErrorStatus:false, uniqueArrList, apiError:''};
    }

    try {
        const apiCallArray = [];
        for(let field in uniqueArrList) {
            if(uniqueArrList[field].data.length > 0){
                const url = getBaseUrl() + '/api/datasrcs/1/schemas/' + uniqueArrList[field].url;
                const promise = postRequest(url, uniqueArrList[field].data);
                apiCallArray.push({
                    field,
                    promise
                });
            }
        }
        
        const results = await Promise.all(apiCallArray.map(e => e.promise));
        for (let i = 0; i < results.length; i++) {
            uniqueArrList[apiCallArray[i].field].result = results[i].data.data;
        }

        const newAsyncErrors = checkForErrors(rows, dbSchema, uniqueArrList);

        return { asyncErrors: newAsyncErrors, asyncErrorStatus: newAsyncErrors.length > 0, uniqueArrList};
          
    } catch (error) {

        return { asyncErrors:[], asyncErrorStatus:true, uniqueArrList, apiError:'-' + error?.response?.data?.message || '!'};
    }
};


const _handleFileChange = ({file, fileStatusObj, Config, Columns, newUniqueFields}, {setError, setFileStatus,  setChecking, setParseError,  setSubmit}, setSelectFile, setFileData, setRawRows, tableData) => {
    // if no file is entered, just return
    if (!file.length) return;

    const inputFile = file[0];

    const fileExtension = inputFile?.type.split('/')[1];
    setSelectFile({name: inputFile.name, size: inputFile.size, status:true});

    // error handling for file type and file size
    if ('csv' !== fileExtension) {
        setError('Please select a CSV file');
        setFileStatus({...fileStatusObj.error, message: 'File error!'});
        return;
    }
    // return if file size is more that 10 megabytes 
    const maxAllowedSize = 10000000;
    if(inputFile.size > maxAllowedSize ){
        setError('Please select file smaller than 10MB');
        setFileStatus({...fileStatusObj.error, message: 'File size error'});
        return;
    }

    //set file status to checking 
    setFileStatus(fileStatusObj.checking);
    setChecking(true);

    // converting csv to JSON with papa parse
    Papa.parse(inputFile, {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
            // if there  is parse error set error
            if(results.errors.length > 0) {
                setParseError(results.errors);
                setFileStatus(fileStatusObj.error);
                setChecking(false);
                return;
            }
            
            // if no records found give error
            if(results.data.length === 0) {
                setParseError(['No records found!']);
                setFileStatus(fileStatusObj.error);
                setChecking(false);
                return;
            }

            // start validation
            let {column_error_status, rows,validation_results, newConfig } = checkColumns(Columns, results.data, Config);
            if (column_error_status) {
                setRawRows({status:true, rows:[], errors:validation_results, errorStatus:true, errorType:'column'});
                setFileStatus(fileStatusObj.error);
                setChecking(false);
                return;
            }
                        
            // Creating a Yup schema for validation
            const yupSchema = createYupSchema(newConfig, newUniqueFields);
            const { uniqueStatus, uniqueErrors } = uniqueCheckMultiple(rows, Config, tableData);
            // Yup Validation
            yupSchema
                .validate(rows, { abortEarly: false })
                .then(() => {
                    // If there are unique errors, set state to error and show errors
                    if (uniqueStatus) {
                        setRawRows({ status: true, rows: rows, errors: uniqueErrors, errorStatus: true, errorType: 'row' });
                        setFileStatus(fileStatusObj.error);
                        setChecking(false);
                        return Promise.resolve({});
                    } else {
                        // Perform async validation
                        setFileStatus(fileStatusObj.checkingAsync);
                        return asyncFieldCheck(rows, newConfig);
                    }
                })
                .then(({ asyncErrors, asyncErrorStatus, uniqueArrList, apiError}) => {
                    if (asyncErrorStatus && !apiError ) {
                        setRawRows({ status: true, rows: rows, errors: asyncErrors, errorStatus: true, errorType: 'row' });
                        setFileStatus(fileStatusObj.error);
                    } 
                    if(asyncErrorStatus && apiError) {
                        setRawRows({ status: true, rows: rows, errors: asyncErrors, errorStatus: true, errorType: 'row' });
                        setFileStatus({...fileStatusObj.error, message:'Network error' +apiError});
                    }
                    if((asyncErrors!== undefined ) && !asyncErrorStatus){
                        // If there are no errors, clean the data and update state to reflect that
                        setRawRows({ status: true, rows: rows, errors: [], errorStatus: false, errorType: null });
                        setSubmit(true);
                        setFileStatus(fileStatusObj.ready);
                        const fileData = cleanseEmptyColumns(rows, newConfig, uniqueArrList);
                        setFileData(fileData);
                    }

                    setChecking(false);
                })
                .catch(err => {

                    // Update state with the validation and unique errors, unique errors might be also empty if no unique field
                    setRawRows({ status: true, rows: rows, errors: [...err.inner, ...uniqueErrors], errorStatus: true, errorType: 'row' });
                    setFileStatus(fileStatusObj.error);
                    setChecking(false);
                });
                
            
        }}
    );
    
};

export { _handleFileChange };
