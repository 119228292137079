import React,{useEffect} from 'react';
import { MdOutlineFindInPage, MdAdminPanelSettings } from 'react-icons/md';
import PropTypes from 'prop-types';
import PageCards from '../../components/common/PageCards';
import { TbDatabaseCog } from 'react-icons/tb';



const AdminHome = ({setTitle}) =>  {

    /* set title */
    useEffect(() => {
        setTitle('TS Insights & Solutions Admin Page');
    }, []);
    
    /**
    * Creating an array of pages
    * card data with icon and url
    */
    const cards= [
        {
            icon: <MdOutlineFindInPage size={'100px'}/>,
            url: '/ViewLogs',
            title: 'View Logs',
            externalLink: false
        },
        {
            icon: <MdAdminPanelSettings size={'100px'}/>,
            url: '/DBAdmin',
            title: 'Database Admin',
            externalLink: false
        },
        {
            icon: <TbDatabaseCog size={'100px'}/>,
            url: '/JobAdmin',
            title: 'Job Admin',
            externalLink: false
        },
    ];    
    // return 
    return (
        <PageCards pages={cards} />
    );
};

AdminHome.propTypes={
    setTitle: PropTypes.any
};

  
export default AdminHome;
  