import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import React, { useState, useEffect } from 'react';
import { getRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../util/functions';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import { MdPreview } from 'react-icons/md';
const url = getBaseUrl() + '/api/datasrcs/1/folder/survey/customQuery/get_user_survey_responses?filters=';

const SurveyResponses = ({wwid}) => {
    const [responses, setResponses] = useState();
    const [loading, setLoading] = useState(true);
    const [viewResponse, setViewResponse] = useState({isOpen:false, questions:{}});
    const getSurveyResponses = async id =>{

        try {
            const res = await getRequest(url + id);
            res.data.data.sort((a, b) => b.response_instance_id - a.response_instance_id);
            setResponses(res.data.data);
        } catch (error) {
            toast.error('Error with getting survey responses:' + getDetailErrorMsg(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSurveyResponses(wwid);
    
    }, []);
    
    if(loading){
        return <LoadingAnimation/>;
    }
    const checkResponses = data=> {
        //console.log(data);
        setViewResponse({isOpen:true, questions:data});
    };
    const onClose = ()=>{
        setViewResponse({isOpen:false, questions:{}});
    };
    const colConfig = [
        {
            accessorKey: 'response_instance_id', 
            header: 'Id',
            textAlign: 'center',
            dataType: 'number',
            filterFn: 'numberFilter',
            disableFilter: true,
        },
        {
            accessorKey: 'product_name', 
            header: 'Product Name',  
            textAlign: 'left',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
        },
        {
            accessorKey:'response_date', 
            header: 'Survey Date',  
            textAlign: 'left',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
        },
        {
            header: 'Response',
            textAlign: 'center',
            disableFilter: true,
            minWidth: 70,
            cell: info => (
                <div >
                    <span  onClick={()=> checkResponses(info.row.original)} className='survey-link'> <MdPreview size={25}/>  View Response</span>
                </div>
            )
        }
    ];

    return (
        <div className='survey-container'>
            <div className='survey-top-title'>
                <p>List of completed surveys:</p>
                <span>
                Here you can view the surveys that you have completed in the past. 
                Feel free to search by product name or date. To view your responses for a survey, click the View Response button.</span>
            </div>
            <div style={{marginTop: '38px'}}>
                {!loading && <DataTable
                    {...{
                        tableData:responses,
                        columnConfig:colConfig, 
                        disableDownload:true, 
                        disableFilter:true,
                        themeColor:'#5856d6',
                    }}
                />}
            </div>
            

            {viewResponse.isOpen &&
                <span title='Take Survey'>
                    <Survey
                        baseUrl={getBaseUrl()}
                        productId={viewResponse.questions.product_id}
                        wwid={wwid}
                        productName={viewResponse.questions.product_name}
                        surveyTitle={`${viewResponse.questions.product_name} Survey`}
                        showIcon={false}
                        isOpen={true}
                        hideControls={true}
                        showResponses={viewResponse.questions.responses}
                        onClose={onClose}
                    />
                </span>
            }
            
        </div>
    );
};

SurveyResponses.propTypes  = {
    wwid:PropTypes.string.isRequired
};

export default SurveyResponses;