import { createWithEqualityFn } from 'zustand/traditional';
import { getRequest } from '../../services/axiosClient';
import { getErrorMsg, groupByProductAndWorkbookView} from '../../util/functions';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import {shallow} from 'zustand/shallow';
const urlUserViews = `${getBaseUrl()}${backendURL().dataExpSaved}`;
// Constructing dashboard data url
const dashboardLandingURL = `${getBaseUrl()}${backendURL().dashboardLandingPath}`;

// Create a store for handling state related to the dashboard.
// This store has states for the dashboard menu, links menu, and error text.
// It also has functions for setting those states and getting dashboard links.
const dashboardStore = createWithEqualityFn(set => (
    {
        // An array to hold the dashboard menu items.
        dashboardMenu: [],
        // An object to hold the links menu items.
        linksMenu:{},
        dashboardViews:[],
        // A string to hold any error messages.
        errorText:null,
        dashboardsFetched:false,
        // A bool to hold new saved view status
        newView:false,
        productGroups:{
            status:null,
            productGroups:{},
            errorText:'', 
            fetched:false
        },
        // A function for to set newView status
        setNewView: data => set({newView: data}),
        // A function for setting the dashboard menu items.
        setDashboardMenu: data => set({dashboardMenu: data}),
        // A function for setting the links menu items.
        setLinksMenu: data => set({linksMenu: data}),
        // An async function for getting the dashboard links given a userId.
        // This involves making a GET request and updating the links menu state.
        getDashboardLinks: async userId => {
            // Clear any previous error messages before starting.
            set({ errorText: null});
            try {
                // Make a GET request for the user's views.
                const resLink  = await getRequest(urlUserViews + userId);
                // Filter and map the response data to fit the needs of the application.
                const newData = resLink.data.data
                    .filter(item => item.type_id ===1)
                    .map(obj => ({
                        ...obj,
                        mylink_name: `${obj.mydata_view_name} (${obj.workbook_view_name})`,
                        link: `/dashboards/${obj.dashboard_path}?mylink=${obj.mylink_id}`
                    }));
                // Group the new data by product and workbook view, and store it in the links menu state.
                set({ linksMenu: groupByProductAndWorkbookView(newData), errorText: null });
                set({dashboardViews: resLink.data.data.filter(item => item.type_id === 1)});
                set({dashboardsFetched:true});
            } catch (error) {
                // If there's an error, generate an error message and store it in the error text state.
                const msg = getErrorMsg(error);
                set({ errorText: 'Error with getting saved user views, please refresh page or try again later' + msg });
                set({dashboardsFetched:true});
            }
        },
        getDashboardLandingData: async () =>  {
            try {
                // get response data
                const res = await getRequest(dashboardLandingURL);
                const organizedData = {};

                res.data.data.forEach(({ product_group, product, workbook, dashboard_path }) => {
                    
                    if (!organizedData[product_group]) {
                        organizedData[product_group] = {};
                    }
                    if (!organizedData[product_group][product]) {
                        organizedData[product_group][product] = {
                            productName:product,
                            productPath: dashboard_path,
                            workbooks:[]
                        };
                    }
                   
                    const workbookData = {
                        dashboard_path,
                        workbook
                    };
                    organizedData[product_group][product].workbooks.push(workbookData);
                });
                
                const dashboards = {
                    status:'OK',
                    productGroups:organizedData,
                    errorText:'', 
                    fetched:true
                };
 
                set({ productGroups:dashboards});
            } catch (err){
                // set errors
                const msg = getErrorMsg(err);

                const dashboards = {
                    status:'ERROR',
                    productGroups:{},
                    errorText:msg ||'No data found!',
                    fetched:true,
                };
                set({ productGroups:dashboards});
            }
    
        },
        expandedProducts:{}, 
        setExpandedProducts: data => set({expandedProducts:data}),
        selectedGroup : 'Operational Health', setSelectedGroup : data=> set({selectedGroup:data}),
    }
));

// A hook for accessing the dashboard store.
// This hook uses selector to return only the parts of the state that are needed.
// This way, components that use this hook won't re-render unless these specific parts of the state change.
const useDashboardStore = () => {
    const {
        getDashboardLinks, 
        linksMenu, 
        dashboardMenu, 
        setLinksMenu, 
        errorText, 
        setDashboardMenu, 
        newView, 
        setNewView, 
        dashboardViews, 
        getDashboardLandingData, 
        productGroups, 
        dashboardsFetched, expandedProducts, setExpandedProducts,
        selectedGroup, setSelectedGroup} = dashboardStore(state => ({ 

        linksMenu:state.linksMenu, 
        getDashboardLinks:state.getDashboardLinks, 
        errorText:state.errorText,
        newView:state.newView,
        setNewView:state.setNewView,
        setLinksMenu:state.setLinksMenu, 
        dashboardMenu:state.dashboardMenu,
        setDashboardMenu:state.setDashboardMenu,
        dashboardViews:state.dashboardViews,
        getDashboardLandingData:state.getDashboardLandingData,
        productGroups:state.productGroups,
        dashboardsFetched:state.dashboardsFetched,
        expandedProducts:state.expandedProducts, setExpandedProducts:state.setExpandedProducts,
        selectedGroup:state.selectedGroup, setSelectedGroup:state.setSelectedGroup
    }), shallow);

    // Return these parts of the state and the functions that operate on them.
    return { 
        getDashboardLinks, linksMenu, dashboardMenu, 
        setLinksMenu, errorText, setDashboardMenu, newView, 
        setNewView, dashboardViews, getDashboardLandingData, 
        productGroups,dashboardsFetched, expandedProducts, setExpandedProducts,
        selectedGroup, setSelectedGroup
    };
};

// Export the hook so other components can use it.
export default useDashboardStore;
