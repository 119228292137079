import { useState } from 'react';
import { deleteRequest, postRequest, putRequest } from '../../services/axiosClient';

// Custom hook for making API requests to save data
const useApiSave = () => {
    // Flag indicating whether the API request is currently loading
    const [isLoading, setIsLoading] = useState(false); 
    // Error message if the API request fails
    const [error, setError] = useState(null); 
    // Flag indicating whether the API request was successful
    const [success, setSuccess] = useState(false); 

    // Makes a request to the given URL using the given method and data
    const handleRequest = async (url,method, data ) => {
        setIsLoading(true);
        setError(null);
        setSuccess(false);
        let returnId = null;

        try {
           
            if(method === 'POST') {
                // Make a POST request and get the ID of the newly created item
                const res = await postRequest(url, data); 
                returnId = res.data.data[0].id;  
            }
           
            if(method === 'DELETE') {
                // Make a DELETE request
                await deleteRequest(url); 
                returnId = true;
            }
            if(method === 'PUT') {
                // Make a DELETE request
                await putRequest(url, data);
                returnId = true;
            }
            setIsLoading(false);
            setSuccess(true);
        } catch (err) {
            setIsLoading(false);
            setError(err.message);
        }
        
        // Return the ID of the created or deleted item
        return returnId; 
    };

    // Return an object with the state values and the handleRequest function
    return { isLoading, error, success, handleRequest };
};




export {useApiSave};