import React, { useEffect, useState } from 'react';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { getRequest } from '../../services/axiosClient';
import InfoMessage from '../../components/common/InfoMessage';
import { FaPlayCircle } from 'react-icons/fa';
import { MdOutlineRefresh } from 'react-icons/md';
import './DBAdmin.css';
import { getErrorMsg } from '../../util/functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';

const JobAdmin = () => {
    const jobAdminList = `${getBaseUrl()}${backendURL().jobAdminURL}`;
    const [sqlJobData, setSqlJobData] = useState({});
    const [jobListData, setJobListData] = useState({});
    const [apiStatus, setApiStatus] = useState({ status: false, timer: false, isError: false, message: '', loading: false });
    const jobRunURL = `${getBaseUrl()}${backendURL().jobAdminRunURL}`;
    const scriptRunURL = `${getBaseUrl()}${backendURL().jobScriptRunURL}`;
    /**
    * Fetches data mart tables
    * Sets the state of fetched data to 
    * be displayed on the UI
    * If there is an error, sets the state of 
    * fetched data to an empty array and logs the error
    */
    const fetchJobAdminList = async () => {
        try {
            const res = await getRequest(`${jobAdminList}/job_admin_list`);
            if (res) {
                setSqlJobData(res?.data);
            }
        }
        catch (error) {
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting data, please refresh page or try again later' + msg, isError: true });
        }
    };
    /**
    * Fetches data mart tables
    * Sets the state of fetched data to 
    * be displayed on the UI
    * If there is an error, sets the state of 
    * fetched data to an empty array and logs the error
    */
    const fetchJavascriptJobAdminList = async () => {
        try {
            const res = await getRequest(`${jobAdminList}/jobscript_admin_list`);
            if (res) {
                setJobListData(res?.data);
            }
        }
        catch (error) {
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting data, please refresh page or try again later' + msg, isError: true });
        }
    };
    useEffect(() => {
        fetchJobAdminList();
        fetchJavascriptJobAdminList();
    }, []);
    // These are the columns for the 
    // available data marts table

    const getColumns = (tableName) => {
        const columns = [
            {
                accessorKey: 'column_name',
                getCanFilter: false,
                header: 'Actions',
                minWidth: 80,
                hideFilter: true,
                textAlign: 'center',
                cell: info => {
                    return (
                        (info.row.original.status === 'Success' || info.row.original.status === 'Error') && <span className='btn-icon' data-testid={tableName === 'sqlTable' ? 'fa-play' : 'fa-play-script'} onClick={() => tableName === 'sqlTable' ? handlePlayClick(info.row.original.job_id) : handlePlayClickScript(info.row.original.job_name)}><FaPlayCircle size='18px' /></span>
                    );
                },
            },
            {
                accessorKey: 'job_id',
                getCanFilter: false,
                header: 'Job ID',
                minWidth: 80,
                hideFilter: false,
                textAlign: 'center'
            },
            {
                accessorKey: 'job_name',
                getCanFilter: false,
                header: 'Job Name',
                minWidth: 130,
                hideFilter: false,
                textAlign: 'left'
            },
            {
                accessorKey: 'start_timestamp',
                getCanFilter: false,
                header: 'Last Run Start',
                minWidth: 130,
                hideFilter: false,
                textAlign: 'center',
            },
            {
                accessorKey: 'end_timestamp',
                getCanFilter: false,
                header: 'Last Run Complete',
                minWidth: 130,
                hideFilter: false,
                textAlign: 'left'
            },
            {
                accessorKey: 'duration_seconds',
                getCanFilter: false,
                header: 'Duration',
                minWidth: 130,
                hideFilter: false,
                textAlign: 'center',
                cell: info => (
                    new Date(info.row.original.duration_seconds * 1000).toISOString().substring(11).replace('Z', '')
                ),
            },
            {
                accessorKey: 'status',
                getCanFilter: false,
                header: 'Status',
                minWidth: 130,
                hideFilter: false,
                textAlign: 'left'
            },
        ];
        return columns;
    };


    const refreshJobHandler = () => {
        fetchJobAdminList();
    };

    const handlePlayClick = async (jobId) => {
        await getRequest(`${jobRunURL}/${jobId}`)
            .then((res) => {
                toast.success(res.data.message);
            }).catch((error) => {
                // catch any unexpected errors
                toast.error(`Error while fetching data ${error}`);
            });

    };
    const handlePlayClickScript = async (data) => {
        if (data && data.indexOf('.') > -1) {
            const splittedData = data.split('.');
            await getRequest(`${scriptRunURL}/${splittedData[0]}`)
                .then((res) => {
                    toast.success(res.data.message);
                }).catch((error) => {
                    // catch any unexpected errors
                    toast.error(`Error while fetching data ${error}`);
                });
        }
    };


    return <div className='job-admin-container'>
        <section>
            <InfoMessage message={apiStatus.message} isError={apiStatus.isError} onClose={() => setApiStatus({ status: false, message: '', isError: false })} status={apiStatus.status} timer={false} showClose={true} />
        </section>
        {sqlJobData?.data && <div>
            <button className='ts-btn green' onClick={refreshJobHandler}><MdOutlineRefresh size='18px' /><span>Refresh</span></button>
            <h2>SQL Job Admin</h2>
            <DataTable
                {...{
                    
                    tableData: sqlJobData.data,
                    columnConfig:getColumns('sqlTable'), 
                    disableDownload: true, 
                    defaultFileName:'sqlTable',
                    showFooter: false,
                }}
            />
        </div>}

        {/* JOb Admin */}
        {jobListData?.data && <div>
            <h2>JavaScript Job Admin</h2>
            <DataTable
                {...{
                    tableData: jobListData.data,
                    columnConfig:getColumns('script'), 
                    disableDownload: true, 
                    defaultFileName: 'script', 
                    showFooter: false,
                }}
            />
        </div>}
        <ToastContainer theme='dark' position='top-center' />
    </div>;
};
export default JobAdmin;