import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import  { InputDatePicker } from '../../components/common/datePicker/CustomDatePicker';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import {useApiSave } from './useHooks';
import {getBaseUrl} from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';


const urlFilter =`${getBaseUrl()}${backendURL().filterDataUrl}`;
export const getFormattedDate = value=>{
    let date = new Date(value);
    date.setHours(0, 0, 0,);
    // this is for '2022-02-02' length to slice from date string
    const maxStringLength = 10;
    return date.toISOString().substring(0, maxStringLength);
};
const getSelectValue = value => value === '' ? null : parseISO(value);
const MyLinkParams = ({startEndDate, setStartEndDate, handleApiMessage, formInfo}) => {

    const { isLoading, error, success, handleRequest } = useApiSave();
    // show saved popup information when api status change
    useEffect(() => {
        if(success){
            handleApiMessage({status:success, timer:1000, isError:false, message:'Changes saved!'});
        }
        if(error){
            handleApiMessage({status:true, timer:5000, isError:true, message:error});
        }
    },[success, error]);

    const handleChange = async (e, name) => {
        // Prevent the default behavior of the event
        const date = getFormattedDate(e);
        // Update the state using setStartEndDate

        const { start_date, end_date } = startEndDate;
        const updatedValue = {
            value: date,
            columnId: startEndDate[name].columnId,
            columnName: startEndDate[name].columnName,
        };
        const newData = { ...startEndDate, [name]: updatedValue };
        const isValidDateRange = (start, end) => !(start && end && new Date(start) > new Date(end));
        // checking dates 
        if (
            (name === 'start_date' && isValidDateRange(updatedValue.value, end_date.value)) ||
                (name === 'end_date' && isValidDateRange(start_date.value, updatedValue.value))
        ) {
            //setting date if state true
            setStartEndDate(newData);
            // if edit save date
            if(formInfo.action === 'edit'){
                const obj = {[name]:date};
                await handleRequest(urlFilter + `/${startEndDate.id}`, 'PUT', obj);
            } 
            
        } else {
            // give alert when date range is not valid
            alert('Invalid date range. Please ensure that the start date is before the end date.');
        }
    };
      
    if(!startEndDate.status){
        return null;
    }
    
    return (
            
        <div className='myLinkDataForm-date-wrap'> 
            <span>  
                <p>{startEndDate.start_date.columnName}</p>
                <div style={{ margin:'auto'}}>
                    <DatePicker
                    // sets the selected date
                        selected={getSelectValue(startEndDate.start_date?.value)} 
                        // change handler for the date picker
                        onChange={date => handleChange(date,'start_date')} 
                        // custom input component
                        // this is only needed for to add test id
                        customInput={<InputDatePicker testId={'link-start-date'} value={startEndDate.start_date?.value} isDisabled={isLoading}/>} 
                        placeholderText='mm/dd/yyyy'
                        showMonthDropdown 
                        showYearDropdown 
                        useShortMonthInDropdown
                        yearDropdownItemNumber={8}
                        shouldCloseOnSelect={true}
                        disabled={isLoading}
                    />
                </div>
            </span>
            <span> 
                <p>{startEndDate.end_date.columnName}</p>
                <div style={{ margin:'auto'}}>
                    <DatePicker
                    // sets the selected date
                        selected={getSelectValue(startEndDate.end_date?.value)} 
                        // change handler for the date picker
                        onChange={date => handleChange(date,'end_date')} 
                        // custom input component
                        // this is only needed for to add test id
                        customInput={<InputDatePicker testId={'link-end-date'} value={startEndDate.end_date?.value} isDisabled={isLoading}/>} 
                        placeholderText='mm/dd/yyyy'
                        showMonthDropdown 
                        showYearDropdown 
                        useShortMonthInDropdown
                        yearDropdownItemNumber={8}
                        shouldCloseOnSelect={true}
                        disabled={isLoading}
                    />
                </div>
                
            </span>
        </div> 
    );
};

MyLinkParams.propTypes = {
    startEndDate: PropTypes.object,
    setStartEndDate: PropTypes.func,
    handleApiMessage: PropTypes.func,
    formInfo: PropTypes.object,
};

export default MyLinkParams;