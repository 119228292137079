import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import { getRequest, postRequest } from '../../../services/axiosClient';
import { checkSixtyDayDifference, formatDateTime, getDetailErrorMsg } from '../../../util/functions';
import { getBaseUrl } from '../../../util/getBaseUrl';
import { toast } from 'react-toastify';
import useMainStore from '../../../store/useMainStore';


const urlPost = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/user_prompt/records';
const urlquery = getBaseUrl() + '/api/datasrcs/1/folder/survey/customQuery/get_last_prompt_response?filters=';

const SurveyPrompt = ({selectedDashboard, userId}) => {
    const [isOpen, setIsOpen]= useState(false);
    const {surveyCheck, setSurveyCheck} = useMainStore();

    const checkLastSurvey = async ()=>{
        try {
            const res  = await getRequest(urlquery + userId);
            if(res.data.rowCount > 0){
                const lastDate = res.data.data[0].last_prompt_date;
                const isTimeForSurvey = checkSixtyDayDifference(lastDate);

                if(isTimeForSurvey){
                    const date = formatDateTime(new Date(), true);
                    await postRequest(urlPost, { wwid:userId, prompt_date: date });
                    setIsOpen(true);
                }
                // we are setting global survey check to true so it wont keep checking survey prompt every time dashboard change
                setSurveyCheck(true);
            }
        } catch (error) {
            toast.error('Error with chekcing last survey date:' + getDetailErrorMsg(error));
        }
    };
    
    useEffect(()=>{
        if(!surveyCheck){
            checkLastSurvey();
        }
    },[surveyCheck]);

    if(!isOpen){
        return null;
    }

    return (
        <div>
            {isOpen && <Survey
                baseUrl={getBaseUrl()}
                productId={selectedDashboard.survey_product_id}
                wwid={userId}
                showIcon={false}
                isOpen={true}
                hideControls={true}
            />}
        </div>
    );
};

SurveyPrompt.propTypes = {
    selectedDashboard: PropTypes.object.isRequired,
    userId:PropTypes.string.isRequired
};
export default SurveyPrompt;