import React, {useEffect, useState} from 'react';
import PropTypes  from 'prop-types';
import ProgressBar from '../common/ProgressBar';

const ProgressBarHandler = ({ count }) => {
    const [progress, setProgress] = useState(0);

    // This is estimated record can be added in one second
    const estimatedRecordNum = 25;

    // Total seconds needed to upload all data in database
    const totalSecondsNeeded = count / estimatedRecordNum;

    const onePercent = 1;
    const tenPercent = 10;
    const eightyPercent = 80;
    const ninetyEightPerc = 98;
    const maxRecNum = 120;
    const hundredPerc = 100;
    const oneSecToMilliSec =1000;

    // If record count is less that 120 it will be very fast loading so we increase by 10% else by 1%
    // We also set percent increase based on this by 10% or 1%
    const percentIncreaseCalc = count < maxRecNum ? tenPercent : hundredPerc;
    const percentIncrease = percentIncreaseCalc === tenPercent ? tenPercent : onePercent;

    // progress will always based on percent increase if it is 10% we cancel interval at 80 and it will stop at 90
    // same for if increase is 1% we cancel at 98% and it will stop at 99%
    const interValStopNumber = percentIncrease === tenPercent ? eightyPercent : ninetyEightPerc;

    // Getting interval so we can increase progress every that interval, *1000 to milsecond
    const interval = (totalSecondsNeeded/percentIncreaseCalc)*oneSecToMilliSec;

    useEffect(() => {
        const intervalProgress = setInterval(() => {
            setProgress(prevState => {
                if(prevState >= interValStopNumber ){
                    clearInterval(intervalProgress);
                }
                return prevState + percentIncrease;
            });
        }, interval);
        
        return () => {
            clearInterval(intervalProgress);
        };
    }, [interval, interValStopNumber, percentIncrease]);  

    return (
        <ProgressBar progress={progress}/>
    );
};

ProgressBarHandler.propTypes = {
    count: PropTypes.number.isRequired, 
};

export default ProgressBarHandler;