/* eslint-disable no-prototype-builtins */
import { getRequest, postRequest, deleteRequest } from '../../../services/axiosClient';
import { backendURL } from '../../../config/constants';
import { getBaseUrl } from '../../../util/getBaseUrl';

function findItemsToAddAndDelete(currentSegmentIds, desiredSegmentIds, allData, idName, rowId, colName) {
    const itemsToAdd = [];
    const itemsToDelete = [];
  
    // Find items to add (present in desiredSegmentIds but not in currentSegmentIds)
    for (const segmentId of desiredSegmentIds) {
        if (!currentSegmentIds.includes(segmentId)) {
            itemsToAdd.push({[idName]: rowId, [colName]: segmentId});
        }
    }
  
    // Find items to delete (present in currentSegmentIds but not in desiredSegmentIds)
    for (const segmentId of currentSegmentIds) {
        if (!desiredSegmentIds.includes(segmentId)) {
            const result_id = allData.find(item => item[colName] === segmentId)?.id;
            itemsToDelete.push(result_id);
        }
    }

  
    return { itemsToAdd, itemsToDelete };
}

const AddItemRequests = async (url, object) => {
    for(let i in object){
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(i)){
            await postRequest(url, object[i]);
        }
    }

};

const DeleteItemRequests = async (url, object) => {
    for(let i in object){
        if (object.hasOwnProperty(i)){
            await deleteRequest(`${url}/${object[i]}`);
        }
    }
};
const RelationshipDataHandler = async (data, id, type) => {

    //multi_select_lkup_column_link will be the id for the table
    //multiselectcolumn would replace ID.
    //{multi_select_lkup_column_link: id of row from grid, multiselectcolumn: id from actual item   }


    
    if(type === 'add'){
        //loop through and post every record.
        //loop through all multiSelect fields.
        for(let i in data){
            if(data.hasOwnProperty(i)){
            //define url for requests
                const detailDataUrl = `${getBaseUrl()}${backendURL().detailsTableData}`;
                let conf = data[i]['conf'];
                let colName = conf.multi_select_column;
                let idName = conf.multi_select_lkup_column_link;
                let baseurlreq = `${detailDataUrl}${conf.multi_select_schema}/tables/${conf.multi_select_table}/records`;
                let myData = data[i]['data'];

                const myDataIds = myData.map(obj => obj.id);

                //compare and make final object of deletes and posts.
                const {itemsToAdd} = findItemsToAddAndDelete([], myDataIds, [], idName, id, colName);
                await AddItemRequests(baseurlreq, itemsToAdd);
            }
        }
    }

    if(type === 'edit'){
        //loop through all multiSelect fields.
        for(let i in data){
            if(data.hasOwnProperty(i)){
            //get existing data
                const detailDataUrl = `${getBaseUrl()}${backendURL().detailsTableData}`;
            
                let conf = data[i]['conf'];
                let colName = conf.multi_select_column;
                let idName = conf.multi_select_lkup_column_link;
                let urlreq = `${detailDataUrl}${conf.multi_select_schema}/tables/${conf.multi_select_table}/records?data_platform_id=${id}`;
                let baseurlreq = `${detailDataUrl}${conf.multi_select_schema}/tables/${conf.multi_select_table}/records`;

                let allData = await getRequest(urlreq);
                allData = allData.data.data;

                let myData = data[i]['data'];

                const allDataIds = allData.map(obj => obj[colName]);
                const myDataIds = myData.map(obj => obj.id);

                //compare and make final object of deletes and posts.
                const {itemsToAdd, itemsToDelete} = findItemsToAddAndDelete(allDataIds, myDataIds, allData, idName, id, colName);

                await AddItemRequests(baseurlreq, itemsToAdd);
                await DeleteItemRequests(baseurlreq, itemsToDelete);
            }
        }

        //compare and create new object to replace

        //put new data or delete as needed
    }

    return null;
};

export default RelationshipDataHandler;
