import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaFileCsv} from 'react-icons/fa';

const DragAndDropUpload = ({handleFileChange}) => {
    // highlight state
    const [highlight, setHighlight] = useState(false);
    // ref for file input
    const fileInputRef = useRef();
    // open dialog
    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    // files added event handler
    const onFilesAdded = event => {
        event.stopPropagation();
        const files = event.target.files;
        handleFileChange(files);
    };
    // drag over event handler, we set
    // highlight on when file is
    // over the box
    const onDragOver = event => {
        event.preventDefault();
        setHighlight(true);
    };
    // set highlight off
    const onDragLeave = () => {
        setHighlight(false);
    };
    // when file dropped call file 
    // handling function
    const onDrop = event => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileChange(files);
        setHighlight(false);
    };
    
  
    
    return (
        <div className={`Upload ${highlight ? 'Highlight' : ''}`} 
            onClick={openFileDialog} 
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{ cursor: 'pointer' }}>
            <input
                ref={fileInputRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={onFilesAdded}
                accept=".csv"
                aria-label="upload"
                data-testid="upload-file-input"
            />
            <div className={`drag-drop-text ${highlight ? 'drag-highlight' : ''}`}>
                <FaFileCsv fontSize={40}/>
                <div>Select a CSV file to upload</div>
                <span>or drag and drop it here</span>
            </div>
            
        </div>
    );
};
DragAndDropUpload.propTypes = {
    handleFileChange: PropTypes.func.isRequired,
};

export default DragAndDropUpload;