import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { SelectInput, AsyncSelectInput } from './InputComponents';
import { SingleKeyReplacement, } from '../../../pages/data_maintenance/Datagrid/RowEditingCommands';
import { backendURL } from '../../../config/constants';
import { useForm } from 'react-hook-form';
import { getBaseUrl } from '../../../util/getBaseUrl';
import LinearIndeterminate from '../loadingAnimation/LinearIndeterminate';
import { getRequest } from '../../../services/axiosClient';
import { useSearchParams } from 'react-router-dom';
import { addurlParamValues, addDefaultValues, } from './formHelperFunctions';
import { InputMask } from '@react-input/mask';


const fifty = 50;
const detailDataUrl = `${getBaseUrl()}${backendURL().detailsTableData}`;
//This creates a form on the page
//check null
const isForeignInfoNull = data => {
    return (
        data.foreign_table_name == null &&
        data.foreign_column_name == null);
};

//Checks if the field should be displayed on the form, meaning it is
//not a primary key and not an auto created by flag field
const isFieldOnForm = data => (!data.is_primary_key && !data.auto_created_by_flag);

//Checks if the field is a PK that is not Auto Increment
const isNonAutoIncPk = data => (data.is_primary_key === 1 && data.auto_increment === '0');

const isRmrResourceWwid = data => {
    return (
        data.foreign_schema_name == 'rmr' &&
        data.foreign_table_name == 'rmr_resource' &&
        data.foreign_column_name == 'wwid'
    );
};

const GetDataTypeInfo = data => {
    let dataType = '';
    let dataColInfo = '';
    //if the field is any type of numeric, 
    //set dataType as such and the form will generate
    // a field that only allows numbers to be entered
    if (data.data_type === 'bigint' || data.data_type === 'integer') {
        dataType = 'number';
    }
    //regex for numeric values
    //in test, reffered to as numbers
    else if (data.data_type === 'numeric') {
        dataType = 'number';
    }

    // if the field is a date, set dataType as such 
    // and the form will generate a date picker
    else if (data.data_type === 'date') {
        dataType = 'date';
    }
    // if the field is a timestamp, set dataType as such and 
    // set dataColInfo that will show the format on the form
    else if (data.data_type === 'timestamp without time zone') {
        dataType = 'datetime';
        dataColInfo = ' (YYYY-MM-DD HH:MM:SS)';
    }
    else if (data.data_type === 'time without time zone') {
        dataType = 'time';
        dataColInfo = ' (HH:MM:SS)';
    }
    else if (data?.character_maximum_length > fifty && data.data_type === 'text') {
        dataType = 'textarea';
    }
    // else the field is a text field, 
    // generate on the form
    else { dataType = 'text'; }
    return { dataType: dataType, dataColInfo: dataColInfo };
};

// FieldLabel component is used to display the field label with or without the required indicator
const FieldLabel = ({ isRequired, displayText, data }) => (
    <label htmlFor={data?.column_name || null}>
        {isRequired ?
            <span style={{ fontWeight: 'bold' }}>{displayText}* </span>
            : displayText
        }:
    </label>
);

FieldLabel.propTypes = {
    isRequired: PropTypes.any,
    displayText: PropTypes.string,
    data: PropTypes.object,
};


const TextInput = ({ data, control, register, formData, dataType, formErrors }) => {

    // select component type based on dataType
    const InputComponent = dataType === 'textarea' ? 'textarea' : 'input';
    return (
        <>
            {dataType === 'datetime' && <InputMask
                {...register(data.column_name, {
                    required: data.is_required,
                    maxLength: data?.character_maximum_length,
                    pattern: {
                        value: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31)) (0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}/,
                        message: 'Invalid input of ' + data.column_name
                    }
                })}
                defaultValue={formData?.[data.column_name]}
                placeholder={'Enter ' + data.column_name}

                mask="YYYY-MM-DD HH:MM:SS" replacement={{ Y: /\d/, M: /\d/, H: /\d/, S: /\d/, D: /\d/ }} showMask separate />
            }

            {dataType === 'time' && <InputMask
                {...register(data.column_name, {    
                    required: data.is_required,
                    maxLength: data?.character_maximum_length,
                    pattern: {
                        value: /(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}/,
                        message: 'Invalid input of ' + data.column_name
                    }
                })}
                defaultValue={formData?.[data.column_name]}
                placeholder={'Enter ' + data.column_name}

                mask="HH:MM:SS" replacement={{ H: /\d/, S: /\d/, M: /\d/ }} showMask separate />
            }

            {(dataType !== 'datetime' && dataType !== 'time') && <InputComponent type={dataType} name={data.column_name}
                {...register(data.column_name, {
                    required: data.is_required,
                    maxLength: data?.character_maximum_length,                   
                })}
                required={!!data.is_required}
                readOnly={!!data.is_primary_key}
                control={control}
                className='form-input'
                placeholder={'Enter ' + data.column_name}
                defaultValue={formData?.[data.column_name]}
                maxLength={data?.character_maximum_length}
                step=".01"
                rows="4"
                cols="50">
            </InputComponent>
            }
            {formErrors?.[data.column_name] && <p style={{ color: 'red' }}>{formErrors?.[data.column_name]?.message}</p>}
        </>
    );
};

TextInput.propTypes = {
    data: PropTypes.object,
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    dataType: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    formType: PropTypes.bool,
    formErrors: PropTypes.object
};

// Checkbox component for rendering checkboxes
const Checkbox = ({ data, register, control, formData }) => {
    const [dataVal, setDataVal] = useState(formData?.[data.column_name]);
    return (
        <input
            name={data.column_name}
            {...register(data.column_name, { required: data.is_required, maxLength: data?.character_maximum_length })}
            type="checkbox"
            checked={(dataVal === 'true' || dataVal === true) ? true : false}
            id={`checkbox for ${data.column_name}`}
            onChange={e => { setDataVal(e.target.checked); }}
            required={!!data.is_required}
            // eslint-disable-next-line react/no-unknown-property
            control={control}
        ></input>
    );
};

Checkbox.propTypes = {
    data: PropTypes.object,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    formType: PropTypes.bool,
    defaultValue: PropTypes.any
};

// setFormFields function is used to create the form fields based on the provided data
const setFormFields = (data, formData, k, register, control, formErrors) => {
    let dataType = '';
    let dataColInfo = '';

    // if the data type is boolean, create a checkbox
    if (data.data_type === 'boolean') {
        return (
            <td key={k}>
                <FieldLabel isRequired={data.is_required} displayText={data.column_name_display} data={data} />
                <br></br>
                <Checkbox data={data} control={control} register={register} formData={formData} />
            </td>
        );
    }

    // if the data type is character varying, create a textarea or text input based on the character maximum length
    if (data.data_type === 'character varying') {
        dataType = data?.character_maximum_length > fifty ? 'textarea' : 'text';
        return (
            <td key={k}>
                <FieldLabel isRequired={data.is_required} displayText={data.column_name_display + dataColInfo} />
                <br></br>
                <TextInput data={data} control={control} register={register} formData={formData} dataType={dataType} formErrors={formErrors} />
            </td>
        );
    }

    // Get data type information
    let DataTypeConf = GetDataTypeInfo(data);
    dataColInfo = DataTypeConf.dataColInfo;
    dataType = DataTypeConf.dataType;

    // create the form fields that are of type numeric, int, bigint, date, datetime
    return (
        <td key={k}>
            <FieldLabel isRequired={data.is_required} displayText={data.column_name_display + dataColInfo} />
            <br></br>
            <TextInput data={data} control={control} register={register} formData={formData} dataType={dataType} formErrors={formErrors} />
        </td>
    );
};
// Create Label component
// Label component and PropTypes
const Label = ({ columnName, columnNameDisplay, isRequired }) => (
    <label htmlFor={columnName}>
        {isRequired
            ? <span style={{ fontWeight: 'bold' }}>{columnNameDisplay}* </span>
            : columnNameDisplay
        }:
    </label>
);

Label.propTypes = {
    columnName: PropTypes.string.isRequired,
    columnNameDisplay: PropTypes.string.isRequired,
    isRequired: PropTypes.any,
};

// GenericInput component and PropTypes
const GenericInput = ({ data, name, registerOptions, required, control, className, placeholder, value, maxLength, onChange }) => {
    const dataType = data?.data_type === 'character varying' && data?.character_maximum_length > fifty ? 'textarea' : 'text';
    const ComponentType = dataType === 'textarea' ? 'textarea' : 'input';
    return (
        <ComponentType
            name={name}
            {...registerOptions}
            required={required}
            type={data?.data_type}
            control={control}
            className={className}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            onChange={onChange}
        />
    );
};

GenericInput.propTypes = {
    data: PropTypes.object,
    name: PropTypes.string.isRequired,
    registerOptions: PropTypes.object.isRequired,
    required: PropTypes.bool.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};


const FastForm = props => {
    const {
        formData,
        fsData,
        Config,
        index,
        register,
        control,
        formErrors
    } = props;
    //process each column
    let data = Config[fsData];
    let actions = [];
    let defaultSelect = {};

    let dv = data.display_values;
    // loop through the display values for the field if they exist
    // store display values in defaultSelect
    for (let i in dv) {
        if (i !== null) {
            actions.push({ label: dv[i], value: i });
            defaultSelect[i] = { label: dv[i], value: i };
        }
    }
    let defaultSelectIndex = formData?.[data.column_name]?.[0];



    //if the field is not a primary key
    if (isFieldOnForm(data)) {
        let urlreq;
        //if the field is not a foreign key (select field)
        if (data.data_type === 'multi-select') {
            urlreq = `${detailDataUrl}${data.multi_select_lkup_schema}/tables/${data.multi_select_lkup_table}/records?fields-all=${data.multi_select_lkup_column},${data.multi_select_lkup_column_dv}&record-limit=10&search-fields=${data.multi_select_lkup_column},${data.multi_select_lkup_column_dv}&search-term=`;
            return (
                <AsyncSelectInput data={data} defaultSelectIndex={defaultSelectIndex} control={control} index={index} urlreq={urlreq} formData={formData} isMulti={true} />
            );
        }
        else if (isForeignInfoNull(data)) {
            return setFormFields(data, formData, index, register, control, formErrors);
        }
        else if (data.async_lookup_flag) {
            if (isRmrResourceWwid(data)) {
                urlreq = `${detailDataUrl}rmr/tables/rmr_resource/records?fields-all=display_name,username,wwid&record-limit=10&search-fields=display_name,username,wwid&search-term=`;
            } else {
                urlreq = `${detailDataUrl}${data.foreign_schema_name}/tables/${data.foreign_table_name}/records?fields-all=${data.foreign_column_name},${data.ref_display_value}&record-limit=10&search-fields=${data.foreign_column_name},${data.ref_display_value}&search-term=`;
            }
            //using async select here for lookups
            return (
                <AsyncSelectInput data={data} defaultSelectIndex={defaultSelectIndex} control={control} index={index} urlreq={urlreq} formData={formData} />
            );
        }
        //else this is a select list field with reference values
        else {
            return (
                <SelectInput data={data} control={control} actions={actions} defaultValue={defaultSelect[defaultSelectIndex]} index={index} />
            );
        }
    }
    else if (isNonAutoIncPk(data)) {
        const [dataVal, setDataVal] = useState(formData?.[data.column_name]);

        return (
            <td key={index}>
                <Label columnName={data.column_name} columnNameDisplay={data.column_name_display} isRequired={data.is_required} />
                <br></br>
                <GenericInput
                    data={data}
                    name={data.column_name}
                    registerOptions={register(data.column_name, { required: !!data.is_required, maxLength: data?.character_maximum_length })}
                    required={!!data.is_required}
                    control={control}
                    className='form-input'
                    placeholder={'Enter ' + data.column_name}
                    value={dataVal}
                    maxLength={data?.character_maximum_length}
                    onChange={event => setDataVal(event.currentTarget.value)}
                />
            </td>
        );
    } else {
        // here for sonar code
        return null;
    }
};



const getRowData = async (setIsLoading, setFormData, formInfo, params, Config) => {
    setIsLoading(true);
    let res;
    const operator = formInfo.url?.indexOf('?') > 0 ? '&' : '?';
    res = await getRequest(formInfo.url + `${operator}${formInfo.uniqueId}=${formInfo.rowId}`);

    let rowdata = res.data.data ? res.data.data[0] : null;
    let rowColumns = res.data.columns;
    //uses the first item in the form to create an empty record.
    if (formInfo.formType == 'add') {

        rowdata = await addDefaultValues(Config, rowColumns);
        const result = addurlParamValues(rowdata, params, rowColumns, Config);

        setFormData({ recieved: true, data: result });
    } else {
        //create copy of object to be sent to datagrid
        let FinalFormData = SingleKeyReplacement(rowdata);
        FinalFormData = addurlParamValues(FinalFormData, params, rowColumns, Config);
        setFormData({ recieved: true, data: FinalFormData });

    }
    setIsLoading(false);
};

export const Form = props => {

    const {
        url,
        Columns,
        Config,
        handleSubmitter,
        handleCancelClick,
        rowId,
        formType,
        uniqueId,
    } = props;

    const [formData, setFormData] = useState({ recieved: false, data: '' });
    const [isLoading, setIsLoading] = useState(false);
    const formTypeAdd = formType == 'add' ? true : false;

    const formDiv = useRef();

    const [searchParams] = useSearchParams();
    let params = [];
    for (let entry of searchParams.entries()) {
        params.push(entry);
    }

    let formInfo = {
        formType,
        url,
        uniqueId,
        rowId
    };

    useEffect(() => {
        getRowData(setIsLoading, setFormData, formInfo, params, Config);
        //clears params after data is pulled to prevent duping in other forms
        params = [];
        //USED to fix onblur call issue, not a permanent solution.
        if (formDiv.current) {
            formDiv.current.click();
        }
        //cleans state when unmounting
        return () => {
            setFormData();
        };
    }, []);

    /**
     * It takes an input text, makes a call to a REST API, and returns a list of objects with a label
     * and value property.
     */


    const { register, handleSubmit, control, formState: { errors } } = useForm({mode:'all'});
    const hasErrors = Object.keys(errors)?.length > 0 ? true : false;
    const onSubmit = async data => {
        setIsLoading(true);
        const didSubmissionFinish = await handleSubmitter(data);
        setIsLoading(didSubmissionFinish);
    };
    return (
        <div ref={formDiv}>
            {isLoading ? <LinearIndeterminate /> :
                formData.recieved && <form onSubmit={handleSubmit(onSubmit)} className='datagrid-form'>
                    {Columns.map((fsData, k) => (
                        <FastForm key={fsData} index={k} fsData={fsData} formData={formData.data} Config={Config} register={register} control={control} formTypeAdd={formTypeAdd} formErrors={errors} />
                    ))}
                    <div className='upload-form-btns' style={{ marginTop: '10px' }}>
                        <button onClick={handleCancelClick} className='btn btn-outlined'>Cancel</button>
                        <button disabled={(isLoading || hasErrors)} type="submit" className='btn'>Save</button>
                    </div>
                </form>}
        </div>
    );
};

//define properties and types
Form.propTypes = {
    Columns: PropTypes.array,
    Config: PropTypes.object,
    url: PropTypes.any,
    rowId: PropTypes.any,
    formType: PropTypes.any,
    uniqueId: PropTypes.any,
    handleCancelClick: PropTypes.func,
    handleSubmitter: PropTypes.func
};


FastForm.propTypes = {
    fsData: PropTypes.any,
    Config: PropTypes.object,
    formData: PropTypes.any,
    index: PropTypes.any,
    register: PropTypes.any,
    control: PropTypes.any,
    formTypeAdd: PropTypes.bool,
    formErrors: PropTypes.object
};
