import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { backendURL, httpStatuses } from '../../../config/constants';
import { getBaseUrl } from '../../../util/getBaseUrl';
import Loading from '../../../components/common/loading';
import Error from '../../../components/common/error';
import './dashboardDetails.css';
import PropTypes from 'prop-types';
import CustomPopup from '../../../components/common/customPopup/CustomPopup';
import MyLinkDataForm from '../../explore/MyLinkDataForm';
import MsrRequest from '../../explore/MsrRequest';
import { getRequest } from '../../../services/axiosClient';
import useDashboardStore from '../../../store/dashboard/useDashboardStore';
import DashboardTopMenu from './DashboardTopMenu';
import SurveyPrompt from './SurveyPrompt';

const dashboardDetailsURL = `${getBaseUrl()}${backendURL().dashboardDetailsPath}`;


// Dashboard details page - loading the required Tableau dashboad
// eslint-disable-next-line react/prop-types
const DashboardContent = ({ setTitle, userId, setRespData, setSideMenuData, refObj, isLoaded, setIsLoaded}) => {
    const navigate = useNavigate();
    const { productId, workbookId, workbookViewId } = useParams();
    const [isError, setIsError] = useState(false);
    
    const { dashboardViews } = useDashboardStore();
    
    const [status, setStatus] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [topMenuData, setTopMenuData] = useState([]);
    const [documentLinkData, setDocumentLinkData] = useState('');
    const [selectedView, setSelectedView] = useState({});
    const [popupView, setPopupView] = useState({ status: false });
    const [downloadView, setDownloadView] = useState({ status: false });
    const [msrView, setMsrView] = useState({ status: false });    
    const [showMsrButton, setShowMsrButton] = useState(false);
    
    //Getting all three params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mylink = queryParams.get('mylink');
    
    // constructing the query path
    const queryParam = `?filters=${productId}&filters=${workbookId}&filters=${workbookViewId}`;
    

    // Used for error handling when the api returns errors
    const errorInCall = async err => {
        setStatus((err.getStatus && await err.getStatus()) || httpStatuses.SERVER_ERROR);

        // Assigning default error if nothing from response
        setErrorMessage((err.getErrorMessage && await err.getErrorMessage()) || 'No data found!');
        setIsError(true);
    };

    // API call to get menu data
    const getMenuData = async () => {
        try {
            const dashboardDetails = await getRequest(dashboardDetailsURL + queryParam);

            setSelectedView({ 
                id: workbookViewId, 
                formName: dashboardDetails.data.data[0]?.workbook_view_name, 
                productId: productId, 
                survey_product_id:dashboardDetails.data.data[0]?.survey_product_id, 
                survey_product_name:dashboardDetails.data.data[0]?.survey_product_name             
            });
            setRespData(dashboardDetails.data.data[0]);
            setDocumentLinkData(dashboardDetails.data.data[0]?.documentation_link);
            setShowMsrButton(dashboardDetails.data.data[0]?.show_msr_req_flag);
            setStatus(httpStatuses.OK);
            setIsError(false);
            setIsLoaded(true);
            setTitle(dashboardDetails.data.data[0]?.product_group_name);
            const topMenu = await getRequest(`${getBaseUrl()}${backendURL().dashboardTopMenu}?filters=${productId}`);
            setTopMenuData(topMenu.data.data);
            const sideMEnu = await getRequest(`${getBaseUrl()}${backendURL().dashboardSideMenu}?filters=${productId}`);

            setSideMenuData(sideMEnu.data.data);
        } catch (error) {
            errorInCall(error);
        }
    };

    const getDashboardPath = async (url, name)=>{
        try {
            const dashboardPath = await getRequest(getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/workbook_view/records');
            let parts = url.split('/');

            // Remove the last part
            parts.pop();
            let modifiedName = name.replace(/[\s/]/g, '');
            // Join the remaining parts back into a URL
            let newUrl = parts.join('/') + '/' + modifiedName;

            const result = dashboardPath.data.data.filter(item => item.url  === newUrl);

            if(result.length >0){
                setSelectedView({ id: result[0].id,  formName: result[0].name, productId: productId });
            }
        } catch (error) {
            errorInCall(error);
        }
    };
    
    //grabs initial workbook data and sets iframe load to false.
    useEffect(() => {

        getMenuData();
        return () => {
            // Update the state when the component unmount
            setRespData({});
            setDocumentLinkData({});
            setIsLoaded(false);
        };
    }, [productId, workbookId, workbookViewId,]);

    // Closing an error message
    const onErrorWasClosed = () => {
        setErrorMessage('');
    };

    // It handles the redirection when clicked on the menu items
    const handleChange = newValue => {
        const filteredData = topMenuData.filter(d => d.product_id === newValue);
        setSelectedView({ id: filteredData[0].product_id, formName: filteredData[0].product_name, });
        if (filteredData && filteredData[0]) {
            navigate(`/dashboards/${filteredData[0].dashboard_path}`);
        }
    };

    
    const handleViewPopup = id => {
        setPopupView({ status: true, formInfo: { action: 'new', myLinkId: null, formName: '' }, tableId: Number(id), });
    };
    const handleClosePopup = () => {
        setPopupView({ status: false, formInfo: {} });
    };

    const handleViewDownload = id => {
        const selectedForm = dashboardViews.find((dashboardView) => dashboardView.mylink_id == mylink);
        setDownloadView({ status: true, formInfo: { action: 'edit', myLinkId: selectedForm.mylink_id, formName: selectedForm.mydata_view_name, generateBtn: true, myLinkDataId: selectedForm.mylink_id, myLinkDataName: selectedForm.mydata_view_name }, tableId: Number(id), });
    };
    const handleCloseDownload = () => {
        setDownloadView({ status: false, formInfo: {} });
    };

    const handleViewMsr = id => {
        setMsrView({ status: true, formInfo: { action: 'new', myLinkId: null, formName: '' }, tableId: Number(id), });
    };
    const handleCloseMsr = () => {
        setMsrView({ status: false, formInfo: {} });
    };

    const getDashboardPathFunc = () => {
        getDashboardPath(refObj.url,  refObj.sheetName);
    };

    refObj.getDashboardPath = getDashboardPathFunc;
    const renderMenu = () => {
        if (isLoaded) {
            return <div>
                <DashboardTopMenu
                    topMenuData={topMenuData}
                    currentProductId={productId}
                    handleChange={handleChange}
                    workbookViewId={workbookViewId}
                    handleViewPopup={handleViewPopup}
                    handleViewDownload={handleViewDownload}
                    handleViewMsr={handleViewMsr}
                    showMsrButton={showMsrButton}
                    selectedView={selectedView}
                    mylink={mylink}
                    documentLinkData={documentLinkData}
                    errorInCall={errorInCall}
                />
                {popupView.status &&
                        <CustomPopup formType='other' tableName={`My Links Form: ${selectedView.formName}`} onClose={handleClosePopup} minWidth='80vw' backColor={'#fafafa'} minHeight='500px'>
                            <MyLinkDataForm
                                selectedTableId={null}
                                userId={userId}
                                getUserViews={null}
                                formInfo={popupView.formInfo}
                                setIsEditClicked={setPopupView}
                                formType={'myLink'}
                                workbookViewId={popupView.tableId}
                                productId={productId}
                                workbookId={workbookId}
                            />
                        </CustomPopup>
                }

                {downloadView.status &&
                        <CustomPopup formType='other' tableName={`My Data Form: ${selectedView.formName}`} onClose={handleCloseDownload} minWidth='80vw' backColor={'#fafafa'} minHeight='500px'>
                            <MyLinkDataForm
                                selectedTableId={null}
                                userId={userId}
                                getUserViews={null}
                                formInfo={downloadView.formInfo}
                                setIsEditClicked={setDownloadView}
                                formType={'myData'}
                                workbookViewId={downloadView.tableId}
                                productId={productId}
                                workbookId={workbookId}
                            />
                        </CustomPopup>
                }

                {msrView.status &&
                        <div className='msr-popup'>
                            <CustomPopup formType='other' tableName={`My MSR Form: ${selectedView.formName}`} onClose={handleCloseMsr}  minWidth={'550px'} backColor={'#fafafa'} minHeight='500px'>
                                <MsrRequest userId={userId} onClose={handleCloseMsr}></MsrRequest>
                            </CustomPopup>
                        </div>
                }

                {selectedView?.survey_product_id && <SurveyPrompt selectedDashboard={selectedView} userId={userId}/>}
            </div>;
        }

        return <Loading isLoading={true} />;
    };

    return (
        <>
            {isError ?
                <Error open={true} error={errorMessage} status={`${status}`} onErrorWasClosed={onErrorWasClosed} />
                : renderMenu()
            }
        </>
    );
};

DashboardContent.propTypes ={
    setTitle: PropTypes.func,
    userId: PropTypes.string,
    setRespData: PropTypes.func,
    setSideMenuData: PropTypes.func,
    refObj:PropTypes.object,
    isLoaded: PropTypes.bool,
    setIsLoaded: PropTypes.func,
};

export default DashboardContent;