import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DragAndDropUpload from './UploadForm/DragAndDropUpload';

const UploadSelectFile = props => {
    const{handleFileChange, error, fileStatus, selectFile, resetFile} = props;
    const [fileEmpty, setFileEmpty] = useState(true);
    
    //getting file sizes of files
    function fileSize(size) {
        let byteSize = 1024;
        let numPrecision = 2;
        
        let i = Math.floor( Math.log(size) / Math.log(byteSize) );
        return ( size / Math.pow(byteSize, i) ).toFixed(numPrecision) * 1 + ' ' + ['Bytes', 'kB', 'MB', 'GB', 'TB'][i];
    }
    
    // checking for empty file
    useEffect(() => {
        if(selectFile.status){
            setFileEmpty(false);
        } else {
            setFileEmpty(true);
        }
        
    }, [selectFile.status]);
    
    /**
     * The following is a form with the 
     * file contents info, such as size, name, and status.
     * Upload is done by file input, which opens local machine directory
     */
    return (
        <div className='table-upload-title'>
            {!fileEmpty ? <div className='upload-file-form'>
                <label className='upload-form-file-label'>
                    {error ? <span className='upload-file-label error-file'>{error}</span>
                        : <span className='upload-file-label'>{selectFile.name}</span>
                    }
                    <button className='btn btn-red' onClick={resetFile} style={{borderRadius:'0 5px 5px 0', minWidth:'90px'}}>reset</button>
                </label>
            </div> :
                <DragAndDropUpload handleFileChange={handleFileChange}/>}
            {!fileEmpty && <table className='upload-form-table'>
                <thead style={{backgroundColor: fileStatus.color ,border:`1px solid ${fileStatus.color}`}}>
                    <tr>
                        <th>File name</th> 
                        <th>File size</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{color: fileStatus.color}}>
                        <td >{selectFile.name}</td>
                        <td width={110}>{fileSize(selectFile.size)}</td>
                        <td >{fileStatus.message}</td>
                    </tr>
                </tbody>
            </table>}
        </div>
    );
};
UploadSelectFile.propTypes = {
    selectFile: PropTypes.object.isRequired,
    fileStatus: PropTypes.object.isRequired,
    handleFileChange: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    resetFile:PropTypes.func.isRequired,
};

export default UploadSelectFile;