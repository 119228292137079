import popupStyles from './customPopup.module.css';
import PropTypes from 'prop-types';
import React,{ useEffect, useRef, useState } from 'react';

const threeHundred = 300;
//to use this popup pass onClose props, title, children
const CustomPopup = props => {
    // title prop and onClose is optional and if not given it will not render title section of popup
    // also it will not render close, if you want to render close(x) give title empty string

    const ref = useRef();
    let paddingSize = 40;
    const ninetyPercent = 0.9;
    // minWidth prop is optional if you want to render with min-width give desired size otherwise it will fit-content
    const {minWidth, onClose, backColor, minHeight, padding, overflow} = props;
    const [maxHeight, setMaxHeight] = useState(window.innerHeight * ninetyPercent );
    const [winSize, setWinSize] = useState(window.innerHeight);

   
    /**
     * When the window is resized, set the window size to the window's inner height, and set the max
     * height to 90% of the window's inner height minus 40(this comes from padding).
     */
    
    const resizeEventListener = () => {
        setWinSize(window.innerHeight);
        setMaxHeight((window.innerHeight * ninetyPercent)-paddingSize);
    };


    
    useEffect(() => {
        // disabling body scroll
        document.body.style.overflow = 'hidden';
        //adding resize listener to track windows size
        window.addEventListener('resize', resizeEventListener);
        if(ref.current.clientHeight >= winSize){
            setMaxHeight((winSize * ninetyPercent)-paddingSize);
        }
        // cleanup
        return () => {
            document.body.style.overflow = ''; 
            window.removeEventListener('resize', resizeEventListener);
        };
    }, []);

    
    // handling close
    const closeHandler = e => {
        e.stopPropagation();
        e.preventDefault();
        onClose(false);
    };

    const CreateHeader = () => {
        switch (props.formType) {
        case 'edit':
            return <h2 id="FormHeader" > Editing {props.tableName} Record ID {props.recordId}</h2>;
        case 'add':
            return <h2 id="FormHeader"> Creating {props.tableName} Record</h2>;
        case 'copy':
            return <h2 id="FormHeader"> Copying {props.tableName} Record ID {props.recordId}</h2>;
        case 'other':
            return <h2 id="FormHeader">{props.tableName}</h2>;
        default:
            return <h2>Form</h2>;
        }
    };

    return (
        <div
            className={popupStyles.overlay}
            style={{zIndex:props.zIndex || threeHundred}}
        >
            <div className={popupStyles.popup} style={{minWidth, maxHeight:maxHeight, backgroundColor:backColor || '#eee', minHeight, padding:padding || '20px', overflowY:overflow || 'scroll'}} ref={ref}>
                {(props.tableName && props.onClose) && (<div className={popupStyles.title} >
                    <>{CreateHeader()}</>
                    <span className={popupStyles.close} onClick={closeHandler} data-testid = 'close-custom-popup'>
                &times;
                    </span>
                </div>)}
                <div className={popupStyles.content}>
                    {props.children}</div>
            </div>
        </div>
    );
};

CustomPopup.propTypes = {
    tableName: PropTypes.string,
    onClose: PropTypes.func,
    minWidth: PropTypes.string,
    recordId: PropTypes.any,
    formType: PropTypes.any,
    children: PropTypes.any,
    backColor: PropTypes.string,
    minHeight: PropTypes.string,
    zIndex: PropTypes.number,
    overflow:PropTypes.string,
    padding: PropTypes.string
};
export default CustomPopup;