import { create } from 'zustand';
 
// Create a store for handling the main part of the state.
// This store has states for the title and authorization status.
// It also has functions for setting those states.
const useMainStore = create(set => (
    {
        // A string to hold the title.
        title: '',
        // A function for setting the title.
        setTitle: data => set({title: data}),
        // A boolean to hold the authorization status.
        isAuthorized: false,
        // A function for setting the authorization status.
        setIsAuthorized:data => set({isAuthorized: data}),
        surveyCheck:false,
        setSurveyCheck: data => set({surveyCheck:data})
    }
));


// An object for handling authorization outside of React components.
// This object has a function for setting the authorization status.
export const apiAuth = {
    setIsAuthorized: isAuthorized => {
        // Set the authorization status in the main store.
        useMainStore.setState({ isAuthorized });
    }
};

// Export the hook so other components can use it.
export default useMainStore;
