import { AlertTitle, Alert } from '@mui/material';
import React, { Fragment } from 'react';
import { getBaseUrl } from '../../util/getBaseUrl';
import PropTypes from 'prop-types';

//used for specifically authentication
//should render and redirect instantly
const AuthRedirectError = props => {
    let redirectURL = window.location;
    const ERROR_CODE = 401;
    const url = `${getBaseUrl()}/api/login?redirectURL=${redirectURL}`;
    if (props.error === ERROR_CODE) {
        window.location.replace(url);
    }

    return (
        <Fragment>
            {props.error === ERROR_CODE ? (
                <Alert severity="info">
          Its trying to Sign in, please wait a moment!
                </Alert>
            ) : (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
          Something went wrong. {props.message}
                </Alert>
            )}
        </Fragment>
    );
};

//prop types
AuthRedirectError.propTypes = {
    error:PropTypes.number,
    message:PropTypes.string
};
//export
export default AuthRedirectError;