import React, {useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from '../common/loadingAnimation/LoadingAnimation';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';


/**
 * findErrorMessage - This function looks up an error message based on a provided path
 * @param {string} path - The error path we're looking for
 * @param {array} rowError - The array of errors we're searching in
 * @returns {string} - The corresponding error message if it exists, otherwise returns an empty string
 */
function findErrorMessage(path, rowError) {
    
    // Filter the rowError array for error objects that have a matching path
    const error = rowError.filter(err => err.path === path);

    // If no errors found, return an empty string
    if (error.length === 0) {
        return '';
    }
    // If more than one error found, concatenate the error messages
    if(error.length > 1){
        let errorText = '';
        error.forEach((element, index) => {
            let num = index +1;
            errorText = errorText + '  ' + num + '- '+ element.errors[0];
        });
        return errorText;
    }

    // If one error found, return the error message
    return error[0].errors[0];
}

/**
 * generateColumns - This function generates column configurations for a table
 * @param {array} keys - The list of keys to generate columns for
 * @param {boolean} includeUpdateResult - Flag to determine if the "updateResult" column should be included
 * @returns {array} - An array of column configuration objects
 */
const generateColumns = (keys, type='clear', data= []) => {
    // Map over each key, creating a basic column configuration object for each
    let cols = keys.map(key => ({
        accessorKey: key,
        header: key,
        minWidth: 60,
    }));


    // If includeUpdateResult is true, add an "updateResult" column to the front of the array
    if (type === 'apiResult') {
        const statusCol = {
            accessorKey: 'updateResult',
            header: 'Status',
            minWidth: 60,
            // Define a custom cell renderer that changes the cell color based on the updateResult value
            cell: info => (
                <div style={{
                    color: info.row.original.updateResult === 'Added' || info.row.original.updateResult === 'Updated' ? 'green' : 'red',
                    fontWeight: 600
                }}>
                    {info.row.original.updateResult}
                </div>
            )
        };

        cols = [statusCol, ...cols];
    }
    if(type === 'row'){
        
        const newCols = cols.map(col => ({
            ...col,
            cell: info => (
                <div>
                    {info.row.original[col.accessorKey]}
                    <div style={{color: 'red', fontSize:'0.8rem'}}>
                        {findErrorMessage(`[${info.row.index}].${col.accessorKey}`, data.errors)}
                    </div> 
                </div>
            )
        }));
        const statusCol = {
            accessorKey:'rowStatus',
            header: 'Status',
            minWidth: 60,
            hideFilter:false,
            cell: info => (
                <div>
                    {   
                        <div style={{color: info.row.original.rowStatus === 'Error' ? 'red' : 'green', fontWeight: 600}}> 
                            {info.row.original.rowStatus}
                        </div> 
                    }
                </div>
            )
        };
        cols = [statusCol, ...newCols];
    }
    if(type==='clear'){
        const statusCol = {
            accessorKey:'rowStatus',
            header: 'Status',
            minWidth: 60,
            hideFilter:false,
            cell: info => (
                <div>
                    {   
                        <div style={{color: info.row.original.rowStatus === 'Error' ? 'red' : 'green', fontWeight: 600}}> 
                            {info.row.original.rowStatus}
                        </div> 
                    }
                </div>
            )
        };
        cols =[statusCol, ...cols];
    }

    // Return the array of column configurations
    return cols;
};

const twentyFive =25;
const twenty = 20;
const fifteen = 15;
const ten = 10;
const five =5;
const rowArray = [ five, ten, fifteen, twenty, twentyFive];

const UploadTable = props => {


    const {data, filename, colNames}  = props;
    const [rows, setRows]  = useState(data.rows);
    const [columnError, setColumnError] = useState({status:false, errors:[]});
    const [columns, setColumns] = useState({status:false, cols:[]});
    
    // setting column names and rows based on error type
    useEffect(() => {
       
        let errorSet = new Set();
        data.errors.forEach(err => {
            let path = err.path;
            let rowIndex = parseInt(path?.substring(1, path.indexOf(']')), 10);
            errorSet.add(rowIndex);
        });

        const newRows = data.rows.map((row, index) => {
            row.rowStatus = errorSet.has(index) ? 'Error' : 'Passed';
            row.row = index +1;
            return row;
        });
        setRows(newRows);
        if(data.errorType === 'row'){
            const cols = generateColumns(['row', ...colNames], 'row', data);
            setColumns({status: true, cols});
        } 
    
        if(data.errorType === 'column'){
            setColumnError({status: true, errors: data.errors});
        }
    
        if(!data.errorStatus && !data.apiResult){

            const cols = generateColumns(colNames);
            setColumns({status: true, cols});
            setColumnError({status: false, errors: []});
        }
    
        if(data.apiResult){

            const cols = generateColumns(colNames, 'apiResult');
            setColumns({status: true, cols});
            setColumnError({status: false, errors: []});
        }
      
        return () => {
            setColumns([]);
            setRows([]);
            setColumnError([]);
        };
         
    }, []);
    {/* Showing column name related errors  */}
    if(columnError.status) {
        return (
            <div className='upload-error-cont'>
                <h3>Error log</h3>
                <div className='upload-error-log'>
                    {columnError.errors.map((column, index) => {
                        return (
                            <pre key={index}>
                                {(index+1) + '-' + column.message}
                            </pre>
                        );
                    })}
                </div>
            </div>
        );
    }
    return (
        <>  
            { columns.status ? <div> <DataTable
                {...{
                    tableData:rows,
                    columnConfig:columns.cols, 
                    disableDownload:false, 
                    defaultFileName:filename.split('.')[0], 
                    showFooter:false, 
                    rowsPerPage:rowArray
                }}/></div> : <LoadingAnimation/>
            }        
        </>
    );
};

UploadTable.propTypes = {
    data: PropTypes.object.isRequired,
    filename: PropTypes.string.isRequired,
    colNames: PropTypes.array.isRequired,
};

export default UploadTable;