
import React, {useEffect, useState} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DashboardGroup from './dashboardGroup/DashboardGroup';
import DashboardDetails from './dashboardDetails/dashboardDetails';
import DashboardRedirect from './dashboardRedirect/dashboardRedirect';
import PropTypes from 'prop-types';
import { backendURL, httpStatuses} from '../../config/constants';
import {getBaseUrl} from '../../util/getBaseUrl';
import Loading from '../../components/common/loading';
import Error from '../../components/common/error';
import { getRequest } from '../../services/axiosClient';
import { getErrorMsg } from '../../util/functions';
const dashboardMenuURL = `${getBaseUrl()}${backendURL().getDashboardMenu}`;

import useDashboardStore from '../../store/dashboard/useDashboardStore';


// Dashboard redirect to details after getting all three params - productid, workbookid, workbookviewid
const Dashboards = ({setTitle, userId }) => { 
    const [status, setStatus] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    
    const {getDashboardLinks, errorText, setDashboardMenu, dashboardsFetched} = useDashboardStore();
    useEffect(() => {
        if(location.pathname === '/dashboards'){
            setTitle('Dashboards');
        }
    },[location.pathname]);
    useEffect(()=>{
   
        // Making an API call to get the menu data
        const fetchData = async () => {
            try {
                const resDashboard = await getRequest(dashboardMenuURL);
                const newDashboard = resDashboard.data.data.map(item=> ({...item, onClick:()=>{navigate(`/dashboards/${item.dashboard_path}`);}}));
                await getDashboardLinks(userId);
                if(errorText){
                    setStatus(errorText);
                    setIsError(true);
                }
                setDashboardMenu(newDashboard);
                setStatus(httpStatuses.OK);
                setIsError(false);
                setIsLoaded(true);
            } catch (err) {
                const msg = getErrorMsg(err);
                setStatus('Error with getting saved user views, please refresh page or try again later' + msg);
                setIsError(true);
            }
        };
        if(!dashboardsFetched){
            fetchData();
        } else {
            setStatus(httpStatuses.OK);
            setIsError(false);
            setIsLoaded(true);
        } 
    },[]);
 
    // Closing the error message
    const onErrorWasClosed = () => {
        setErrorMessage('');
    };
    // Function to render the appropriate 
    // component based on the conditions
    const renderContent = () => {
    // If there is an error, render the 
    // Error component with the given props
        if (isError) {
            return (
                <Error
                    open={true}
                    error={errorMessage}
                    status={`${status}`}
                    onErrorWasClosed={onErrorWasClosed}
                />
            );
        }

        // If the content is not yet loaded, 
        // render the Loading component
        if (!isLoaded) {
            return <Loading isLoading={true} />;
        }

        // If there is no error and the content is loaded, 
        // render the Switch with Route components
        // Routes
        // Route for the main dashboard landing page
        // Route for redirecting to the appropriate dashboard
        // Route for displaying the details of a specific dashboard
        return (
            <Routes>
                <Route path={''} element={<DashboardGroup hideLink/>} />
                <Route path={':productId?/:workbookId?'} element={<DashboardRedirect />} />
                <Route path={':productId/:workbookId/:workbookViewId'} element={<DashboardDetails setTitle={setTitle} userId={userId} />} />
            </Routes>
        );
    };

    // Render the content by calling the renderContent function
    return <>{renderContent()}</>;
    
};
Dashboards.propTypes = {
    setTitle:PropTypes.func,
    userId:PropTypes.string,
};

export default Dashboards;
