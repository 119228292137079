import PropTypes from 'prop-types';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { getRequest, putRequest } from '../../services/axiosClient';
import { Form } from '../../components/common/Forms/Form';
import InfoMessage from '../../components/common/InfoMessage';
import { getErrorMsg } from '../../util/functions';
import React, { useEffect, useState } from 'react';

const DataMartPopup = ({ records, onClose, refetch }) => {
    /**
    * dataMartRecordURL is for api URL 
    * apiStatus, is for setting api data while fetching;
    */
    const dataMartConfigUrl = `${getBaseUrl()}${backendURL().getDataMart_ConfigURL}`;
    const dataMartRecordURL = `${getBaseUrl()}${backendURL().getDataMart_ColumnInfoURL}`;
    const [apiStatus, setApiStatus] = useState({ status: false, message: '', error: false });
    const [configData, setConfigData] = useState({ fetched: false, data: null });

    /**
    * Fetches data mart tables
    * Sets the state of fetched data to 
    * be displayed on the UI
    * If there is an error, sets the state of 
    * fetched data to an empty array and logs the error
    */
    const fetchConfigData = async () => {
        try {
            const res = await getRequest(dataMartConfigUrl);
            if (res) {
                setConfigData({ fetched: true, data: res.data?.columnConfig });
            }
        }
        catch (error) {
            setConfigData({ fetched: true, data: null });
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting table data, please refresh page or try again later' + msg, isError: true });
        }
    };
    useEffect(() => {
        fetchConfigData();
    }, []);
   
    /**
     * Put request for column
     * data tables and show
     * the result of put data to 
     * be displayed on the UI
     * If there is an error,
     * sets the state of put
     * request then logs the error
     */
    const handleChangeSubmit = async textState => {
        try {
            await putRequest(`${dataMartRecordURL}/${records.id}`, textState);
            await refetch();
            onClose();
        } catch (error) {
            const msg = getErrorMsg(error);
            setApiStatus({ status: true, message: 'Error with getting table data, please refresh page or try again later' + msg, isError: true });
        }
    };
    return (
        <>
            <section>
                <InfoMessage message={apiStatus.message} isError={apiStatus.isError} onClose={() => setApiStatus({ status: false, message: '', isError: false })} status={apiStatus.status} timer={false} showClose={true} />
            </section>
            {configData?.data && <Form
                Config={configData.data}
                Columns={[
                    'id',
                    'column_name_display',
                    'description',
                    'filter_flag',
                    'filter_tableau_name',
                    'filter_display_order',
                    'filter_tableau_start_dt_param_nm',
                    'filter_tableau_end_dt_param_nm',
                    'parameter_flag'
                ]}
                url={dataMartRecordURL}
                rowId={records.id}
                uniqueId={'id'}
                formType={'other'}
                handleSubmitter={handleChangeSubmit}
                handleCancelClick={onClose}
            />
            }
        </>

    );
};
DataMartPopup.propTypes = {
    records: PropTypes.object,
    onClose: PropTypes.func,
    refetch: PropTypes.func
};
export default DataMartPopup;