import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

export function FilePathBar({ pathArr, handlePathChange, handleForward, handleBackward }) {

    return (
        <div
            data-testid='filepathbar'
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '16px',
                marginTop: '16px',
                marginBottom: '16px',
            }}
        >
            <IconButton
                data-testid='path-bar-back'
                onClick={() => handleBackward()}
            >
                <ChevronLeftRoundedIcon />
            </IconButton>
            <IconButton
                data-testid='path-bar-forward'
                onClick={() => handleForward()}
            >
                <ChevronRightRoundedIcon />
            </IconButton>

            <div
                style={{
                    marginLeft: '16px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    verticalAlign: 'middle',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {pathArr.map((item, idx) => {
                    return (
                        <div data-testid={`path-${item}`} key={`path-${item}`} style={{ display: 'flex', flexDirection: 'row' }}>
                            <span
                                onClick={() => {
                                    handlePathChange(item);
                                }}
                                style={{
                                    textDecorationLine: 'underline',
                                    cursor: 'pointer',
                                    fontWeight:
                                        idx === pathArr.length - 1
                                            ? 'bold'
                                            : 'normal',
                                }}
                            >
                                {item}
                            </span>
                            <span
                                style={{
                                    marginLeft: '16px',
                                    marginRight: '16px',
                                }}
                            >
                                /
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

FilePathBar.propTypes = { 
    pathArr: PropTypes.array,
    handlePathChange: PropTypes.func,
    handleForward: PropTypes.func,
    handleBackward: PropTypes.func
};