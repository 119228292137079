import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {useApiSave } from './useHooks';
import {getBaseUrl} from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';


const urlFilter =`${getBaseUrl()}${backendURL().filterDataUrl}`;


const DataFilters = ({filterValues, processedData, setSelectedFilterList, setFilterValues,  formInfo, handleApiMessage}) => {
    const { isLoading, error, success, handleRequest } = useApiSave();

    useEffect(() => {
        if(success){
            handleApiMessage({status:success, timer:1000, isError:false, message:'Changes saved!'});
        }
        if(error){
            handleApiMessage({status:true, timer:5000, isError:true, message:error});
        }
    },[success, error]);
    // This function updates the selected filter list based on the item clicked by the user.
    // If the item is not present in the previous state, it adds it to the list. 
    // If the item is already present in the previous state, it removes it from the list.
    const handleToggleChange = async item => {
        const { action } = formInfo;
      
        if (action === 'edit') {
            const { isChecked, filterId, column_id, id } = item;
      
            // create filter data to send to server
            const filterData = { column_id, mylink_id: formInfo.myLinkId, filter_value_id: id };
            const url = `${urlFilter}/${filterId}`;
      
            let updatedItem;
      
            if (isChecked) {
            // send delete request to server to remove filter value
                const result = await handleRequest(url, 'DELETE');
                if (result) {
                    // update item object with new isChecked value
                    updatedItem = { ...item, isChecked: false };
                }
            } else {
            // send post request to server to add filter value and get response ID
                const resId = await handleRequest(urlFilter, 'POST', filterData);
                if (resId) {
                    // update item object with new filter ID and isChecked value
                    updatedItem = { ...item, filterId: resId, isChecked: true };
                }
            }
      
            // if item was updated, update filter values in state
            if (updatedItem) {
                const updatedFormValues = filterValues.map(column => {
                    const columnId = Object.keys(column)[0];
                    const updatedFilters = column[columnId].map(filter =>
                        filter.id === id ? updatedItem : filter
                    );
      
                    return { [columnId]: updatedFilters };
                });
      
                setFilterValues(updatedFormValues);
            }
        } else {
            // update item object with new isChecked value
            const updatedItem = { ...item, isChecked: !item.isChecked };
      
            setSelectedFilterList(prevState => {
                const itemIndex = prevState.findIndex(obj => obj.id === item.id);
      
                return itemIndex === -1
                    ? [...prevState, updatedItem]
                    : prevState.filter(obj => obj.id !== item.id);
            });
      
            // update filter values in state
            const updatedFormValues = filterValues.map(column => {
                const columnId = Object.keys(column)[0];
                const updatedFilters = column[columnId].map(filter =>
                    filter.id === item.id ? updatedItem : filter
                );
      
                return { [columnId]: updatedFilters };
            });
      
            setFilterValues(updatedFormValues);
        }
    };
      
      
  
    // This function gets the column name to be displayed based on the value object passed.
    // It first filters the `processedData` array to get the object with matching `id` key.
    // Then, it checks if the `column_name_display` property is present in the object and returns it,
    // otherwise it returns the value of `column_name` property.
    const getColumnName = value => {

        const reqData = processedData?.filter(val=>val.id == +Object.keys(value)[0])[0];
        return reqData?.column_name_display ? reqData.column_name_display : reqData.column_name;
    };

    return (<>
        { 
            filterValues.map((value, index)=>
                <div key={index} className='myLinkDataForm-input-container'>
                    <div className='myLinkDataForm-input-label'>
                        {getColumnName(value)}
                        
                    </div>
                    <div style={{marginTop:'10px', display:'flex', flexDirection:'column', maxWidth:'400px'}}>
                        {
                            Object.values(value)[0].map((item, i)=><label key={i}>
                                <input
                                    type="checkbox"
                                    name="lang"
                                    value={item.filter_value_name}
                                    onChange={()=>handleToggleChange(item)}
                                    checked={item.isChecked}
                                    disabled={isLoading}
                                /> {item.filter_value_name} 
                            </label>)
                        }
                    </div>
                </div>)
        }
    </>);
};

DataFilters.propTypes = {
    processedData: PropTypes.array,
    filterValues:PropTypes.array,
    columnName:PropTypes.string,
    setSelectedFilterList:PropTypes.func,
    setFilterValues:PropTypes.func,
    formInfo:PropTypes.object,
    handleApiMessage:PropTypes.func,
};
export default DataFilters;