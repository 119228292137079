import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Error = props => {

    /* 
    Scrolling to the 
    top of the page 
    when the error 
    is displayed.
    */

    const [errorMessage, setErrorMessage] = useState(props.error);
    //may need to remove
    useEffect(() => {
        if (props.error == 'API Key missing'){
            setErrorMessage('Your session has timed out. Please refresh to re establish a secure connection.');
        }
    }, [props.error]);
    
    // error component
    return (
        <Collapse in={props.open}>
            <Alert severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        position='fixed'
                        onClick={() => {
                            props.onErrorWasClosed();
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}>
                <AlertTitle>Error</AlertTitle>
        Something went wrong: {errorMessage} ERROR CODE: {props.status}  
            </Alert>
        </Collapse>
    );
};

Error.propTypes = {
    open: PropTypes.bool.isRequired,
    onErrorWasClosed: PropTypes.func.isRequired,
    error: PropTypes.any,
    status: PropTypes.string,
};
export default Error;
