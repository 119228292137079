import PropTypes from 'prop-types';

//Admin Group in application
const adminGroup = '216978de-32af-40ae-af15-fe6c0511ba1c';

//returns true if admin group id is in users Access Control List
const checkIfAdmin= ACL => ACL.includes(adminGroup);

export default checkIfAdmin;

checkIfAdmin.propTypes = {
    ACL:PropTypes.array,
    AdminGroup:PropTypes.string,

};