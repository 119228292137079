import React from 'react';
import PropTypes  from 'prop-types';

const ProgressBar = props => {

    // width and height is optional, if not given it will fill available place
    const defaultWidth = '100%';
    const defaultHeight = 20;
    const barWidth = props.width ? props.width : defaultWidth;
    const barHeight = props.height ? props.height : defaultHeight;
    const completedProgress = props.progress;


    /* Setting the width and height of the outer bar. */
    const outerStyle = {
        width: barWidth,
        height: barHeight,
        backgroundColor: '#036ddf2b'
    };


    /* Setting the width of the inner bar to the percentage of the progress. 
        And adding styles
    */
    const innerBarStyle = {
        width: `${completedProgress}%`,
        height: '100%',
        backgroundColor: '#036ddf',
        color:'white',
        fontSize:'14px',
        transition: 'width 0.2s ease-in-out',
        textAlign:'center'
    };

    return (
        <div style={outerStyle} data-testid='progress-bar'>
            <div style={innerBarStyle}>
                <span>{completedProgress}%</span>
            </div>
        </div>
    );
};

ProgressBar.propTypes= {
    progress: PropTypes.number.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ProgressBar;