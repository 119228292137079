import React from 'react';
import PropTypes from 'prop-types';
import loading from './loading.module.css';

const LoadingAnimation = props => {
    // width and height of loading animation
    // is optional if not given it will default as 40 by 40 px.
    const width = props.width ? props.width : '40px';
    const height = props.height ? props.height : '40px';
    // if you give type true it will display dot animation instead of circular animation
    const dotAnimation = props.type ? props.type : false;

    //assigning styles
    const style = {
        width: width,
        height: height,
    };

    return (
        <>
            {
                !dotAnimation ? 
                    <div className={loading.loadingWrapper} data-testid="loading-animation">
                        <div style={style} className={loading.loader}></div>
                    </div> 
                    : 
                    <div className={loading.container} data-title={loading.dotAnimation} data-testid="loading-dot-animation">
                        <div className={loading.wrapper}>
                            <div className={loading.dotAnimation}></div>
                        </div>
                    </div>
            }
        </>
    );
};

LoadingAnimation.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    type: PropTypes.bool
};

export default LoadingAnimation;
