import React, {useState} from 'react';

import Searchbarnew from '../../../components/common/Searchbarnew';

import PropTypes from 'prop-types';
import SubMenu from './SubMenu';
import './sidebar.css';

const SideBar = props => {
    const {menuItems, isOpen, setIsOpen}= props;

    // hold opened and closed menu items
    const [menuArray, setMenuArray] = useState(menuItems);

    // fn to close open sidebar
    const toggle = () => setIsOpen(!isOpen);

    /**
     * maping and chaning active field of each menu, when active it will be opened
     */
    const handleClick = index => {
        const newd = menuArray.map((item, i) => {
            if(index === i) {
                return {...item, active : !item.active};
            }
            return item;
        });
        setMenuArray(newd);   
    };

    /**
     * If the side bar is not open, then open it.
     */
    const handleSideBarOpenClose = () =>{
        if(!isOpen){
            setIsOpen(true);
        }
    };

    return (
   
        <div className={isOpen ? 'side-menu menu-open' : 'side-menu'} onClick={handleSideBarOpenClose} data-testid='sidebar'>
            <div className='top-section'>
               
                <div onClick={toggle} className="toggle-menu-btn" data-testid={'closeSideBar'}>
                    {isOpen ? (
                        <i className="bi bi-arrow-left-square-fill"></i>
                    ) : (
                        <i className="bi bi-arrow-right-square-fill menu-closed"></i>
                    )}
                </div>
            </div>
            <div className="search-controller" data-testid='searchbar'>
                <Searchbarnew placeholder="search" data={props.menuItems} />
            </div>
            <div className="divider"></div>
            <div className="main-menu">
                <ul style={{paddingBottom:'90px'}}>
                    {menuArray.map((item, index) => (
                        <SubMenu 
                            key={index}
                            index={index}
                            name={item.name} 
                            subMenus={item.subMenus || []}
                            closeOpenMenu={handleClick}
                            active={item.active}
                        />
                    ))}
                </ul>
            </div>
            
        </div>
        
    );
};

SideBar.propTypes = {
    menuItems: PropTypes.array,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func
};

export default SideBar;