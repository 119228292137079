
import React, {useEffect, useState} from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { backendURL, httpStatuses } from '../../../config/constants';
import {getBaseUrl} from '../../../util/getBaseUrl';
import Loading from '../../../components/common/loading';
import Error from '../../../components/common/error';
import { getRequest } from '../../../services/axiosClient';
import { getErrorMsg } from '../../../util/functions';

const getURLPath = (product_id,workbook_id,workbook_view_id) => {
    let path = '';
    if(product_id) {
        path = `/${product_id}`;
    }
    if(workbook_id){
        path = `${path}/${workbook_id}`;
    }
    if(workbook_view_id) {
        path = `${path}/${workbook_view_id}`;
    }
    return path;
};

// Dashboard redirect to details after getting all three params - productid, workbookid, workbookviewid
const DashboardRedirect = () => { 
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [status, setStatus] = useState();
    const [errorMessage, setErrorMessage] = useState();
    // Get the params from the URL
    const {productId, workbookId} = useParams();
    const productIdParam = `?filters=${productId}`;
    const workbookIdParam = workbookId?`&filters=${workbookId}`:'';
    // Construct the query path
    const queryParam = workbookIdParam?`${productIdParam}+${workbookIdParam}`:productIdParam;
    const [redirectPath, setRedirectPath] = useState('');
    //construct url path based on the params
    const urlPath = productId&&workbookId?backendURL().dashboardGetViewPath:backendURL().dashboardFilterPath;
    const dashboardFilterURL = `${getBaseUrl()}${urlPath}`;
    const getDashboardRedirectData = async () => {
        // Handling http service calls
        try{
            const response = await getRequest(dashboardFilterURL+queryParam);
            if(response.data.data?.length>0) {
                const {workbook_id,workbook_view_id } = response.data.data[0];
                setRedirectPath(getURLPath(productId,workbook_id,workbook_view_id));
                setStatus(httpStatuses.OK);
                setIsError(false);
                setIsLoaded(true);
            }
            else {
                throw new Error({data:{errors:{message:'No data found!', status:httpStatuses.SERVER_ERROR}}});
            }
        }catch(err){
            setIsError(true);
            setStatus(err.request.status || httpStatuses.SERVER_ERROR);
            setErrorMessage(getErrorMsg(err)||'No data found!');
        }
    };
    useEffect(()=>{
        // Making an API call
        getDashboardRedirectData();
        return () => {
            // Updating the state when the component Unmount
            setRedirectPath('');
        };
    },[]);
    // Close error message
    const onErrorWasClosed = () => {
        setErrorMessage('');
    };
    // redirect
    const redirect = ()=>{
        if(isLoaded){
            return <Navigate to={`/dashboards${redirectPath}`}/>;
        }
        return <Loading isLoading={true}/>;
    };
    
    return (
        <> 
            { isError ? (
                <Error open={true} error={errorMessage} status = {`${status}`} onErrorWasClosed={onErrorWasClosed} />
            ) : redirect()}
        </>
    );
};

export default DashboardRedirect;
