import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// to use import page cards and 
// pass array of pages
const PageCards = ({ pages }) => {
    // return
    return (
        <div className='homepage'>
            {pages.map(page => (
                <div key={page.url} className={'card'}>
                    {!page.externalLink ? 
                        <Link to={{pathname: page.url}} className={'card-link'} data-testid='cards'>
                            <span className='card-icon'>{page.icon}</span>
                            <span className='card-title'>{page.title}</span>
                        </Link> : 

                        <a href={page.url} className={'card-link'} target='_blank' rel='noreferrer'>
                            <span className='card-icon'>{page.icon}</span>
                            <span className='card-title'>{page.title}</span>
                        </a>
                    }
                </div>
            ))}
        </div>
    );
};

PageCards.propTypes = {
    pages: PropTypes.array.isRequired,
};
export default PageCards;
