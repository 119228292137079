import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { FaBookmark, FaDownload, FaFileContract } from 'react-icons/fa';
import { BsPatchQuestionFill } from 'react-icons/bs';
import { getDownloadBtnClass } from '../../../util/functions';
import { backendURL} from '../../../config/constants';
import { getBaseUrl } from '../../../util/getBaseUrl';
import { getRequest } from '../../../services/axiosClient';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import useUserStore from '../../../store/user/useUserStore';

const urlGetTableId = `${getBaseUrl()}${backendURL().getTableIdWith_WorkbookViewId}`;
const DashboardTopMenu = ({ topMenuData, currentProductId, handleChange, workbookViewId, handleViewPopup, handleViewDownload, handleViewMsr, showMsrButton, selectedView, mylink, documentLinkData, errorInCall }) => {

    const [mylinkStatus, setMylinkStatus] = useState(null);
    const {userInfo} = useUserStore();
    const getTableId = async id => {
        try {
            const res = await getRequest(urlGetTableId + id);
            setMylinkStatus(res.data.data[0].datamart_table_id);
        } catch (err) {
            // If there is an error, return an error message
            errorInCall(err);
        }
    };

    //grabs initial workbook data and sets iframe load to false.
    useEffect(() => {


        getTableId(selectedView.id || workbookViewId);
        return () => {
            // Update the state when the component unmount
            setMylinkStatus(null);
        };
    }, [selectedView]);

    return (
        <div className="main-tabs">
            <Link to={{ pathname: '/dashboards' }} data-testid="cards">
                <span title="Dashboards">
                    <AiOutlineAreaChart size={'35px'} />
                </span>
            </Link>
            <span
                onClick={() => { if (mylinkStatus) { handleViewPopup(selectedView.id); } }}
                title="Create MyLink"
                className={!mylinkStatus ? 'mylinks-save-icon mylinks-save-disabled' : 'mylinks-save-icon'}
            >
                <FaBookmark fontSize={25} />
            </span>
            <span
                onClick={() => { if (mylinkStatus) { handleViewDownload(selectedView.id); } }}
                title={!mylinkStatus ? 'To generate data, please create a My Link first' : 'Download data'}
                className={getDownloadBtnClass(mylinkStatus, mylink)}
            >
                <FaDownload size={'25px'} />
            </span>
            {selectedView.survey_product_id && 
                <span title='Take Survey'>
                    <Survey 
                        baseUrl={getBaseUrl()}  
                        productId={selectedView.survey_product_id} 
                        wwid={userInfo.wwid} 
                        productName={selectedView.survey_product_name} 
                        surveyTitle={`${selectedView.survey_product_name} Survey`} 
                        showIcon={true}
                    /></span>
            }
            <a
                className="dashboard-links"
                title={documentLinkData ? 'Documentation' : 'There is no documentation'}
                target="_blank"
                rel="noopener noreferrer"
                href={documentLinkData}
            >
                <BsPatchQuestionFill size={'25px'} />
            </a>
            {showMsrButton && <span
                onClick={() => handleViewMsr(selectedView.id)}
                title="Ad-hoc MSR"
                className={!mylinkStatus ? 'mylinks-save-icon mylinks-save-disabled' : 'mylinks-save-icon'}
            >
                <FaFileContract fontSize={25} />
            </span>}
            {topMenuData.map((d, index) => (
                <button
                    key={index}
                    className={`main-tab-dashboard ${d.product_id === Number(currentProductId) ? 'active' : ''}`}
                    onClick={() => {
                        const checkIsActive = d.product_id === Number(currentProductId);
                        if(!checkIsActive)
                            handleChange(d.product_id);
                    }}
                >
                    {d.product_name}
                </button>
            ))}
        </div>
    );
};

DashboardTopMenu.propTypes = {
    topMenuData: PropTypes.array.isRequired,
    currentProductId: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    workbookViewId: PropTypes.string,
    handleViewPopup: PropTypes.func.isRequired,
    handleViewDownload: PropTypes.func.isRequired,
    handleViewMsr: PropTypes.func.isRequired,
    showMsrButton: PropTypes.bool.isRequired,
    selectedView: PropTypes.object.isRequired,
    mylink: PropTypes.any,
    documentLinkData: PropTypes.string,
    errorInCall:PropTypes.func.isRequired
};

export default DashboardTopMenu;

