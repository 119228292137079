/* eslint-disable react/display-name */
import React, {useCallback,  useEffect} from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { debounce, getErrorMsg } from '../../util/functions';
import { getRequest } from '../../services/axiosClient';

import {getBaseUrl} from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import { useApiSave } from './useHooks';



const urlFilter =`${getBaseUrl()}${backendURL().filterDataUrl}`;

const DataAsyncSelect = ({dataURL, columnIdsAboveTenValues,columnData, setSelectedAsyncFilterList, selectedAsyncFilterList, formInfo, handleApiMessage }) => {
    const { isLoading, error, success, handleRequest } = useApiSave();
    const loadOptionData = async (inputText,callback,columnId) => {
        try {
            const json = await getRequest(`${dataURL}${columnId}&search-term=${inputText}`);
            callback(json.data.data);
        } catch (err) {
            const msg = getErrorMsg(err);
            handleApiMessage({status:true, timer:false,  message:'Error with getting filter values' + msg, isError:true});
            callback([]);
        }
    };

    // debounceDurationMS is the duration in milliseconds to wait before calling the `loadOptionData` function again
    const debounceDurationMS = 500;

    // `loadOptionDataDebounced` is a memoized version of the `loadOptionData` function that has been debounced
    // The `useCallback` hook ensures that the function is only redefined when its dependencies (i.e. `loadOptionData` and `debounceDurationMS`) change.
    const loadOptionDataDebounced = useCallback(
        debounce(loadOptionData, debounceDurationMS),
        []
    );

    useEffect(() => {
        if(success){
            handleApiMessage({status:success, timer:1000, isError:false, message:'Changes saved!'});
        }
        if(error){
            handleApiMessage({status:true, timer:5000, isError:true, message:error});
        }
    },[success, error]);

    // `updateSelectedValues` is a function that takes an array of `items` and updates the `selectedAsyncFilterList` state accordingly
    const updateSelectedValues = async (_selValues, userAction) => {
        const { action, option, removedValue } = userAction;
        // boolean to check if editing form
        const isEditing = formInfo.action === 'edit'; 
        
        // function to add selected option to selected list
        const addSelectedOption = async opt => {
            if (isEditing) {
            // create filter data to send to server
                const filterData = {
                    column_id: opt.column_id,
                    mylink_id: formInfo.myLinkId,
                    filter_value_id: opt.id,
                };
      
                // send post request to server to add filter value and get response ID
                const resId = await handleRequest(urlFilter, 'POST', filterData);
      
                if (resId) {
                    // create updated option object with new filter ID and add to selected list
                    const updatedOption = { ...opt, filterId: resId };
                    setSelectedAsyncFilterList(prevState => [...prevState, updatedOption]);
                }
            } else {
            // if not editing, simply add option to selected list
                setSelectedAsyncFilterList(prevState => [...prevState, opt]);
            }
        };
      
        // function to remove selected option from selected list
        const removeSelectedOption = async removedVal => {
            if (isEditing) {
            // find selected option object and get its filter ID
                const value = selectedAsyncFilterList.find(item => item.id === removedVal.id);
                const result = await handleRequest(`${urlFilter}/${value.filterId}`, 'DELETE');
                if(result){
                    // remove selected option from list
                    setSelectedAsyncFilterList(prevState =>
                        prevState.filter(item => item.id !== removedVal.id)
                    );
                }
            } else {
            // if not editing, simply remove option from selected list
                setSelectedAsyncFilterList(prevState =>
                    prevState.filter(item => item.id !== removedVal.id)
                );
            }
        };
      
        // perform action based on user action type
        if (action === 'select-option') {
            await addSelectedOption(option);
        } else {
            await removeSelectedOption(removedValue);
        }
    };
      
      

    // `getColumnName` is a function that takes a `columnId` and returns the corresponding `column_name` from the `columnData` array (if it exists)
    const getColumnName = columnId =>{ 
        
        const reqData = columnData?.filter(obj=>obj.id===columnId)[0];
        return reqData?.column_name_display ? reqData.column_name_display : reqData.column_name;
    };
   
    
    return <>
        {   
            columnIdsAboveTenValues?.map((obj, index)=>
                <div key={index} className='myLinkDataForm-input-container'>
                    <div className='myLinkDataForm-input-label'>
                        {getColumnName(obj?.column_id)}
                    </div>
                    <div style={{ marginBottom: '10px', maxWidth:'400px' }}>
                        <AsyncSelect
                            loadOptions={(...args)=>loadOptionDataDebounced(...args,obj?.column_id)}
                            onChange={(value, action)=> updateSelectedValues(value, action)}
                            getOptionLabel={option => option.filter_value_name}
                            getOptionValue={option => option.filter_value_name}
                            isMulti
                            backspaceRemovesValue={false}
                            menuPlacement="auto"
                            isClearable={false}
                            defaultValue={selectedAsyncFilterList.filter(item => item.column_id === obj.column_id) || ''}
                            isDisabled={isLoading}
                        />
                           
                    </div>
                </div>)
        }
    </>;
};

DataAsyncSelect.propTypes = {
    dataURL: PropTypes.string,
    columnIdsAboveTenValues:PropTypes.array,
    columnData:PropTypes.array,
    setSelectedAsyncFilterList:PropTypes.func,
    selectedAsyncFilterList:PropTypes.array,
    formInfo:PropTypes.object,
    handleApiMessage:PropTypes.func,
};

export default DataAsyncSelect;
