// ExportCSV.jsx

import React from 'react';
import { Parser } from 'json2csv';
import { BiDownload } from 'react-icons/bi';
import PropTypes from 'prop-types';
import LoadingAnimation from '../common/loadingAnimation/LoadingAnimation';
import { getRequest } from '../../services/axiosClient';
import { getErrorMsg } from '../../util/functions';


const ExportCSV = props => {

    ExportCSV.propTypes = {
        url: PropTypes.any,
        tableName: PropTypes.any,
        dispatch: PropTypes.func,
    };
    //used to get display values from api
    const dvDisplay = '?display-value=true&audit-create=true';

    //request url given as a prop from parent
    const urlreq = `${props.url}/records`;


    
    /**
     * Export data as a CSV file.
     *
     * @param {string[]} columnNames - An array of column names for the CSV.
     * @param {Object[]} rowData - An array of objects, each representing a row of data.
     * @param {string} [optionalTableName='table'] - An optional filename for the CSV (excluding extension).
     */
    const exportAsCsv = (columnNames, rowData, optionalTableName = 'table') => {
        // Create a CSV parser with the supplied column names
        const csvParser = new Parser({ fields: columnNames });
    
        // Use the parser to convert the row data into CSV format
        const csvData = csvParser.parse(rowData);
    
        // Create a blob from the CSV data
        const blobData = new Blob([csvData], { type: 'text/csv' });
    
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blobData);
    
        // Create a download link and set its href to the blob's URL
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
    
        // Set the filename for the download
        downloadLink.setAttribute('download', `${optionalTableName}.csv`);
    
        // Add the download link to the page
        document.body.appendChild(downloadLink);
    
        // Start the download
        downloadLink.click();
    
        // Remove the download link from the page
        downloadLink.parentNode.removeChild(downloadLink);
    };
        
    //state of file data to be downloaded. 
    //fetch handler
    const handleDataFetch = async() => {
        try{
            const res  = await getRequest(urlreq + dvDisplay);
            exportAsCsv(res.data.columns, res.data.data, props.tableName);
        }catch(err){
            const msg = getErrorMsg(err);
            props.dispatch({type:'FETCH_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status});
        }  
    };


    


    return (
        <div>
            {props.tableName ?
                <button className='btn btn-grey' role='infodownloaditem' style={{paddingLeft:'5px', paddingRight:'5px'}}
                    onClick={() => {
                        handleDataFetch();
                    }}
                >
                    <BiDownload size="20px"/> Download All 
                </button>
                
                :  <span className='btn-outlined btn' style={{paddingLeft:'8px', paddingRight:'8px'}}> <LoadingAnimation width={'20px'} height={'20px'} type={true}/></span>
                

            }
        </div>
    );
};
export default ExportCSV;