import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { BsArrowRightSquareFill, BsArrowLeftSquareFill } from 'react-icons/bs';

const DashboardSideMenu = ({sideMenuData, workbookId, handleSideTabChange})=>{
    const [sideClosed, setSideClosed] = useState(false);

    return(
        <>
            {sideMenuData.length > 1 && <div className={sideClosed ? 'side-menu-dashboard' : 'side-menu-dashboard side-menu-open'}>
                <span onClick={() => setSideClosed(prevState => !prevState)} data-testid='side-menu-close'
                    className={!sideClosed ? 'side-menu-open-close' : 'side-menu-open-close side-menu-open-close-right'}>
                    {sideClosed ? <BsArrowRightSquareFill fontSize={22} /> : <BsArrowLeftSquareFill fontSize={22} />}
                </span>
                {!sideClosed && sideMenuData.map((d, index) => (
                    <button
                        key={index}
                        className={`secondary-tab ${d.workbook_id === workbookId ? 'active' : ''}`}
                        onClick={() => {
                            const checkActive = d.workbook_id === workbookId;
                            if(!checkActive)
                                handleSideTabChange(d.workbook_id);
                        }}
                    >
                        {d.workbook_name}
                    </button>
                ))}
            </div>}
    
        </>
    );
};

DashboardSideMenu.propTypes ={
    sideMenuData : PropTypes.array,
    workbookId:PropTypes.number,
    handleSideTabChange:PropTypes.func
};

export default DashboardSideMenu;