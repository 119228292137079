// This is a function that returns an object with URLs for different backend endpoints
export const backendURL = () => {
    return {
        // URL to get a list of tables
        listOfTablesGenerator: '/api/datasrcs/1/schemas/tspmcfg/tables/table_config/tables?display-value=true&admin_form_edit_flag=true&sort-schema_id=ASC&sort-table_name=ASC',
        // URL to get details about a specific table
        detailsTableData: '/api/datasrcs/1/schemas/',
        // URL to get dashboard landing page
        dashboardLandingPath:'/api/datasrcs/1/folder/dataviz/customQuery/dashboard_catalog',
        // URL to get dashboard filter path
        dashboardFilterPath:'/api/datasrcs/1/folder/dataviz/customQuery/get_workbook_view_from_product',
        // URL to get dashboard view path
        dashboardGetViewPath:'/api/datasrcs/1/folder/dataviz/customQuery/get_view_from_product_workbook',
        // URL to get details about a dashboard
        dashboardDetailsPath:'/api/datasrcs/1/folder/dataviz/customQuery/get_dashboard_by_product_workbook_view',
        // URL to get top menu for dashboards
        dashboardTopMenu:'/api/datasrcs/1/folder/dataviz/customQuery/getTopMenuProducts',
        // URL to get side menu for dashboards
        dashboardSideMenu:'/api/datasrcs/1/folder/dataviz/customQuery/getSideMenuWorkbooks',
        // URL to get dashboard menu
        getDashboardMenu:'/api/datasrcs/1/folder/dataviz/customQuery/get_dashboard_menu',
        // URL to get data exploration records
        dataExploration:'/api/datasrcs/1/schemas/tspmcfg/tables/table_config/records?schema_id_dv=datamart_core,hpt_core&display-value=true',    
        // URL to get columns for a table in data exploration
        getDataExplorationTableColumns:'/api/datasrcs/1/schemas/tspmcfg/tables/column_config/records?active_flag=true&table_id=',
        // URL to get filter record count for a specific column
        getFilterRecordsCount:'/api/datasrcs/1/schemas/dataviz/tables/filter_value/records?active_flag=true&aggr-group_by=column_id&aggr-cnt=id&column_id=',
        // URL to get filter record values for a specific column
        getFilterRecordValues: '/api/datasrcs/1/schemas/dataviz/tables/filter_value/records?active_flag=true&sort-filter_value_name=ASC&column_id=',
        // URL to get filter record values asynchronously
        getAsyncFilterRecordValues: '/api/datasrcs/1/schemas/dataviz/tables/filter_value/records?active_flag=true&record-limit=10&search-fields=filter_value_name&sort-filter_value_name=ASC&column_id=',
        // URL to get mylink ID for a user
        getMyLinkId:'/api/datasrcs/1/schemas/dataviz/tables/mylink/records',
        getGenerateMyData: '/api/generateMyData',
        uploadMyLinkId:'/api/datasrcs/1/schemas/dataviz/tables/mylink/upload',
        // URL to save filtered data
        filteredDataSave:'/api/datasrcs/1/schemas/dataviz/tables/mylink_filter/upload',
        // URL to save column data
        columnDataSave:'/api/datasrcs/1/schemas/dataviz/tables/mydata_column/upload',
        // URL to get saved data exploration by user
        dataExpSaved:'/api/datasrcs/1/folder/dataviz/customQuery/get_mydata_views_by_user?filters=',
        // URL to get columns for a table in data exploration (with filtering enabled)
        getDataExpTableCols:'/api/datasrcs/1/schemas/tspmcfg/tables/column_config/records?filter_flag=true&active_flag=true&sort-filter_display_order=ASC&table_id=',
        // URL to get filtered data records
        filterDataUrl:'/api/datasrcs/1/schemas/dataviz/tables/mylink_filter/records',
        // URL to get column data records
        columnDataUrl:'/api/datasrcs/1/schemas/dataviz/tables/mydata_column/records',
        // URL to get Table Id
        getTableIdWith_WorkbookViewId:'/api/datasrcs/1/schemas/dataviz/tables/workbook_view/records?&id=',
        getFilterParams: '/api/datasrcs/1/schemas/tspmcfg/tables/column_config/records?active_flag=true&parameter_flag=true&sort-filter_display_order=ASC&table_id=',
        //URL to get DB Admin home page sourcing data
        getDBAdminUrl: '/api/datasrcs/1/schemas/tspmcfg/tables/schema_config/records?display-value=true&datasrc_id=1',
        getDataMart_TableInfoURL: '/api/datasrcs/1/schemas/tspmcfg/tables/table_config/records',
        getDataMart_ColumnInfoURL: '/api/datasrcs/1/schemas/tspmcfg/tables/column_config/records',
        getDataMart_ConfigURL : '/api/datasrcs/1/schemas/tspmcfg/tables/column_config/config?display-value=true',
        jobAdminURL : '/api/datasrcs/1/folder/tspmcfg/customQuery',
        jobAdminRunURL : '/api/runjob',
        jobScriptRunURL : '/api/runjobscript',
        msrFormURL: '/api/datasrcs/1/folder/dataviz/customQuery/get_msr_ad_hoc_form',
        msrDataURL: '/api/datasrcs/1/schemas/dataviz/tables/msr_request_dashboard/records?active_flag=true',
        msrPostDataURL:'/api/datasrcs/1/schemas/dataviz/tables',
        logMappingURL: '/api/logFileMapping',
        logDownloadURL: '/api/logfile'
    };

};



export const httpStatuses = {
    OK: 200,
    CREATED: 201,
    MULTIPLE_CHOICES: 300,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SERVER_ERROR: 500
};