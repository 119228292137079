import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {BiChevronUp, BiChevronDown }from 'react-icons/bi';

const SubMenu = props => {
    /* 
     * active is for tracking menu open/close status
     * closeOpenMenu is function to close open menu
     * index is to track place of menu that is clicked
     * 
     * 
    */
    const { name, subMenus, index, closeOpenMenu, active } = props;
    

    return (
        <li className={'menu'}>
            <button className={active ? 'menu-item active'  : 'menu-item'}
                onClick={()=> closeOpenMenu(index)}>{name}
                <span>
                    {active ? <BiChevronUp size={'20px'}/> : <BiChevronDown size={'20px'}/>}
                </span>
                
            </button>
            <ul className={active ? 'sub-menu' : 'sub-menu sub-closed'} data-testid={'menu'}>
                {subMenus.map((subitem, subindex) => (
                    <li key={subindex}>
                        <NavLink to={`${subitem.to}`}>{subitem.name}</NavLink>
                    </li>
                ))}
            </ul>
        </li>
     
    );
};
SubMenu.propTypes = {

    name: PropTypes.any,
    subMenus: PropTypes.array,
    to: PropTypes.any,
    closeOpenMenu: PropTypes.func,
    active: PropTypes.bool,
    index: PropTypes.number
};
export default SubMenu;